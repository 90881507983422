import { get, isEmpty } from "lodash";
import React, { Component } from "react";
import moment from "moment";
import { Col, Container, Row } from "react-grid-system";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string";
import Freetext from "../../components/Freetext";
import Loader from "../../components/Loader";
import WorkplaceReport from "./workplaceReport";
import SideBar from "./Filter/sideBar";
import LocationFilter from "./Filter/locationFilter";
import DateFilter from "./Filter/dateFilter";
import { getLocation } from "../../locationHelpers";
import * as reportActions from "../../actions/reports";
import style from "./style.module.scss";
import styled from "styled-components";
import strings from "./strings";
import IntersectionObserverWrapper from "../../components/IntersectionObserverWrapper";
import HelpView from "./helpView";
import { NewControlledDropdownSelection, Dropdown } from "../../components/DropdownSelection";
import Checkbox from "../../components/Checkbox";

export const reportOptions = {
  u: {
    intraday: {
      graphTypes: ["line", "bar"],
      graphValueKeys: [["avgAvg", "avgPeak"], ["avgPeak", "peakPeak"]],
      graphDataKeys: ["avgAvg", "avgPeak", "peakPeak"],
      graphTimeSpan: ["workhours", "wholeday"]
    },
    weekday: {
      graphTypes: ["line", "bar"],
      graphValueKeys: [["avgAvg", "avgPeak"], ["avgPeak", "peakPeak"]],
      graphDataKeys: ["avgAvg", "avgPeak", "peakPeak"],
    },
    week: {
      graphTypes: ["line", "bar"],
      graphValueKeys: [["avgAvg", "avgPeak"], ["avgPeak", "peakPeak"]],
      graphDataKeys: ["avgAvg", "avgPeak", "peakPeak"],
    },
    month: {
      graphTypes: ["line", "bar"],
      graphValueKeys: [["avgAvg", "avgPeak"], ["avgPeak", "peakPeak"]],
      graphDataKeys: ["avgAvg", "avgPeak", "peakPeak"],
    },
  },
  pc: {
    intraday: {
      graphTypes: ["line", "bar"],
      graphValueKeys: [["avgAvg", "avgPeak"], ["avgPeak", "peakPeak"]],
      graphDataKeys: ["avgAvg", "avgPeak", "peakPeak"],
      graphTimeSpan: ["workhours", "wholeday"],
      hasSummed: true,
      showPercentage: true
    },
    weekday: {
      graphTypes: ["line", "bar"],
      graphValueKeys: [["avgAvg", "avgPeak"], ["avgPeak", "peakPeak"]],
      graphDataKeys: ["avgAvg", "avgPeak", "peakPeak"],
      hasSummed: true,
      showPercentage: true
    },
    week: {
      graphTypes: ["line", "bar"],
      graphValueKeys: [["avgAvg", "avgPeak"], ["avgPeak", "peakPeak"]],
      graphDataKeys: ["avgAvg", "avgPeak", "peakPeak"],
      hasSummed: true,
      showPercentage: true
    },
    month: {
      graphTypes: ["line", "bar"],
      graphValueKeys: [["avgAvg", "avgPeak"], ["avgPeak", "peakPeak"]],
      graphDataKeys: ["avgAvg", "avgPeak", "peakPeak"],
      hasSummed: true,
      showPercentage: true

    },
    yearOverYear: {
      graphTypes: ["line"],
      graphValueKeys: [["avgPeak"], ["peakPeak"]],
      graphDataKeys: ["avgAvg", "avgPeak", "peakPeak"],
      showPercentage: true
    },
  },
  e: {
    
  },
}

class ReportContainer extends Component {
  
  constructor(props) {
    // console.log("HomeContainer.constructor");
    super(props);

    const dataKeysJSON = localStorage.getItem("dataKeys");
    const dataKeys = dataKeysJSON ? JSON.parse(dataKeysJSON) : ["avgPeak"];

    this.state = {
      selectedQueryIndex: -1,
      selectedLocations: [],
      configView: null,
      disabledDates: this.disabledDates(),
      filterOpen: false,
      language: localStorage.getItem("language") || "en",
      graphDataClass: localStorage.getItem("dataClass") || "avgPeak",
      graphDataKeys: dataKeys,
    };
    
    this.onTimeRangesChanged = this.onTimeRangesChanged.bind(this);
    this.onTimeRangesSaved = this.onTimeRangesSaved.bind(this);
    this.onLocationChanged = this.onLocationChanged.bind(this);
    this.onLocationSaved = this.onLocationSaved.bind(this);
    this.onWorkplaceCategoryChange = this.onWorkplaceCategoryChange.bind(this);
    this.onCustomTagChange = this.onCustomTagChange.bind(this);
    this.onSummedChanged = this.onSummedChanged.bind(this);
    this.onAddFilter = this.onAddFilter.bind(this);
    this.onDuplicateFilter = this.onDuplicateFilter.bind(this);
    this.onRemoveFilter = this.onRemoveFilter.bind(this);
    this.onChangeConfigView = this.onChangeConfigView.bind(this);
    this.getDateRangeView = this.getDateRangeView.bind(this);
    this.getLocationView = this.getLocationView.bind(this);
    this.onSelectedViewChanged = this.onSelectedViewChanged.bind(this);
    this.onSelectedPageChanged = this.onSelectedPageChanged.bind(this);
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.onGraphDataClassChanged = this.onGraphDataClassChanged.bind(this);
    this.onGraphDataKeysChanged = this.onGraphDataKeysChanged.bind(this);
    this.onGraphTypeChanged = this.onGraphTypeChanged.bind(this);
    this.onGraphTimeSpanChanged = this.onGraphTimeSpanChanged.bind(this);
    this.onGraphScaleTypeChanged = this.onGraphScaleTypeChanged.bind(this);
    this.onGraphShowLabelsChanged = this.onGraphShowLabelsChanged.bind(this);

    if (this.props.queries.length === 0) {
      this.props.getWorkplaceFilters();

      // Check if we have a multiple queries in the url
      const queryParams = new URLSearchParams(props.history.location.search);
      const filtersString = queryParams.get("f") || "";
      const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];
      // console.log("filters", filters)

      if (filters.length >= 1) {
        filters.forEach((_, index) => {
          const dateRanges = this.initialDateRanges(props, index);
          const includeHolidays = this.initialIncludeHolidays(props, index);
          const includeWeekends = this.initialIncludeWeekends(props, index);
          const locationId = this.initialLocationId(props, index);
          const workplaceCategoryId = this.initialWorkplaceCategoryId(props, index);
          const customTagId = this.initialCustomTagId(props, index);
          const summed = this.initialSummed(props, index);
          const graphType = this.initialGraphType(props, index);
          const graphScaleType = this.initialGraphScaleType(props, index);
          const graphTimeSpan = this.initialGraphTimeSpan(props, index);
          const graphShowLabels = this.initialGraphShowLabels(props, index);

          this.props.createWorkplaceQuery(
            dateRanges, 
            includeHolidays, 
            includeWeekends, 
            locationId, 
            workplaceCategoryId, 
            customTagId, 
            summed,
            graphType,
            graphScaleType,
            graphTimeSpan,
            graphShowLabels,
          );
        });
      }
      else {
        const dateRanges = this.initialDateRanges(props, 0);
        const includeHolidays = this.initialIncludeHolidays(props, 0);
        const includeWeekends = this.initialIncludeWeekends(props, 0);
        const locationId = this.initialLocationId(props, 0);
        const workplaceCategoryId = this.initialWorkplaceCategoryId(props, 0);
        const customTagId = this.initialCustomTagId(props, 0);
        const summed = this.initialSummed(props, 0);
        const graphType = this.initialGraphType(props, 0);
        const graphScaleType = this.initialGraphScaleType(props, 0);
        const graphTimeSpan = this.initialGraphTimeSpan(props, 0);
        const graphShowLabels = this.initialGraphShowLabels(props, 0);
        this.props.createWorkplaceQuery(
          dateRanges, 
          includeHolidays, 
          includeWeekends, 
          locationId, 
          workplaceCategoryId, 
          customTagId, 
          summed,
          graphType,
          graphScaleType,
          graphTimeSpan,
          graphShowLabels,
        );
      }
    }
    else {
      // If we already have queries in the store, we should put the filters in the url
      const params = new URLSearchParams(props.history.location.search);
      const filtersString = params.get("f") || "";
      const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];
      // console.log("filters", filters)

      if (filters.length === 0) {
        this.props.queries.forEach(query => {
          const dateRanges = query.dateRanges;
          const includeHolidays = query.includeHolidays;
          const includeWeekends = query.includeWeekends;
          const locationId = query.locationId;
          const workplaceCategoryId = query.workplaceCategoryId;
          const customTagId = query.customTagId;
          const summed = query.summed;
          const graphType = query.graphType;
          const graphScaleType = query.graphScaleType;
          const graphTimeSpan = query.graphTimeSpan;
          const graphShowLabels = query.graphShowLabels;

          filters.push({ 
            d: dateRanges.map(dateRange => (`${moment(dateRange.startDate).format("YYYY-MM-DD")}:${moment(dateRange.endDate).format("YYYY-MM-DD")}`)).join(";"),
            h: includeHolidays,
            w: includeWeekends,
            lid: locationId,
            wpc: workplaceCategoryId,
            ct: customTagId,
            s: summed,
            gt: graphType,
            gst: graphScaleType,
            gts: graphTimeSpan,
            gsl: graphShowLabels
          });
        });
        params.set("f", compressToEncodedURIComponent(JSON.stringify(filters)));
        props.history.push({ 
          pathname: props.history.location.pathname.substr(0, props.history.location.pathname.length),
          search: params.toString()
        });
      }
    }
  }

  componentDidMount() {
    // Update the language after viewing English on other pages
    moment.locale(this.state.language);
    strings.setLanguage(this.state.language);
  }

  // Make array of dates for already past days in the current week
  disabledDates() {
    const datesInThisWeek = [];
    const today = moment();
    const lastAvailableDate = moment().subtract(1, "days").startOf("day");
    let date = lastAvailableDate;
    while (date.isBefore(today)) {
      datesInThisWeek.push(date.toDate());
      date = date.clone().add(1, "day");
    }
    return datesInThisWeek;
  }

  initialDateRanges(props, index) {

    // Default date range is last 30 days (minus last 2 days)
    const defaultStart =  moment().startOf("year").toDate(); //moment().subtract(1, "month").startOf("isoWeek");
    const firstAvailableDate = moment().subtract(1, "year").startOf("day");
    const lastAvailableDate = moment().subtract(2, "days").startOf("day");

    const queryParams = new URLSearchParams(props.history.location.search);
    const filtersString = queryParams.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString));
    const dateQueryString = get(filters, `${index}.d`, null);
    if (dateQueryString) {

      try {
        const simpleDateRanges = dateQueryString.split(";").map(simpleDateRange => {
          const [startDate, endDate] = simpleDateRange.split(":");
          return { startDate, endDate };
        });

        const dateRanges = simpleDateRanges.map(simpleDateRange => {
          let startDate = moment(simpleDateRange.startDate).startOf("day");
          let endDate = moment(simpleDateRange.endDate).startOf("day");
          
          if (startDate.isValid()) {
            if (startDate.isBefore(firstAvailableDate)) {
              startDate = firstAvailableDate.startOf("day");
            }
            if (startDate.isAfter(lastAvailableDate)) {
              startDate = lastAvailableDate.startOf("day");
            }
          }
          else {
            startDate = defaultStart;
          }
    
          if (endDate.isValid()) {
            if (endDate.isAfter(lastAvailableDate)) {
              endDate = lastAvailableDate.startOf("day");
            }
            if (endDate.isBefore(firstAvailableDate)) {
              endDate = firstAvailableDate.startOf("day");
            }
            if (endDate.isBefore(startDate)) {
              let temp = endDate;
              endDate = startDate;
              startDate = temp;
            }
          }
          else {
            endDate = lastAvailableDate.startOf("day");
          }
    
          return { startDate: startDate.toISOString(), endDate: endDate.toISOString() };
        });

        return dateRanges;
      }
      catch (e) {
        onsole.log("Error parsing date query string: " + e);
      }
    }
    
    let defaultEnd = moment();
    if (defaultEnd.isAfter(lastAvailableDate)) {
      defaultEnd = lastAvailableDate.startOf("day");
    }

    return [{ startDate: defaultStart.toISOString(), endDate: defaultEnd.toISOString() }];
  }

  initialIncludeHolidays(props, index) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const filtersString = queryParams.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString));
    const includeHolidays = get(filters, `${index}.h`, null);
    return includeHolidays === "true";
  }

  initialIncludeWeekends(props, index) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const filtersString = queryParams.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString));
    const includeWeekends = get(filters, `${index}.w`, null);
    return includeWeekends === "true";
  }

  initialLocationId(props, index) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const filtersString = queryParams.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString));
    const locationId = get(filters, `${index}.lid`, null);
    return locationId;
  }

  initialWorkplaceCategoryId(props, index) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const filtersString = queryParams.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString));
    const workplaceCategoryId = get(filters, `${index}.wpc`, null);
    return workplaceCategoryId;
  }

  initialCustomTagId(props, index) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const filtersString = queryParams.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString));
    const customTagId = get(filters, `${index}.ct`, null);
    return customTagId;
  }

  initialSummed(props, index) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const filtersString = queryParams.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString));
    const summed = get(filters, `${index}.s`, "true");
    return summed;
  }

  initialGraphType(props, index) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const filtersString = queryParams.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString));
    const graphType = get(filters, `${index}.gt`, "bar");
    return graphType;
  }

  initialGraphScaleType(props, index) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const filtersString = queryParams.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString));
    const graphScaleType = get(filters, `${index}.gst`, "count");
    return graphScaleType;
  }

  initialGraphTimeSpan(props, index) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const filtersString = queryParams.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString));
    let graphTimeSpan = get(filters, `${index}.gts`, null);
    if (graphTimeSpan === null) {
      if (this.props.selectedCompany._id === "5bd6f1eaf59309f2e2198fe8") {
        graphTimeSpan = "workhours";
      }
      else {
        graphTimeSpan = "wholeday";
      }
    }
    return graphTimeSpan;
  }

  initialGraphShowLabels(props, index) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const filtersString = queryParams.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString));
    const graphShowLabels = get(filters, `${index}.gsl`, true);
    return graphShowLabels;
  }
  
  onTimeRangesSaved(dateRanges, includeHolidays, includeWeekends) {
    // console.log("ReportContainer.onTimeRangesSaved", dateRanges);
    if (this.state.selectedQueryIndex === -1) {
      return;
    }

    const query = this.props.queries[this.state.selectedQueryIndex];
    this.props.updateWorkplaceQuery(
      query.id,
      dateRanges,
      includeHolidays,
      includeWeekends,
      query.locationId,
      query.workplaceCategoryId,
      query.customTagId,
      query.summed,
      query.graphType,
      query.graphScaleType,
      query.graphTimeSpan,
      query.graphShowLabels,
    );

    const params = new URLSearchParams(this.props.history.location.search);
    const simpleDateRanges = dateRanges.map(dateRange => (`${moment(dateRange.startDate).format("YYYY-MM-DD")}:${moment(dateRange.endDate).format("YYYY-MM-DD")}`));
    const dateQueryString = simpleDateRanges.join(";");

    const filtersString = params.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];
    if (filters.length > this.state.selectedQueryIndex) {
      filters[this.state.selectedQueryIndex] = {
        ...filters[this.state.selectedQueryIndex],
        d: dateQueryString,
        h: includeHolidays,
        w: includeWeekends
      };
    }
    else {
      filters.push({ d: dateQueryString, h: includeHolidays, w: includeWeekends });
    }

    params.set("f", compressToEncodedURIComponent(JSON.stringify(filters)));

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState({ tempDateRanges: null, tempIncludeHolidays: null, tempIncludeWeekends: null });
  }

  onTimeRangesChanged(dateRanges, includeHolidays, includeWeekends) {
    this.setState({ tempDateRanges: dateRanges, tempIncludeHolidays: includeHolidays, tempIncludeWeekends: includeWeekends });
  }

  onLocationSaved(locationId) {
    // console.log("ReportContainer.onLocationSaved", locationId);expandLocationHierarchy
    if (this.state.selectedQueryIndex === -1) {
      return;
    }
    
    let query = this.props.queries[this.state.selectedQueryIndex];

    // Recursively find location in this.props.expandedHierarchy
    const topLocation = { _id: "top", name: "", children: this.props.expandedLocationHierarchy };
    const selectedLocation = getLocation(topLocation, locationId);
    
    const expandedCustomTags = get(selectedLocation, "expandedCustomTags", {});
    const customTags = Object.keys(expandedCustomTags).map(customTagId => ({ id: customTagId, name: expandedCustomTags[customTagId] })).flat();
    let customTagId = query.customTagId;
    if (customTagId === null) {
      customTagId = customTags.length > 0 ? customTags[0].id : null;
    }

    this.props.updateWorkplaceQuery(
      query.id,
      query.dateRanges,
      query.includeHolidays,
      query.includeWeekends,
      locationId,
      query.workplaceCategoryId,
      customTagId,
      query.summed,
      query.graphType,
      query.graphScaleType,
      query.graphTimeSpan,
      query.graphShowLabels,
    );
    
    const params = new URLSearchParams(this.props.history.location.search);
    const filtersString = params.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];

    if (filters.length > this.state.selectedQueryIndex) {
      if (locationId === null) {
        delete filters[this.state.selectedQueryIndex].lid;
      }
      else {
        filters[this.state.selectedQueryIndex] = {
          ...filters[this.state.selectedQueryIndex],
          lid: locationId
        };
      }

      if (customTagId === null) {
        delete filters[this.state.selectedQueryIndex].ct;
      }
      else {
        filters[this.state.selectedQueryIndex] = {
          ...filters[this.state.selectedQueryIndex],
          ct: customTagId
        };
      }
    }
    else if (locationId && customTagId) {
      filters.push({ lid: locationId, ct: customTagId });
    }
    else if (locationId ) {
      filters.push({ lid: locationId });
    }

    params.set("f", compressToEncodedURIComponent(JSON.stringify(filters)));

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState({ tempLocationId: null });
  }

  onLocationChanged(locationId) {
    this.setState({ tempLocationId: locationId });
  }

  onWorkplaceCategoryChange(queryIndex, workplaceCategoryId) {
    let query = this.props.queries[queryIndex];
    this.props.updateWorkplaceQuery(
      query.id,
      query.dateRanges,
      query.includeHolidays,
      query.includeWeekends,
      query.locationId,
      workplaceCategoryId,
      query.customTagId,
      query.summed,
      query.graphType,
      query.graphScaleType,
      query.graphTimeSpan,
      query.graphShowLabels,
    );

    const params = new URLSearchParams(this.props.history.location.search);
    const filtersString = params.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];

    if (filters.length > queryIndex) {
      if (workplaceCategoryId === null) {
        delete filters[queryIndex].wpc;
      }
      else {
        filters[queryIndex] = {
          ...filters[queryIndex],
          wpc: workplaceCategoryId
        };
      }
    }
    else if (workplaceCategoryId) {
      filters.push({ wpc: workplaceCategoryId });
    }
    
    params.set("f", compressToEncodedURIComponent(JSON.stringify(filters)));

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onCustomTagChange(queryIndex, customTagId) {

    if (customTagId === "undefined") {
      return;
    }

    let query = this.props.queries[queryIndex];
    this.props.updateWorkplaceQuery(
      query.id,
      query.dateRanges,
      query.includeHolidays,
      query.includeWeekends,
      query.locationId,
      query.workplaceCategoryId,
      customTagId,
      query.summed,
      query.graphType,
      query.graphScaleType,
      query.graphTimeSpan,
      query.graphShowLabels,
    );

    const params = new URLSearchParams(this.props.history.location.search);
    const filtersString = params.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];

    if (filters.length > queryIndex) {
      if (customTagId === null) {
        delete filters[queryIndex].ct;
      }
      else {
        filters[queryIndex] = {
          ...filters[queryIndex],
          ct: customTagId
        };
      }
    }
    else if (customTagId) {
      filters.push({ ct: customTagId });
    }
    
    params.set("f", compressToEncodedURIComponent(JSON.stringify(filters)));

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onSummedChanged(queryIndex, summed) {
    let query = this.props.queries[queryIndex];
    this.props.updateWorkplaceQuery(
      query.id,
      query.dateRanges,
      query.includeHolidays,
      query.includeWeekends,
      query.locationId,
      query.workplaceCategoryId,
      query.customTagId,
      summed,
      query.graphType,
      query.graphScaleType,
      query.graphTimeSpan,
      query.graphShowLabels,
    );

    const params = new URLSearchParams(this.props.history.location.search);
    const filtersString = params.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];

    if (filters.length > queryIndex) {
      filters[queryIndex] = {
        ...filters[queryIndex],
        s: summed
      };
    }
    else {
      filters.push({ s: summed });
    }
    
    params.set("f", compressToEncodedURIComponent(JSON.stringify(filters)));

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onAddFilter() {
    const lastIndex = this.props.queries.length - 1;
    this.onDuplicateFilter(lastIndex);
  }

  onDuplicateFilter(index) {
    this.props.createWorkplaceQuery(
      this.props.queries[index].dateRanges, 
      false, 
      false, 
      this.props.queries[index].locationId, 
      this.props.queries[index].workplaceCategoryId, 
      this.props.queries[index].customTagId, 
      this.props.queries[index].summed,
      this.props.queries[index].graphType,
      this.props.queries[index].graphScaleType,
      this.props.queries[index].graphTimeSpan,
      this.props.queries[index].graphShowLabels,
    );

    // Duplicate the filter at index
    const params = new URLSearchParams(this.props.history.location.search);
    const filtersString = params.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];
    
    filters.push(
      filters[index]
    );
  
    params.set("f", compressToEncodedURIComponent(JSON.stringify(filters)));

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onRemoveFilter(index) {
    const queryId = this.props.queries[index].id;
    this.props.deleteWorkplaceQuery(queryId);

    const params = new URLSearchParams(this.props.history.location.search);
    const filtersString = params.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];
    
    filters.splice(index, 1);

    params.set("f", compressToEncodedURIComponent(JSON.stringify(filters)));

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.onChangeConfigView(-1, null);
  }

  onChangeConfigView(queryIndex, configView) {
    this.setState({ 
      configView,
      selectedQueryIndex: queryIndex,
      tempLocationId: null,
      tempDateRanges: null,
      tempIncludeHolidays: null,
      tempIncludeWeekends: null
    });
  }

  getDateRangeView(isMobile) {
    const topLocation = { _id: "top", name: "", children: this.props.expandedLocationHierarchy };
    let selectedLocation = null;

    if (this.props.queries[this.state.selectedQueryIndex].locationId) {
      selectedLocation = getLocation(topLocation, this.props.queries[this.state.selectedQueryIndex].locationId);
    }

    return (
      <DateFilter
        query={this.props.queries[this.state.selectedQueryIndex]}
        workplaceFilters={this.props.workplaceFilters}
        selectedLocation={selectedLocation}
        disabledDates={this.state.disabledDates}
        onTimeRangesChanged={this.onTimeRangesChanged}
        onTimeRangesSaved={this.onTimeRangesSaved}
        onClose={() => {
          if (isMobile) {
            this.onChangeConfigView(-1, "filter");
          } 
          else {
            this.onChangeConfigView(-1, null);
          }}
        }
      />
    );
  }

  getLocationView(isMobile) {
    const companyName = get(this.props.selectedCompany, "name", "Company");
    const topLocation = { _id: "top", name: companyName, children: this.props.expandedLocationHierarchy };
    let selectedLocation = null;

    if (this.props.queries[this.state.selectedQueryIndex].locationId) {
      selectedLocation = getLocation(topLocation, this.props.queries[this.state.selectedQueryIndex].locationId);
    }

    return (
      <LocationFilter
        topLocation={topLocation}
        selectedLocation={selectedLocation}
        tempLocationId={this.state.tempLocationId}
        isLoading={this.props.isLoading}
        onLocationChanged={this.onLocationChanged}
        onLocationSaved={this.onLocationSaved}
        onClose={() => {
          if (isMobile) {
            this.onChangeConfigView(-1, "filter");
          } 
          else {
            this.onChangeConfigView(-1, null);
          }}
        }
      />
    );
  }

  getHelpView(isMobile) {
    const graphValueKeys = this.getGraphValueKeys();
    const showingAvg = get(graphValueKeys, 0, "avgAvg") === "avgAvg";
    return (
      <HelpView
        selectedView={this.initialSelectedView(this.props, "u")}
        selectedPage={this.initialSelectedPage(this.props, "intraday")}
        onClose={() => this.onChangeConfigView(-1, null)}
        showingAvg={showingAvg}
      />
    );
  }

  initialSelectedView(props, defaultView) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const selectedView = queryParams.get("v");
    return selectedView || defaultView;
  }

  onSelectedViewChanged(selectedView) {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("v", selectedView);

    // Check if the new view has the selected page, otherwise set it to the first page
    const selectedPage = this.initialSelectedPage(this.props, "intraday");
    const pageOptions = reportOptions[selectedView][selectedPage];

    if (pageOptions === undefined) {
      const firstPage = Object.keys(reportOptions[selectedView])[0];
      params.set("g", firstPage);
    }

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  initialSelectedPage(props, defaultView) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const selectedView = queryParams.get("g");
    return selectedView || defaultView;
  }

  onSelectedPageChanged(selectedPage) {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("g", selectedPage);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  getGraphOptions() {
    // Get options from local storage
    const graphOptions = localStorage.getItem("wpaGraphOptions");
    if (graphOptions) {
      try {
        return JSON.parse(graphOptions);
      } 
      catch (e) {
        return {};
      }
    }

    return {};
  }

  // peakPeak, avgPeak, avgAvg
  onGraphDataClassChanged(dataClass) {
    this.setState({ graphDataClass: dataClass });
    localStorage.setItem("dataClass", dataClass);
  }

  onGraphDataKeysChanged(dataKey) {
    let dataKeys = this.state.graphDataKeys;
    if (dataKeys.includes(dataKey)) {
      dataKeys = dataKeys.filter(key => key !== dataKey);
    }
    else {
      dataKeys.push(dataKey);
    }
    this.setState({ graphDataKeys: dataKeys });
    localStorage.setItem("dataKeys", JSON.stringify(dataKeys));
  }

  getGraphValueKeys() {
    const type = this.state.graphDataClass;
    const view = this.initialSelectedView(this.props, "u");
    const page = this.initialSelectedPage(this.props, "intraday");

    if (type === "avgAvg") {
      return reportOptions[view][page].graphValueKeys[0];
    }
    else {
      const length = reportOptions[view][page].graphValueKeys.length;
      return reportOptions[view][page].graphValueKeys[length - 1];
    } 
  }

  getGraphDataKeys() {
    const selectedKeys = this.state.graphDataKeys;
    const view = this.initialSelectedView(this.props, "u");
    const page = this.initialSelectedPage(this.props, "intraday");

    const validKeys = reportOptions[view][page].graphDataKeys;

    // Return an array of object with key and show: true/false
    return validKeys.map(key => ({ key, show: selectedKeys.includes(key) }));
  }

  onLanguageChanged(id) {
    moment.locale(id);
    strings.setLanguage(id);
    this.setState({ language: id });
    localStorage.setItem("language", id);
  }

  onGraphTypeChanged(queryIndex, graphType) {
    let query = this.props.queries[queryIndex];
    this.props.updateWorkplaceQuery(
      query.id,
      query.dateRanges,
      query.includeHolidays,
      query.includeWeekends,
      query.locationId,
      query.workplaceCategoryId,
      query.customTagId,
      query.summed,
      graphType,
      query.graphScaleType,
      query.graphTimeSpan,
      query.graphShowLabels,
    );

    const params = new URLSearchParams(this.props.history.location.search);
    const filtersString = params.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];

    if (filters.length > queryIndex) {
      filters[queryIndex] = {
        ...filters[queryIndex],
        gt: graphType
      };
    }
    else {
      filters.push({ gt: graphType });
    }
    
    params.set("f", compressToEncodedURIComponent(JSON.stringify(filters)));

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onGraphScaleTypeChanged(queryIndex, graphScaleType) {
    let query = this.props.queries[queryIndex];
    this.props.updateWorkplaceQuery(
      query.id,
      query.dateRanges,
      query.includeHolidays,
      query.includeWeekends,
      query.locationId,
      query.workplaceCategoryId,
      query.customTagId,
      query.summed,
      query.graphType,
      graphScaleType,
      query.graphTimeSpan,
      query.graphShowLabels,
    );

    const params = new URLSearchParams(this.props.history.location.search);
    const filtersString = params.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];

    if (filters.length > queryIndex) {
      filters[queryIndex] = {
        ...filters[queryIndex],
        gst: graphScaleType
      };
    }
    else {
      filters.push({ gst: graphScaleType });
    }
    
    params.set("f", compressToEncodedURIComponent(JSON.stringify(filters)));

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onGraphTimeSpanChanged(queryIndex, graphTimeSpan) {
    let query = this.props.queries[queryIndex];
    this.props.updateWorkplaceQuery(
      query.id,
      query.dateRanges,
      query.includeHolidays,
      query.includeWeekends,
      query.locationId,
      query.workplaceCategoryId,
      query.customTagId,
      query.summed,
      query.graphType,
      query.graphScaleType,
      graphTimeSpan,
      query.graphShowLabels,
    );

    const params = new URLSearchParams(this.props.history.location.search);
    const filtersString = params.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];

    if (filters.length > queryIndex) {
      filters[queryIndex] = {
        ...filters[queryIndex],
        gts: graphTimeSpan
      };
    }
    else {
      filters.push({ gts: graphTimeSpan });
    }
    
    params.set("f", compressToEncodedURIComponent(JSON.stringify(filters)));

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onGraphShowLabelsChanged(queryIndex, showLabels) {
    console.log("onGraphShowLabelsChanged", queryIndex, showLabels);
    let query = this.props.queries[queryIndex];
    this.props.updateWorkplaceQuery(
      query.id,
      query.dateRanges,
      query.includeHolidays,
      query.includeWeekends,
      query.locationId,
      query.workplaceCategoryId,
      query.customTagId,
      query.summed,
      query.graphType,
      query.graphScaleType,
      query.graphTimeSpan,
      showLabels,
    );

    const params = new URLSearchParams(this.props.history.location.search);
    const filtersString = params.get("f") || "";
    const filters = JSON.parse(decompressFromEncodedURIComponent(filtersString)) || [];

    if (filters.length > queryIndex) {
      filters[queryIndex] = {
        ...filters[queryIndex],
        gsl: showLabels
      };
    }
    else {
      filters.push({ gsl: showLabels });
    }
    
    params.set("f", compressToEncodedURIComponent(JSON.stringify(filters)));

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  render() {
    // console.log("ReportContainer.render.props", this.props);
    // console.log("ReportContainer.render.state", this.state);

    const links = [];
    if (this.props.auth.hasAdminRole) {
      links.push({ id: "u", label: `Utilization` });
      links.push({ id: "pc", label: `People count` });
      // links.push({ id: "e", label: `Energy` });
    }

    const key = links.map(link => link.pathname).join("");
    const menuElement = (
      <IntersectionObserverWrapper 
        key={key}
        links={links}
        small
        selectedIndex={links.findIndex(link => link.id === this.initialSelectedView(this.props, "u"))}
        onSelectIndex={(index) => this.onSelectedViewChanged(links[index].id)}
      />
    )

    const header = (
      <header className={style.header}>
        <div className={style.headerMargins}>
          <div style={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", width: "100%" }}>
              {menuElement}
              <div style={{ flexShrink: 0 }}>
                <NewControlledDropdownSelection
                  options={[
                    { id: "en", name: "English" },
                    { id: "nb", name: "Norsk" }
                  ]}
                  value={this.state.language}
                  onChange={(e) => this.onLanguageChanged(e.target.value)}
                  slim
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    );
    
    if (this.props.queries.length === 0 || this.props.isAuthLoading || this.props.isLoadingLocations) { //  || !this.state.hasLoadedState
      return <Loader fullscreen />;
    }

    if (!get(this.props.auth, "user.companies", null)) {
      return (
        <Container fluid style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <Row className={style.topRow}>
            <Col>
              <Freetext header="No access" content="You don't have administration privileges for any company in BLDNG.ai." />
            </Col>
          </Row>
        </Container>
      );
    }

    if (this.props.selectedCompany === null) {
      return (
        <Container fluid style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <Row className={style.topRow}>
            <Col>
              <Freetext header="No company is selected" />
            </Col>
          </Row>
        </Container>
      );
    }

    const selectedView = this.initialSelectedView(this.props, "u");
    const selectedPage = this.initialSelectedPage(this.props, "intraday");
    const graphDataKeys = this.getGraphDataKeys();

    let dataType = "";
    if (selectedView === "u") {
      dataType = "utilization";
    }
    else if (selectedView === "pc") {
      dataType = "peopleCount";
    }

    const disabledView = this.state.configView !== null && this.state.configView !== "filter";

    // Create the graph options element to be used inside WorkplaceReport
    const graphOptionsElement = (
      <OptionsContainer>
        <DataClassOptions>
          <Checkbox 
            label={strings.options.peak}
            description={`(${strings[dataType][selectedPage].explaination.peakPeak})`}
            isChecked={this.state.graphDataKeys.includes("peakPeak")}
            onClick={() => this.onGraphDataKeysChanged("peakPeak")}
          />
          <Checkbox
            label={strings.options.averagePeak}
            description={`(${strings[dataType][selectedPage].explaination.avgPeak})`}
            isChecked={this.state.graphDataKeys.includes("avgPeak")}
            onClick={() => this.onGraphDataKeysChanged("avgPeak")}
          />
          <Checkbox
            label={strings.options.average}
            description={`(${strings[dataType][selectedPage].explaination.avgAvg})`}
            isChecked={this.state.graphDataKeys.includes("avgAvg")}
            onClick={() => this.onGraphDataKeysChanged("avgAvg")}
          />
        </DataClassOptions>
      </OptionsContainer>
    );

    return (
      <OuterContainer>
        { header }
        <LayoutContainer>
          <Hidden xs sm md lg>
            <SideBarContainer>
              <SideBar
                filterOpen={this.state.filterOpen}
                queries={this.props.queries}
                locationBreadcrumbs={get(this.props, "locationBreadcrumbs", {})}
                locationData={get(this.props, "workplaceFilters.locations", [])}
                tempLocationId={this.state.tempLocationId}
                tempDateRanges={this.state.tempDateRanges}
                workplaceCategories={get(this.props, "workplaceFilters.wpaCategories", [])}
                onWorkplaceCategoryChange={this.onWorkplaceCategoryChange}
                onCustomTagChange={this.onCustomTagChange}
                onSummedChanged={this.onSummedChanged}
                onAddFilter={this.onAddFilter}
                onDuplicateFilter={this.onDuplicateFilter}
                onRemoveFilter={this.onRemoveFilter}
                onChangeConfigView={this.onChangeConfigView}
                disableView={disabledView}
                selectedQueryIndex={this.state.selectedQueryIndex}
                onClose={() => this.onChangeConfigView(-1, null)}
                includeWorkplaceCategories={selectedView === "u"}
                includeCustomTags={selectedView === "pc"}
              />
            </SideBarContainer>
            <InnerContainer $showSideBar>
              <WorkplaceReport
                {...this.props}
                selectedView={selectedView}
                onSelectedViewChanged={this.onSelectedViewChanged}
                selectedPage={selectedPage}
                onSelectedPageChanged={this.onSelectedPageChanged}
                graphDataKeys={graphDataKeys}
                onGraphTypeChanged={this.onGraphTypeChanged}
                onGraphScaleTypeChanged={this.onGraphScaleTypeChanged}
                onGraphTimeSpanChanged={this.onGraphTimeSpanChanged}
                onGraphShowLabelsChanged={this.onGraphShowLabelsChanged}
                filterOpen={this.state.filterOpen}
                disableView={disabledView}
                language={this.state.language}
                graphOptionsElement={graphOptionsElement}
                onHelpClicked={() => this.onChangeConfigView(-1, "helpView")}
              />
              { this.state.configView === "dateRangeView" && this.getDateRangeView(false) }
              { this.state.configView === "locationView" && this.getLocationView(false) }
              { this.state.configView == "helpView" && this.getHelpView(false) }
              { this.props.isFilterLoading && <Loader fullscreen />}
              
            </InnerContainer>
            
          </Hidden>
          <Visible xs sm md lg>
            <InnerContainer>
              <WorkplaceReport
                {...this.props}
                selectedView={selectedView}
                onSelectedViewChanged={this.onSelectedViewChanged}
                selectedPage={selectedPage}
                onSelectedPageChanged={this.onSelectedPageChanged}
                graphDataKeys={graphDataKeys}
                onGraphTypeChanged={this.onGraphTypeChanged}
                onGraphScaleTypeChanged={this.onGraphScaleTypeChanged}
                onGraphTimeSpanChanged={this.onGraphTimeSpanChanged}
                onGraphShowLabelsChanged={this.onGraphShowLabelsChanged}
                filterOpen={this.state.filterOpen}
                disableView={disabledView}
                language={this.state.language}
                mobile={true}
                graphOptionsElement={graphOptionsElement}
                onHelpClicked={() => this.onChangeConfigView(-1, "helpView")}
              />
              { this.state.configView == "helpView" && this.getHelpView(false) }
              { this.props.isFilterLoading && <Loader fullscreen />}
            </InnerContainer>
          </Visible>
        </LayoutContainer>
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth,
    queries: state.report.queries,
    workplaceFilters: state.report.workplaceFilters,
    expandedLocationHierarchy: state.report.expandedHierarchy,
    locationHierarchy: state.locations.hierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    customTags: state.report.customTags,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createWorkplaceQuery: reportActions.createWorkplaceQuery,
    updateWorkplaceQuery: reportActions.updateWorkplaceQuery,
    deleteWorkplaceQuery: reportActions.deleteWorkplaceQuery,
    getWorkplaceFilters: reportActions.getWorkplaceFilters,
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportContainer));

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
`;

const LayoutContainer = styled.div`
  display: inline-flex;
  height: calc(100% - 50px);
  width: 100%;
  background-color: #f6f6f6;
`;

const SideBarContainer = styled.div`
  position: relative;
  width: 345px;
  height: 100%;
`;

const InnerContainer = styled.div`
  position: relative;
  width: ${props => props.$showSideBar ? "calc(100% - 345px)" : "100%"};
`;

const OptionsContainer = styled.div`
  font-family: "Source Sans Pro", Helvetica Neue, Helvetica, sans-serif;
  margin-top: 2px;
  margin-bottom: 2px;
`;

const Options = styled.div`
  display: flex;
  justify-content: flext-start;
  align-items: center;
  margin-top: 10px;
 
  > div {
    margin-right: 20px;
  }

  > div:last-child {
    margin-right: 0;
  }
`;

const OptionsTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #222;
  margin-bottom: 5px;
`;

const DataClassOptions = styled.div`
  display: flex;
  flex-direction: column;
  // margin-top: 10px;

  label {
    font-size: 15px;
    padding-left: 30px;
    margin-top: 3px;
    margin-bottom: 3px;

    span {
      font-size: 15px;
      color: #555;
      top: 0px;
    }
  }
`;