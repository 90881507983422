import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import moment from "moment";
import UNITCODES from "./constants";
import InputBox from "../../components/InputBox";
import Loader from "../../components/Loader";
import { StepSize, TimePeriod } from "../../components/Dashboard/Graph";
import GraphContainer from "../../components/Dashboard/Graph/container";
import Datepicker from "../../components/Datepicker";
import SmallButton from "../../components/SmallButton";
import SegmentedControl from "../../components/SegmentedControl";
import CheckboxBlock from "../../components/CheckboxBlock";
import * as sensorActions from "../../actions/sensors";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";
import { Dropdown } from "../../components/DropdownSelection";
import { toShortISOString } from "../../helpers";

class AtomicSensorData extends Component {

  constructor(props) {
    super(props);
    this.state = {
      timePeriod: this.getInitialPeriod(props),
      startDate: this.getInitialStartDate(props),
      endDate: this.getInitialEndDate(props),
      showRawData: false,
      selectionStartDate: this.getInitialSelectionStartDate(props),
      selectionEndDate: this.getInitialSelectionEndDate(props),
      selectionStartDateText: this.getInitialSelectionStartDateText(props),
      selectionEndDateText: this.getInitialSelectionEndDateText(props),
    };
    this.onTimePeriodChanged = this.onTimePeriodChanged.bind(this);
    this.onTimeChanged = this.onTimeChanged.bind(this);
    this.onStartTimeChanged = this.onStartTimeChanged.bind(this);
    this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
    this.onDateChanged = this.onDateChanged.bind(this);
    this.onWeekNumberChanged = this.onWeekNumberChanged.bind(this);
    this.onMonthChanged = this.onMonthChanged.bind(this);
    this.onShowRawDataChanged = this.onShowRawDataChanged.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.selectionStartDateChanged = this.selectionStartDateChanged.bind(this);
    this.selectionStartDateTextChanged = this.selectionStartDateTextChanged.bind(this);
    this.selectionEndDateTextChanged = this.selectionEndDateTextChanged.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.getGraphData = this.getGraphData.bind(this);

    const atomicSensorId = props.match.params.id;

    // Fix startDate and endDate to reflect timePeriod
    let newStartDate;
    let newEndDate;
    if (this.state.timePeriod === TimePeriod.Week) {
      newStartDate = this.state.startDate.clone().startOf("isoWeek");
      newEndDate = this.state.endDate.clone().endOf("isoWeek");
    }
    else if (this.state.timePeriod === TimePeriod.Month) {
      newStartDate = this.state.startDate.clone().startOf("month");
      newEndDate = this.state.endDate.clone().endOf("month");
    }
    else {
      newStartDate = this.state.startDate.clone();
      newEndDate = this.state.endDate.clone();
    }

    

    // Set span for graph (undefined = raw data)
    let timeScale;
    if (this.state.timePeriod !== TimePeriod.Dynamic || newEndDate.diff(newStartDate, "hours") > 24) {
      if (this.state.timePeriod === TimePeriod.Day) {
        timeScale = "hour";
      }
      else {
        timeScale = "day";
      }
    }

    // Only load data if sensor change
    const currentSamplesQueryHash = `${props.sensor.id}-${atomicSensorId}-${newStartDate}-${newEndDate}-${timeScale}`;
    if (props.sensor.samplesQueryHash !== currentSamplesQueryHash) {
      props.getSensorSamples(props.sensor.id, atomicSensorId, newStartDate, newEndDate, timeScale);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("componentDidUpdate", prevState, this.state);
    const atomicSensorId = this.props.match.params.id;

    // Fix startDate and endDate to reflect timePeriod
    let newStartDate;
    let newEndDate;
    if (this.state.timePeriod === TimePeriod.Week) {
      newStartDate = this.state.startDate.clone().startOf("isoWeek");
      newEndDate = this.state.endDate.clone().endOf("isoWeek");
    }
    else if (this.state.timePeriod === TimePeriod.Month) {
      newStartDate = this.state.startDate.clone().startOf("month");
      newEndDate = this.state.endDate.clone().endOf("month");
    }
    else {
      newStartDate = this.state.startDate.clone();
      newEndDate = this.state.endDate.clone();
    }

    // Set span for graph (undefined = raw data)
    let timeScale;
    if (this.state.timePeriod !== TimePeriod.Dynamic || newEndDate.diff(newStartDate, "hours") > 24) {
      if (this.state.timePeriod === TimePeriod.Day) {
        timeScale = "hour";
      }
      else {
        timeScale = "day";
      }
    }

    // Only load data if sensor change
    const currentSamplesQueryHash = `${this.props.sensor.id}-${atomicSensorId}-${newStartDate}-${newEndDate}-${timeScale}`;
    if (this.props.sensor.samplesQueryHash !== currentSamplesQueryHash) {
      this.props.getSensorSamples(this.props.sensor.id, atomicSensorId, newStartDate, newEndDate, timeScale);
    }
  }

  getInitialPeriod(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return Object.values(TimePeriod).includes(queryParams.get("p")) ? queryParams.get("p") : TimePeriod.Day;
  }

  getInitialStartDate(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const paramDay = moment(queryParams.get("sd"));

    const earliestDay = moment().subtract(180, "days").startOf("day");
    if (paramDay.isValid() && paramDay.isAfter(earliestDay)) {
      return paramDay;
    }

    return moment().startOf("day");
  }

  getInitialEndDate(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const paramDay = moment(queryParams.get("ed"));

    const earliestDay = moment().subtract(180, "days").startOf("day");
    if (paramDay.isValid() && paramDay.isAfter(earliestDay)) {
      return paramDay;
    }

    return moment().endOf("day");
  }

  getInitialSelectionStartDate(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const paramDay = moment(queryParams.get("ssd"));

    const earliestDay = moment().subtract(180, "days").startOf("day");
    if (paramDay.isValid() && paramDay.isAfter(earliestDay)) {
      return paramDay;
    }

    return null;
  }

  getInitialSelectionEndDate(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const paramDay = moment(queryParams.get("sed"));

    const earliestDay = moment().subtract(180, "days").startOf("day");
    if (paramDay.isValid() && paramDay.isAfter(earliestDay)) {
      return paramDay;
    }

    return null;
  }

  getInitialSelectionStartDateText(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const paramDay = moment(queryParams.get("ssd"));

    const earliestDay = moment().subtract(180, "days").startOf("day");
    if (paramDay.isValid() && paramDay.isAfter(earliestDay)) {
      return paramDay.format("DD/MM/YYYY HH:mm:ss");
    }

    return "";
  }

  getInitialSelectionEndDateText(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const paramDay = moment(queryParams.get("sed"));

    const earliestDay = moment().subtract(180, "days").startOf("day");
    if (paramDay.isValid() && paramDay.isAfter(earliestDay)) {
      return paramDay.format("DD/MM/YYYY HH:mm:ss");
    }

    return "";
  }

  onTimePeriodChanged(value) {
    this.setState({ timePeriod: value });

    if (value === TimePeriod.Hours) {
      this.onTimeChanged(this.state.startDate.hours());
    }
    else if (value === TimePeriod.Day || value === TimePeriod.Dynamic) {
      this.onDateChanged(this.state.startDate.toDate());
    }
    else if (value === TimePeriod.Week) {
      this.onWeekNumberChanged(this.state.startDate.isoWeek());
    }
    else if (value === TimePeriod.Month) {
      this.onMonthChanged(this.state.startDate.month());
    }

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("p", value);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onTimeChanged(value) {
    const newStartDate = this.state.startDate.clone().startOf("day").hours(value);
    const newEndDate = newStartDate.clone().add(1, "hour").subtract(1, "second");

    // if (moment().isBefore(newStartDate)) {
    //   console.warn("Cannot select future time");
    //   return;
    // }
    
    this.setState({ startDate: newStartDate, endDate: newEndDate });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("sd", toShortISOString(newStartDate));
    params.set("ed", toShortISOString(newEndDate));
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onStartTimeChanged(value) {
    const newStartDate = this.state.startDate.clone().startOf("day").hours(value);
    let newEndDate = this.state.endDate.clone();
    
    // if (moment().isBefore(newStartDate)) {
    //   console.warn("Cannot select future time");
    //   return;
    // }

    if (newStartDate.isAfter(this.state.endDate)) {
      newEndDate = newStartDate.clone().add(1, "hour").subtract(1, "second");
    }

    this.setState({ startDate: newStartDate, endDate: newEndDate });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("sd", toShortISOString(newStartDate));
    params.set("ed", toShortISOString(newEndDate));
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onEndTimeChanged(value) {
    const newEndDate = this.state.endDate.clone().startOf("day").hours(value).subtract(1, "second");
    let newStartDate = this.state.startDate.clone();

    if (newEndDate.isBefore(this.state.startDate)) {
      newStartDate = newEndDate.clone().startOf("hour");
    }

    this.setState({ startDate: newStartDate, endDate: newEndDate });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("sd", toShortISOString(newStartDate));
    params.set("ed", toShortISOString(newEndDate));
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onDateChanged(date) {
    const newStartDate = this.state.startDate ? moment(date).hours(this.state.startDate.hours()).minutes(this.state.startDate.minutes()).seconds(this.state.startDate.seconds()) : moment(date).startOf("day");
    const newEndDate = this.state.endDate ? moment(date).hours(this.state.endDate.hours()).minutes(this.state.endDate.minutes()).seconds(this.state.endDate.seconds()) : moment(date).endOf("day");
    this.setState({ startDate: newStartDate, endDate: newEndDate });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("sd", toShortISOString(newStartDate));
    params.set("ed", toShortISOString(newEndDate));
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onWeekNumberChanged(value) {
    // If startDate and endDate is in the selected week number, then do not change the selection
    if (this.state.startDate.isoWeek() !== value || this.state.endDate.isoWeek() !== value) {
      let newStartDate;
      let newEndDate;
  
      const earliestMoment = moment().startOf("month").subtract(6, "months");
      
      if (value > moment().isoWeek()) {
        newStartDate = moment().subtract(1, "year").isoWeek(value).startOf("isoWeek");
        newEndDate = newStartDate.clone().endOf("isoWeek");
      }
      else {
        newStartDate = moment().isoWeek(value).startOf("isoWeek");
        newEndDate = newStartDate.clone().endOf("isoWeek");
      }
  
      if (newStartDate.isBefore(earliestMoment)) {
        newStartDate = earliestMoment;
      }
  
      this.setState({ startDate: newStartDate, endDate: newEndDate });

      const params = new URLSearchParams(this.props.history.location.search);
      params.set("sd", toShortISOString(newStartDate));
      params.set("ed", toShortISOString(newEndDate));
      this.props.history.push({ 
        pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
        search: params.toString()
      });
    }
  }

  onMonthChanged(value) {
    // If startDate and endDate is in the selected month, then do not change the selection
    if (this.state.startDate.month() !== value || this.state.endDate.month() !== value) {
      let newStartDate;
      let newEndDate;

      if (value > moment().month()) {
        newStartDate = moment().subtract(1, "year").set("month", value).startOf("month");
        newEndDate = newStartDate.clone().endOf("month");
      }
      else {
        newStartDate = moment().set("month", value).startOf("month");
        newEndDate = newStartDate.clone().endOf("month");
      }

      this.setState({ startDate: newStartDate, endDate: newEndDate });

      const params = new URLSearchParams(this.props.history.location.search);
      params.set("sd", toShortISOString(newStartDate));
      params.set("ed", toShortISOString(newEndDate));
      this.props.history.push({ 
        pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
        search: params.toString()
      });
    }
  }

  onShowRawDataChanged(event) {
    this.onTimePeriodChanged(event.target.checked ? TimePeriod.Dynamic : TimePeriod.Day);
    // this.setState({ showRawData: event.target.checked });
  }

  onSelectionChanged(selectionStartDate, selectionEndDate) {
    const dateA = moment(selectionStartDate);
    const dateB = moment(selectionEndDate);

    let newSelectionStartDate;
    let newSelectionEndDate;
    if (dateB.isAfter(dateA)) {
      newSelectionStartDate = dateA;
      newSelectionEndDate = dateB;
    }
    else {
      newSelectionStartDate = dateB;
      newSelectionEndDate = dateA;
    }

    this.setState({ 
      selectionStartDate: newSelectionStartDate, 
      selectionEndDate: newSelectionEndDate ,
      selectionStartDateText: newSelectionStartDate.isValid ? newSelectionStartDate.format("DD/MM/YYYY HH:mm:ss") : "",
      selectionEndDateText: newSelectionEndDate.isValid ? newSelectionEndDate.format("DD/MM/YYYY HH:mm:ss") : ""
    });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("ssd", toShortISOString(newSelectionStartDate));
    params.set("sed", toShortISOString(newSelectionEndDate));
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  selectionStartDateChanged(newMoment) {
    this.setState({ selectionStartDate: newMoment });
  }

  selectionStartDateTextChanged(event) {
    this.setState({ selectionStartDateText: event.target.value });

    if (event.target.value.length === 0) {
      this.setState({ selectionStartDate: null });
    }
    else {
      const newMoment = moment(event.target.value, "DD/MM/YYYY HH:mm:ss", true);
      if (newMoment.isValid()) {
        this.setState({ selectionStartDate: newMoment });
      }
    }
  }

  selectionEndDateTextChanged(event) {
    this.setState({ selectionEndDateText: event.target.value });

    if (event.target.value.length === 0) {
      this.setState({ selectionEndDate: null });
    }
    else {
      const newMoment = moment(event.target.value, "DD/MM/YYYY HH:mm:ss", true);
      if (newMoment.isValid()) {
        this.setState({ selectionEndDate: newMoment });
      }
    }
  }

  deleteData() {
    const sensorId = this.props.sensor.id;
    const atomicSensorId = this.props.match.params.id;
    this.props.onDeleteDataClicked(atomicSensorId, this.state.selectionStartDate, this.state.selectionEndDate);
    // this.props.deleteSamples(sensorId, atomicSensorId, this.state.selectionStartDate, this.state.selectionEndDate);
  }

  getGraphData(propertyName, samples) {

    const commonCode = get(this.props.sensor.properties, `[${propertyName}].metadata.unitCode`, null);
    const unitCode = UNITCODES[commonCode];

    const graphData = [];
    for (let i = 0; i < samples.length; i++) {
      const sample = samples[i];
      
      let data = {};

      if (commonCode === "KEL" && propertyName === "temperature") {
        if (sample.value !== undefined) {
          data.value = Math.round((sample.value - 273.15) * 10) / 10;
        }
        else {
          data.max = Math.round((sample.max - 273.15) * 10) / 10;
          data.min = Math.round((sample.min - 273.15) * 10) / 10;
        }
        data.unit = "°C";
      }
      else {
        data.value = sample.value;
        data.max = sample.max;
        data.min = sample.min;
        data.unit = unitCode;
      }

      if (sample.sampledAt) {
        data.datetime = moment(sample.sampledAt).toISOString();
      }
      else {
        data.datetime = moment(sample.datetime).toISOString();
      }
        
      data.entityId = "test";
      graphData.push(data);
    }

    // Remove duplicate graphData samples (with same datetime)
    for (let i = 0; i < graphData.length - 1; i++) {
      if (graphData[i].datetime === graphData[i + 1].datetime) {
        graphData.splice(i, 1);
        i--;
      }
    }
     
    return graphData;
  }

  render() {
    // console.log("AtomicSensorData render", this.state, this.props);

    // Fix startDate and endDate to reflect timePeriod
    let newStartDate;
    let newEndDate;
    if (this.state.timePeriod === TimePeriod.Week) {
      newStartDate = this.state.startDate.clone().startOf("isoWeek");
      newEndDate = this.state.endDate.clone().endOf("isoWeek");
    }
    else if (this.state.timePeriod === TimePeriod.Month) {
      newStartDate = this.state.startDate.clone().startOf("month");
      newEndDate = this.state.endDate.clone().endOf("month");
    }
    else {
      newStartDate = this.state.startDate.clone();
      newEndDate = this.state.endDate.clone();
    }

    // Set span for graph (undefined = raw data)
    let timeScale;
    if (this.state.timePeriod !== TimePeriod.Dynamic || newEndDate.diff(newStartDate, "hours") > 24) {
      if (this.state.timePeriod === TimePeriod.Day) {
        timeScale = "hour";
      }
      else {
        timeScale = "day";
      }
    }
    
    const atomicSensorId = this.props.match.params.id;
    const currentSamplesQueryHash = `${this.props.sensor.id}-${atomicSensorId}-${newStartDate}-${newEndDate}-${timeScale}`;
    if (isEmpty(this.props.match.params.id) || isEmpty(this.props.type) || this.props.sensor.samplesQueryHash !== currentSamplesQueryHash) {
      return null;
    }

    let samples = JSON.parse(JSON.stringify(this.props.sensor.samples));
    // console.log(samples);
    samples = this.getGraphData(this.props.type, samples);
    // console.log(samples);

    // if (this.state.sortBy && this.state.sortOrder) {
    //   samples = samples.sort((a, b) => {
    //     if (this.state.sortBy === "value") {
    //       // Sort by value
    //       return this.state.sortOrder === "asc" ? a.value - b.value : b.value - a.value;
    //     }
    //     else if (this.state.sortBy === "sampledAt") {
    //       // Sort by sampledAt date
    //       let aDate = moment(a.sampledAt);
    //       let bDate = moment(b.sampledAt);
    //       return this.state.sortOrder === "asc" ? aDate.diff(bDate) : bDate.diff(aDate);
    //     }
    //     return false;
    //   });
    // }

    const earliestMoment = moment().subtract(180, "days");

    // Calculate todays hours
    let hours = [];
    // if (moment().isSame(this.state.date, "day")) {
    //   hours = [...Array(moment().hours() + 1).keys()];
    // }
    // else {
      hours = [...Array(24).keys()];
    // }

    // Calculate the last ~24 weeks
    let weeks = [];
    const earliestWeek = earliestMoment.isoWeek();
    const currentWeek = moment().isoWeek();
    if (currentWeek > earliestWeek) {
      weeks = [...Array(currentWeek + 1).keys()].slice(earliestWeek);
    }
    else {
      weeks = [...Array(currentWeek + 1).keys()].slice(1);
      const totaltWeeksLastYear = moment().subtract(currentWeek, "week").isoWeek();
      weeks = [...Array(totaltWeeksLastYear + 1).keys()].slice(earliestWeek).concat(weeks);
    }
    // weeks.reverse();

    // Calculate the last ~6 months
    let months = [];
    const earliestMonth = earliestMoment.month();
    const currentMonth = moment().month();
    if (currentMonth > earliestMonth) {
      months = [...Array(currentMonth + 1).keys()].slice(earliestMonth);
    }
    else {
      months = [...Array(currentMonth + 1).keys()];
      months = [...Array(12).keys()].slice(earliestMonth).concat(months);
    }

    let timePicker;
    let datePicker;
    let weekPicker;
    let monthPicker;
    if (this.state.timePeriod === TimePeriod.Hours) {
      timePicker = (
        <Dropdown
          value={this.state.startDate.hours()}
          onChange={(option) => { this.onTimeChanged(option.value)}}
          options={hours.map(hour => ({ label: `${hour}:00`, value: hour }))}
        />
      );
    }
    else if (this.state.timePeriod === TimePeriod.Day || this.state.timePeriod === TimePeriod.Dynamic) {
      const endHour = this.state.endDate.clone().add(1, "minute").startOf("hour").hours();
      datePicker = (
        <>
          <Datepicker
            date={this.state.startDate.toDate()}
            minDate={earliestMoment.toDate()}
            maxDate={new Date()}
            onChange={this.onDateChanged}
          />
          <Dropdown
            value={this.state.startDate.hours()}
            onChange={(option) => { this.onStartTimeChanged(option.value)}}
            options={hours.map(hour => ({ label: `${hour}:00`, value: hour }))}
          />
          <Dropdown
            value={endHour === 0 ? 24 : endHour}
            onChange={(option) => { this.onEndTimeChanged(option.value)}}
            options={[...Array(24).keys()].map(hour => ({ label: `${hour+1}:00`, value: hour+1 }))}
          />
          <CheckboxBlock label="Raw data" isChecked={this.state.timePeriod === TimePeriod.Dynamic} onClick={this.onShowRawDataChanged} />
        </>
      );
    }
    else if (this.state.timePeriod === TimePeriod.Week) {
      const weekNumber = this.state.startDate.isoWeek();
      weekPicker = (
        <Dropdown
          value={weekNumber}
          onChange={(option) => { this.onWeekNumberChanged(option.value)}}
          options={weeks.map(week => ({ label: week, value: week }))}
        />
      );
    }
    else if (this.state.timePeriod === TimePeriod.Month) {
      const month = this.state.startDate.month();
      monthPicker = (
        <Dropdown
          value={month}
          onChange={(option) => { this.onMonthChanged(option.value)}}
          options={months.map(month => ({ label: moment().set("month", month).format("MMMM"), value: month }))}
        />
      );
    }

    const timePeriod = this.state.timePeriod === TimePeriod.Dynamic ? TimePeriod.Day : this.state.timePeriod;
    let heading = (
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        <SegmentedControl
          name="timeperiod"
          lightBg
          value={timePeriod}
          onChange={this.onTimePeriodChanged}
          options={[
            // { label: "Hour", value: TimePeriod.Hours },
            { label: "Day", value: TimePeriod.Day, default: true },
            { label: "Week", value: TimePeriod.Week },
            { label: "Month", value: TimePeriod.Month }
          ]}
        />
        {timePicker}
        {datePicker}
        {weekPicker}
        {monthPicker}
      </div>
    );

    let deleteElement;
    if (this.props.auth.hasInstallerRole) {
      deleteElement = (
        <div className={style.block}>
          <div>
            <div className={style.blockTitle}>Delete data in a range</div>
            <div className={style.blockDescription}>Select a data range in the graph or enter date values below.</div>
            <div className={style.blockDescription}>
              <ul>
                <li>Deleted data CANNOT be recovered later.</li>
                <li>Aggregated data points can be misleading. View Raw data to be 100% certain that you have selected the correct range.</li>
                <li>Data deletion can take a few of minutes. Check back later to verify deletion.</li>
              </ul>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <InputBox
                style={{ width: "200px" }}
                label="Start date"
                value={this.state.selectionStartDateText}
                valid={moment(this.state.selectionStartDateText, "DD/MM/YYYY HH:mm:ss", true).isValid()}
                required
                showValidIcon
                showValidInfo
                onChange={this.selectionStartDateTextChanged}
                info={moment(this.state.selectionStartDateText, "DD/MM/YYYY HH:mm:ss", true).isValid() ? `Valid date` : "Invalid date"}
              />
              <InputBox
                style={{ width: "200px" }}
                label="End date"
                value={this.state.selectionEndDateText}
                valid={moment(this.state.selectionEndDateText, "DD/MM/YYYY HH:mm:ss", true).isValid()}
                required
                showValidIcon
                showValidInfo
                onChange={this.selectionEndDateTextChanged}
                info={moment(this.state.selectionEndDateText, "DD/MM/YYYY HH:mm:ss", true).isValid() ? "Valid date" : "Invalid date"}
              />
            </div>
          </div>
          <div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <SmallButton text="Delete" color="red" noLeftMargin onClick={this.deleteData} disabled={!moment(this.state.selectionStartDateText, "DD/MM/YYYY HH:mm:ss", true).isValid() || !moment(this.state.selectionEndDateText, "DD/MM/YYYY HH:mm:ss", true).isValid()} />
          </div>
        </div>
      );
    }

    let stepSize = undefined; // undefined means that the step size is automatically calculated (does not support holes in time series)
    if (this.state.timePeriod === TimePeriod.Day) {
      stepSize = StepSize.Hour;
    }
    else if (this.state.timePeriod === TimePeriod.Week || this.state.timePeriod === TimePeriod.Month) {
      stepSize = StepSize.Day;
    }

    return (
      <>
        <div style={{ paddingTop: "20px" }} />
        {heading}
        <div style={{ paddingTop: "20px" }} />
        <GraphContainer 
          type={this.props.type}
          startDate={newStartDate.toDate()}
          endDate={newEndDate.toDate()}
          samples={samples}
          timePeriod={this.state.timePeriod}
          stepSize={stepSize}
          isLoading={this.props.isLoadingSamples}
          statusMessage={samples.length === 0 && !this.props.isLoadingSamples ? (newStartDate.isAfter(moment()) ? "Cannot look into the future (yet)" : "No data available") : undefined}
          enableSelection={true}
          selectionStartDate={this.state.selectionStartDate && this.state.selectionStartDate.isValid ? this.state.selectionStartDate.toDate() : undefined}
          selectionEndDate={this.state.selectionEndDate && this.state.selectionEndDate.isValid ? this.state.selectionEndDate.toDate() : undefined}
          onSelectionChanged={this.onSelectionChanged}
        />
        { deleteElement }
        <div style={{ paddingTop: "40px" }} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    sensor: state.sensor,
    auth: state.auth,
    isLoadingSamples: state.loading[API.GET_SAMPLES]
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getSensorSamples: sensorActions.getSensorSamples,
    // deleteSamples: sensorActions.deleteSamples
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(AtomicSensorData);