import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Title from "../Title";
import InputBox from "../InputBox";
import SmallButton from "../SmallButton";
import styled from "styled-components";

class LargeHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showNameInput: false,
      newName: ""
    };

    this.onNameChange = this.onNameChange.bind(this);
    this.onEditNameClicked = this.onEditNameClicked.bind(this);
    this.onEditNameSave = this.onEditNameSave.bind(this);
    this.onEditNameCancel = this.onEditNameCancel.bind(this);
  }

  onNameChange(event) {
    this.setState({ newName: event.target.value });
  }

  onEditNameClicked() {
    this.setState({ showNameInput: true, newName: this.props.title });
  }

  onEditNameSave() {
    this.setState({ showNameInput: false }, () => this.props.onNameSaveClicked(this.state.newName));
  }

  onEditNameCancel() {
    this.setState({ showNameInput: false, newName: "" });
  }

  render() {

    if (this.state.showNameInput) {
      return (
        <Margins>
          <Container justify="start">
            <TextContainer inputBox>
              <InputBox
                value={this.state.newName}
                valid={this.state.newName}
                onChange={this.onNameChange}
                required
                showValidIcon
                noMargin
                style={{ minWidth: "300px", width: "100%" }}
              />
            </TextContainer>
            <ButtonContainer>
              <SmallButton text="Save" onClick={this.onEditNameSave} disabled={!this.props.canUpdateName || this.props.isLoading} noLeftMargin />
              <SmallButton text="Cancel" onClick={this.onEditNameCancel} color="red" />
            </ButtonContainer>
          </Container>
        </Margins>
      );
    }

    const onlyShowDelete = this.props.onDeleteClicked && !this.props.onEditClicked && !this.props.onCopyURLClicked;
    
    return (
      <Margins>
        <Container>
          <TextContainer>
            <Title text={this.props.title} inLargeHeader />
            <TagContainer>
              { this.props.tags }
            </TagContainer>
            <div role="button" tabIndex="0" onClick={this.onEditNameClicked} onKeyDown={this.onEditNameClicked}>
              { this.props.canUpdateName && <FontAwesomeIcon icon={faPencilAlt} size="lg" color="#1C4D82" /> }
            </div>
          </TextContainer>
          <ButtonContainer>
            { this.props.onEditClicked && <SmallButton text="Edit" onClick={this.props.onEditClicked} disabled={!this.props.canUpdate || this.props.isLoading} noLeftMargin /> }
            { this.props.onCopyURLClicked && <SmallButton text="Copy map link" onClick={this.props.onCopyURLClicked} noLeftMargin /> }
            { this.props.onDeleteClicked && <SmallButton text="Delete" onClick={this.props.onDeleteClicked} color="red" disabled={!this.props.canUpdate || this.props.isLoading} onlyLeftMargin={!onlyShowDelete} noLeftMargin={onlyShowDelete} /> }
          </ButtonContainer>
          { this.props.rightText && <RightText>{this.props.rightText}</RightText> }
        </Container>
      </Margins>
    );
  }
}

export default LargeHeader;

const Margins = styled.div`
  margin-left: 60px;
  margin-right: 60px;

  @media (max-width: 992px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 63.5px;
  justify-content: ${props => props.justify ?? "space-between"};
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
  width: 100%;
  max-width: calc(100% - 300px);

  // We want the text to be lower than the input box
  // - might standarize this later to be the same everywhere
  padding-top: ${props => props.inputBox ? "13.5px" : "24px"};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: end;
`;

const TagContainer = styled.div`
  display: inline-block;
  vertical-align: text-bottom;
`;

const RightText = styled.div`
  display: flex;
  font-style: italic;
  align-items: center;
`;

const SmallHeader = styled.div`
  display: block;
  width: 100%;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
`;