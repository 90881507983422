import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import JSONEditorReact from "./editor";
import Freetext from "../../components/Freetext";
import Loader from "../../components/Loader";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import * as screenActions from "../../actions/screens";

class ScreenConfigLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectionPath: [],
      hasLoadedState: false
    };

    this.onLayoutChanged = this.onLayoutChanged.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  onLayoutChanged(editorValue) {
    // console.log("ScreenConfigLayout.onLayoutChanged", editorValue);

    const form = { ...this.props.form };
    if (!form.config) { 
      form.config = {};
    }

    // Save either from JSON or text
    if (editorValue.json) {
      try {
        form.config.view = JSON.stringify(editorValue.json, null, 2);
      }
      catch (error) {
        form.config.view = null;
      }
    }
    else if (editorValue.text) {
      if (editorValue.text === "") {
        form.config.view = null;
      }
      else {
        form.config.view = editorValue.text;
      }
    }
    else {
      form.config.view = null;
    }

    this.props.updateScreenForm(form);
  }

  onSelectionChanged(selection) {
    // console.log("ScreenConfigLayout.onSelectionChanged", selection);

    // Get the path to the selected object
    const range = selection.ranges[0];
    const text = this.props.form.config.view ?? "";
    const start = range.anchor;
    const path = findSelectedObjectPath(text, start);

    // // Save the path and call the callback
    this.setState({
      selectionPath: path
    }, this.props.onUpdateLayoutSelectionPath(path));
  }

  render() {
    // console.log("ScreenConfigLayout.render.state", this.state);
    // console.log("ScreenConfigLayout.render.props", this.props);

    const canEdit = this.props.auth.hasSupportRole;

    if (!canEdit) {
      return null;
    }

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    const overlayView = { text: this.props.form.config.view ?? "" };

    return (
      <>
        <div style={{ paddingTop: "25px" }} />
        <Freetext header={`Edit the screen layout (BETA)`} content={`The layout is very flexible, but require some insight into CSS and our components (widgets).`} />
        <JSONEditorReact 
          content={overlayView}
          onChange={this.onLayoutChanged} 
          mode="text"
          indentation={2}
          tabSize={2}
          mainMenuBar={true}
          navigationBar={true}
          statusBar={true}
          onRenderMenu={(items) => {

            // Remove first 4 items
            items.splice(0, 4);

            // Remove transform
            items = items.filter((item) => item.className !== "jse-transform");

            // Add new component button
            items.push({
              type: "button",
              className: "jse-new-component",
              title: "New component",
              icon: faPlus,
              onClick: this.props.onShowAddView,
            });

            return items;
          }}
          onSelect={this.onSelectionChanged}
        />
        <div style={{ paddingTop: "40px" }} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    screen: state.screen,
    form: state.screen.form,
    isLoading: state.loading.screen,
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    updateScreenForm: screenActions.updateScreenForm,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenConfigLayout);

const findSelectedObjectPath = (text, start, depth = 0) => {
  
  if (start === -1) {
    return [];
  }
  
  // Find the preceding { before the start index (but for each } we find, we need to find a matching {)
  let objectPropertyNameEndIndex = -1;
  
  let lastBracket = null;
  let numObjects = 0;
  let numOfOpenEndedBrackets = 0;
  let numOfOpenEndedSquareBrackets = 0;

  let foundObjectContainer = false;
  let foundArrayContainer = false;

  let foundComma = false;

  for (let i = start; i >= 0; i--) {
    const char = text[i];
    // console.log("findSelectedObjectPath.char:", JSON.stringify(char), "numOEB:", numOfOpenEndedBrackets, "numOESB:", numOfOpenEndedSquareBrackets, "numObjects:", numObjects, "foundObjectContainer", foundObjectContainer, "foundArrayContainer", foundArrayContainer);
    // if (!foundComma && depth === 0) {
    //   console.log("findSelectedObjectPath.char:", JSON.stringify(char), "numOEB:", numOfOpenEndedBrackets, "numOESB:", numOfOpenEndedSquareBrackets, "numObjects:", numObjects);
    // }

    if (char === ",") {
      foundComma = true;
    }

    // Check if the next character is a backslash, if so, we are in a string and should skip the next character
    if (i > 0 && text[i-1] === "\\") {
      // console.log("skipping next character");
      i--;
      continue;
    }

    if (char === ":") {
      // If we are in a object, find the end of the property name (key)
      if (lastBracket === "{" && numOfOpenEndedBrackets === 0 && foundObjectContainer) {
        objectPropertyNameEndIndex = i;
        break;
      }
      
      // If we are in an array, find the end of the property name (key)
      if (lastBracket === "[" && numOfOpenEndedSquareBrackets === 0 && foundArrayContainer) {
        objectPropertyNameEndIndex = i;
        break;
      }
    }

    // If we detect a new object, wait for it to be closed before looking for the property name or count the number of objects
    if (char === "}") {
      numOfOpenEndedBrackets++;
    }
    
    // Close the object if we are in an open ended object
    if (char === "{") {
      if (numOfOpenEndedBrackets > 0) {
        numOfOpenEndedBrackets--;

        if (numOfOpenEndedBrackets === 0) {
          numObjects++;
        }
      }
      else {
        // Found the object the cursor is in
        foundObjectContainer = true;
        numObjects = 0;
      }
    }

    // If we detect a new array, wait for it to be closed before looking for the property name or count the number of objects
    if (char === "]") {
      numOfOpenEndedSquareBrackets++;
    }
    
    // Close the array if we are in an open ended array
    if (char === "[") {

      if (numOfOpenEndedSquareBrackets > 0) {
        numOfOpenEndedSquareBrackets--;
      }
      else {
        // Found the array the cursor is in
        foundArrayContainer = true;
      }
    }

    // Remember the last bracket we found
    if (["[", "]", "{", "}"].includes(char)) {
      lastBracket = char;
    }
  }

  // console.log("objectPropertyNameEndIndex", objectPropertyNameEndIndex);
  // console.log("numOfOpenEndedBrackets", numOfOpenEndedBrackets);
  // console.log("numOfOpenEndedSquareBrackets", numOfOpenEndedSquareBrackets);
  // console.log("numObjects", numObjects);

  // Find the property name (key) before the start index
  const objectPropertyStartIndex = text.lastIndexOf("\"", objectPropertyNameEndIndex - 2);
  if (objectPropertyStartIndex === -1) {
    return [];
  }

  const objectProperty = text.substring(objectPropertyStartIndex + 1, objectPropertyNameEndIndex - 1);
  // console.log("objectProperty", objectProperty);
  // console.log("sub",  text.substring(0, objectPropertyNameEndIndex));

  // If we are in an array, find the index of the current object
  if (lastBracket === "[") {
    // console.log("array index", numObjects);

    // Count one less if we did not start inside an object
    // console.log("foundObjectContainer", foundObjectContainer);
    // console.log("foundArrayContainer", foundArrayContainer);
    if (!foundObjectContainer) {
      // if (numObjects > 0) {
        numObjects--;
      // }
    }

    return [...findSelectedObjectPath(text, objectPropertyStartIndex - 1, depth + 1), objectProperty, numObjects];
  }

  return [...findSelectedObjectPath(text, objectPropertyStartIndex - 1, depth + 1), objectProperty];
}
