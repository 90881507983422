import React, { Component } from "react";
import { Container, Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import Freetext from "../../components/Freetext";
import Loader from "../../components/Loader";
import SmallButton from "../../components/SmallButton";
import Checkbox from "../../components/Checkbox";
import SegmentedControl from "../../components/SegmentedControl";
import InputBox from "../../components/InputBox";
import * as screenActions from "../../actions/screens";
import * as locationActions from "../../actions/locations";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";
import TextBox from "../../components/TextBox";

class ScreenConfigDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customBearing: 0,
      showCustomBearing: false,
      useInitialLocation: false,
      useYourLocation: false,
      hasLoadedState: false
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onShowTitleChanged = this.onShowTitleChanged.bind(this);
    this.onHideMapBackgroundChanged = this.onHideMapBackgroundChanged.bind(this);
    this.onBackgroundColorChanged = this.onBackgroundColorChanged.bind(this);
    this.onUseThreeDChanged = this.onUseThreeDChanged.bind(this);
    this.onUseCircleLabelsChanged = this.onUseCircleLabelsChanged.bind(this);
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.onAllowInteractionsChanged = this.onAllowInteractionsChanged.bind(this);
    this.onShowVacantChanged = this.onShowVacantChanged.bind(this);
    this.onShowOccupiedChanged = this.onShowOccupiedChanged.bind(this);
    this.onShowVacantBookedChanged = this.onShowVacantBookedChanged.bind(this);
    this.onUseMeetingRoomCalendarsChanged = this.onUseMeetingRoomCalendarsChanged.bind(this);
    this.onInitialBearingChanged = this.onInitialBearingChanged.bind(this);
    this.onCustomBearingChanged = this.onCustomBearingChanged.bind(this);
    this.onInitialPitchChanged = this.onInitialPitchChanged.bind(this);
    this.onInitialLocationNELatitudeChanged = this.onInitialLocationNELatitudeChanged.bind(this);
    this.onInitialLocationNELongitudeChanged = this.onInitialLocationNELongitudeChanged.bind(this);
    this.onInitialLocationSWLatitudeChanged = this.onInitialLocationSWLatitudeChanged.bind(this);
    this.onInitialLocationSWLongitudeChanged = this.onInitialLocationSWLongitudeChanged.bind(this);
    this.onClearInitialLocation = this.onClearInitialLocation.bind(this);
    this.onYourLocationLatitudeChanged= this.onYourLocationLatitudeChanged.bind(this);
    this.onYourLocationLongitudeChanged = this.onYourLocationLongitudeChanged.bind(this);
    this.onYourLocationColorChanged = this.onYourLocationColorChanged.bind(this);
    this.onStatusFilterChanged = this.onStatusFilterChanged.bind(this);
    this.onLabelFilterChanged = this.onLabelFilterChanged.bind(this);
    this.onIconFilterChanged = this.onIconFilterChanged.bind(this);
    this.onLocationIdsChanged = this.onLocationIdsChanged.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onCopyURL = this.onCopyURL.bind(this);
    this.onViewLocationClick = this.onViewLocationClick.bind(this);
    this.onChangeLocationClick = this.onChangeLocationClick.bind(this);
    this.mapConfigurationElement = this.mapConfigurationElement.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (prevState.hasLoadedState) {
      return null;
    }

    // Only copy props if they exist and if screen id is correct
    if (!isEmpty(nextProps.screen) && !isEmpty(nextProps.screen.id) && nextProps.configId === nextProps.screen.id) {

      const floorId = get(nextProps.form, "config.initialFloorId", null) ?? get(nextProps.screen, "config.initialFloorId", null);
      if (floorId) {

        // Fetch floor map
        if (nextProps.location.floorMapId !== floorId) {
          nextProps.getLocation(floorId);
          nextProps.getFloorMap(floorId);
        }

        const initialBearing = get(nextProps.form, "config.initialBearing", 0);

        const newState = {
          showCustomBearing: ![0, 90, 180, 270].includes(initialBearing),
          customBearing: initialBearing,
          hasLoadedState: true
        };

        return newState;
      }
      else {

        const newState = {
          hasLoadedState: true
        };
        
        return newState;
      }
      
    }
    else if (nextProps.match.params.id === "new" && get(nextProps.form, "config.initialFloorId", null)) {
      
      // Fetch floor map
      nextProps.getFloorMap(nextProps.form.config.initialFloorId);

      const newState = {
        hasLoadedState: true
      };
      
      return newState;
    }

    return null;
  }

  onBackClick() {
    this.props.resetScreenForm();
    this.props.history.push(`/companies/${this.props.match.params.companyId}/screens`);
  }

  onViewLocationClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${this.props.form.config.initialFloorId}/locations`);
  }

  onChangeLocationClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/screens/configs/${this.props.configId}/edit/locations/root`);
  }

  onNameChange(event) {
    const form = { ...this.props.form };
    form.name = event.target.value;
    this.props.updateScreenForm(form);
  }

  onShowTitleChanged() {
    const form = { ...this.props.form };
    form.config.showTitle = !get(form, "config.showTitle", false);
    this.props.updateScreenForm(form);
  }

  onHideMapBackgroundChanged() {
    const form = { ...this.props.form };
    form.config.hideMapBackground = !get(form, "config.hideMapBackground", false);
    this.props.updateScreenForm(form);
  }

  onBackgroundColorChanged(event) {
    const form = { ...this.props.form };
    form.config.backgroundColor = event.target.value;
    this.props.updateScreenForm(form);
  }

  onUseThreeDChanged() {
    const form = { ...this.props.form };
    form.config.useThreeD = !get(form, "config.useThreeD", false);
    this.props.updateScreenForm(form);
  }

  onUseCircleLabelsChanged() {
    const form = { ...this.props.form };
    form.config.useCircleLabels = !get(form, "config.useCircleLabels", false);
    this.props.updateScreenForm(form);
  }

  onLanguageChanged(event) {
    const form = { ...this.props.form };
    form.config.language = event.target.value === "" ? undefined : event.target.value;
    this.props.updateScreenForm(form);
  }

  onAllowInteractionsChanged() {
    const form = { ...this.props.form };
    form.config.allowInteractions = !get(form, "config.allowInteractions", false);
    this.props.updateScreenForm(form);
  }

  onShowVacantChanged() {
    const form = { ...this.props.form };
    form.config.showVacant = !get(form, "config.showVacant", true);
    this.props.updateScreenForm(form);
  }

  onShowOccupiedChanged() {
    const form = { ...this.props.form };
    form.config.showOccupied = !get(form, "config.showOccupied", true);
    this.props.updateScreenForm(form);
  }

  onShowVacantBookedChanged() {
    const form = { ...this.props.form };
    form.config.showVacantBooked = !get(form, "config.showVacantBooked", false);
    this.props.updateScreenForm(form);
  }

  onUseMeetingRoomCalendarsChanged() {
    const form = { ...this.props.form };
    form.config.useMeetingRoomCalendars = !get(form, "config.useMeetingRoomCalendars", false);
    this.props.updateScreenForm(form);
  }

  onInitialBearingChanged(value) {
    const form = { ...this.props.form };

    if (value === "custom") {
      form.config.initialBearing = this.state.customBearing;
    }
    else {
      form.config.initialBearing = value;
    }

    this.props.updateScreenForm(form);
    this.setState({ showCustomBearing: (value === "custom") });
  }

  onCustomBearingChanged(event) {
    const form = { ...this.props.form };
    form.config.initialBearing = event.target.value;
    this.props.updateScreenForm(form);
    this.setState({ customBearing: event.target.value });
  }

  onInitialPitchChanged(event) {
    const form = { ...this.props.form };
    form.config.initialPitch = event.target.value;
    this.props.updateScreenForm(form);
  }

  onInitialLocationNELatitudeChanged(event) {
    const form = { ...this.props.form };

    if (!form.config.initialLocation) {
      form.config.initialLocation = { ne: {}, sw: {} };
    }

    form.config.initialLocation.ne.lat = event.target.value;
    this.props.updateScreenForm(form);
  }

  onInitialLocationNELongitudeChanged(event) {
    const form = { ...this.props.form };
    
    if (!form.config.initialLocation) {
      form.config.initialLocation = { ne: {}, sw: {} };
    }

    form.config.initialLocation.ne.lng = event.target.value;
    this.props.updateScreenForm(form);
  }

  onInitialLocationSWLatitudeChanged(event) {
    const form = { ...this.props.form };

    if (!form.config.initialLocation) {
      form.config.initialLocation = { ne: {}, sw: {} };
    }

    form.config.initialLocation.sw.lat = event.target.value;
    this.props.updateScreenForm(form);
  }

  onInitialLocationSWLongitudeChanged(event) {
    const form = { ...this.props.form };
    
    if (!form.config.initialLocation) {
      form.config.initialLocation = { ne: {}, sw: {} };
    }

    form.config.initialLocation.sw.lng = event.target.value;
    this.props.updateScreenForm(form);
  }


  onClearInitialLocation() {
    const form = { ...this.props.form };
    form.config.initialLocation = null;
    this.props.updateScreenForm(form);
  }

  onYourLocationLatitudeChanged(event) {
    const form = { ...this.props.form };

    if (!form.config.yourLocation) {
      form.config.yourLocation = {};
    }

    form.config.yourLocation.lat = event.target.value;
    this.props.updateScreenForm(form);
  }

  onYourLocationLongitudeChanged(event) {
    const form = { ...this.props.form };
    
    if (!form.config.yourLocation) {
      form.config.yourLocation = {};
    }

    form.config.yourLocation.lng = event.target.value;
    this.props.updateScreenForm(form);
  }

  onYourLocationColorChanged(event) {
    const form = { ...this.props.form };
    
    if (!form.config.yourLocation) {
      form.config.yourLocation = {};
    }
    
    form.config.yourLocation.color = event.target.value;
    this.props.updateScreenForm(form);
  }

  onStatusFilterChanged(event) {
    const form = { ...this.props.form };
    form.config.statusFilter = event.target.value;
    this.props.updateScreenForm(form);
  }

  onLabelFilterChanged(event) {
    const form = { ...this.props.form };
    form.config.labelFilter = event.target.value;
    this.props.updateScreenForm(form);
  }

  onIconFilterChanged(event) {
    const form = { ...this.props.form };
    form.config.iconFilter = event.target.value;
    this.props.updateScreenForm(form);
  }

  onLocationIdsChanged(event) {
    const form = { ...this.props.form };
    form.config.locationIds = event.target.value;
    this.props.updateScreenForm(form);
  }

  onDelete() {
    const { push } = this.props.history;
    this.props.deleteScreen(this.props.configId, push);
  }

  onCopyURL() {
    // const url = 'https://map.bldng.ai/' + this.props.match.params.companyId + '/' + this.props.configId;
    let domain = window.location.host.replace("admin.", "screen.");
    const url = `https://${domain}/${this.props.match.params.companyId}/${this.props.configId}`;

    // Add url to clipboard
    window.Clipboard.copy(url);
  }

  mapConfigurationElement() {

    const canEdit = this.props.auth.hasAdminRole;

    // Get yourLocation to render in map
    var yourLocationFeature = null;
    const yourLocationLat = get(this.props.form, "config.yourLocation.lat", 0);
    const yourLocationLng = get(this.props.form, "config.yourLocation.lng", 0);
    if (yourLocationLat && yourLocationLng) {
      yourLocationFeature = { 
        type: "Feature",
        geometry: { 
          type: "Point", 
          coordinates: [yourLocationLng, yourLocationLat] 
        }
      };
    }
    
    // Validate hex color
    const yourLocationColorValid = /^(#[A-Fa-f0-9]{3,6})?$/i.test(get(this.props.form, "config.yourLocation.color", ""));

    const inDev = window.location.origin === "https://admin.dev.bldng.ai";
    const tempAuthCheck = inDev || ["christoffer@bldng.ai", "jorgen@bldng.ai"].includes(get(this.props.auth, "user.email", ""));

    return (
      <>
        <div style={{ paddingTop: "25px" }} />
        <Freetext header="Initial floor map" content="This floor is show when the screen is first loaded. The user can navigate to other floors if you include the floor navigation module in the Overlay, else this will be the only floor shown." />
        <Row>
          <Col>
            { (get(this.props.form, "config.initialFloorId", null) || get(this.props.screen, "config.initialFloorId", null)) && <SmallButton text="View floor" color="white" onClick={this.onViewLocationClick} disabled={!canEdit} noLeftMargin /> }
            { (get(this.props.form, "config.initialFloorId", null) || get(this.props.screen, "config.initialFloorId", null)) && <SmallButton text="Change floor" color="white" onClick={this.onChangeLocationClick} disabled={!canEdit} /> }
            { !get(this.props.form, "config.initialFloorId", null) && !get(this.props.screen, "config.initialFloorId", null) && <SmallButton text="Add floor" color="white" onClick={this.onChangeLocationClick} disabled={!canEdit} noLeftMargin /> }
          </Col>
        </Row>
        <div style={{ marginTop: "40px" }} />
        <Freetext header="General options" />
        <div style={{ marginTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show name as a title on map" description="(deprecated)" isChecked={get(this.props.form, "config.showTitle", false)} onClick={this.onShowTitleChanged} disabled={!canEdit} />
          </Col>
        </Row>
        {/* <div style={{ marginTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show numbered circlular labels in map" description="(instead of full location names)" isChecked={get(this.props.form, "config.useCircleLabels", false)} onClick={this.onUseCircleLabelsChanged} disabled={!canEdit} />
          </Col>
        </Row> */}
        {
          tempAuthCheck && (
            <>
              <div style={{ marginTop: "20px" }} />
              <Row>
                <Col>
                  <Checkbox label="Show map in 3D" description="(requires MazeMap 3D maps)" isChecked={get(this.props.form, "config.useThreeD", false)} onClick={this.onUseThreeDChanged} disabled={!canEdit} />
                </Col>
              </Row>
            </>
          )
        }
        <div style={{ marginTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Allow interactions" description="(scrolling/zooming)" isChecked={get(this.props.form, "config.allowInteractions", false)} onClick={this.onAllowInteractionsChanged} disabled={!canEdit} />
          </Col>
        </Row>

        <div style={{ marginTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Hide map background" description="(remove parks, roads, buildings etc. from the surroundings)" isChecked={get(this.props.form, "config.hideMapBackground", false)} onClick={this.onHideMapBackgroundChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <InputBox
          label="Background color in map (hex)"
          placeholder="ex. #f2f2f2"
          value={get(this.props.form, "config.backgroundColor", "")}
          onChange={this.onBackgroundColorChanged}
          disabled={!canEdit}
          style={{ width: "100%", maxWidth: "200px" }}
        />
        <InputBox
          label="Override device language (en/no/se)"
          placeholder="ex. no"
          value={get(this.props.form, "config.language", "")}
          onChange={this.onLanguageChanged}
          disabled={!canEdit}
          style={{ width: "100%", maxWidth: "300px" }}
        />

        <div style={{ marginTop: "40px" }} />
        <Freetext header="Room status in map" />
        <div style={{ marginTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show vacant status" isChecked={get(this.props.form, "config.showVacant", true)} onClick={this.onShowVacantChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ marginTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show occupied status" isChecked={get(this.props.form, "config.showOccupied", true)} onClick={this.onShowOccupiedChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ marginTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Use meeting room calendars" isChecked={get(this.props.form, "config.useMeetingRoomCalendars", false)} onClick={this.onUseMeetingRoomCalendarsChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ marginTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show no-show status" description="(activates 5 min after the meeting starts)" isChecked={get(this.props.form, "config.showVacantBooked", false)} onClick={this.onShowVacantBookedChanged} disabled={!canEdit} />
          </Col>
        </Row>

        <div style={{ marginTop: "40px" }} />
        <Freetext header="Locations" content="By default all locations in the floor map will display their room status. If you don't want that, you can specify which locations to include here." />
        <TextBox
          label="Location ids (comma separated)"
          placeholder="ex. 65e18ad60772899bb08666fe, 65e18ad60772899bb08666ff"
          value={get(this.props.form, "config.locationIds", "")}
          onChange={this.onLocationIdsChanged}
          disabled={!canEdit}
          style={{ width: "100%", maxWidth: "400px" }}
        />

        <div style={{ marginTop: "40px" }} />
        <Freetext header="Filters" content='Enhance your map presentations by applying location filters in a flexible format. Utilize "and", "or", and "not" conditions to specify the types of locations you want to display. When you set these filters, the map will only show locations that match your specified criteria. For instance, using the filter "zone|room|!toilet" will result in the map displaying data for all zones and rooms, excluding toilets. This allows for a more tailored and precise presentation of location-based data on your map.' />
        <InputBox
          label="Status filter (which location type should show status)"
          placeholder="ex. meeting|quiet"
          value={get(this.props.form, "config.statusFilter", "")}
          onChange={this.onStatusFilterChanged}
          disabled={!canEdit}
          style={{ width: "100%", maxWidth: "400px" }}
        />
        <InputBox
          label="Label filter (which location type should show labels)"
          placeholder="ex. meeting|zone"
          value={get(this.props.form, "config.labelFilter", "")}
          onChange={this.onLabelFilterChanged}
          disabled={!canEdit}
          style={{ width: "100%", maxWidth: "400px" }}
        />

        <div style={{ marginTop: "40px" }} />
        {/* NB! The screen might have a different aspect ratio than what is shown here. */}
        {
          tempAuthCheck ? (
            <Freetext header={`Map rotation, pitch and bounds`} headerDescription="(optional)" content={`Choose the rotation and pitch of the map first, then the boundaries. Either pan/zoom in the map or enter coordinates. Both north-east and south-west is required to define a boundary. Default will zoom to show the whole floor map.`} />
          ) : (
            <Freetext header={`Map rotation and bounds`} headerDescription="(optional)" content={`Choose the rotation of the map first, then the boundaries. Either pan/zoom in the map or enter coordinates. Both north-east and south-west is required to define a boundary. Default will zoom to show the whole floor map.`} />
          )
        }
        <div style={{ marginTop: "10px" }} />
        <SegmentedControl
          name="bearing"
          title="Initial map rotation"
          value={this.state.showCustomBearing ? "custom" : get(this.props.form, "config.initialBearing", 0)}
          onChange={this.onInitialBearingChanged} 
          options={[ 
            { label: "North", value: 0, default: true },
            { label: "East", value: 90 },
            { label: "South", value: 180 },
            { label: "West", value: 270 },
            { label: "Custom", value: "custom" }
          ]}
          disabled={!canEdit}
        />
        { this.state.showCustomBearing && 
          <InputBox
            label="Custom bearing (North=0, East=90, South=180, West=270)"
            value={this.state.customBearing}
            onChange={this.onCustomBearingChanged}
            valid={Number(this.state.customBearing)}
            showValidIcon
            required
            style={{ maxWidth: "400px" }}
            disabled={!canEdit}
          />
        }

        {
          tempAuthCheck && (
            <InputBox
              label="Initial pitch (min=0, max=60)"
              value={get(this.props.form, "config.initialPitch", 0)}
              onChange={this.onInitialPitchChanged}
              valid={!isNaN(get(this.props.form, "config.initialPitch", 0)) && get(this.props.form, "config.initialPitch", 0) >= 0 && get(this.props.form, "config.initialPitch", 0) <= 60}
              showValidIcon
              required
              style={{ maxWidth: "400px" }}
              disabled={!canEdit}
            />
          )
        }
        
        <div style={{ width: "100%", maxWidth: "400px", paddingTop: "20px" }}>
          <InputBox 
            label="North-east latitude"
            placeholder="ex. 59.8991"
            value={get(this.props.form, "config.initialLocation.ne.lat", "")} 
            valid={Number(get(this.props.form, "config.initialLocation.ne.lat", 0))} 
            onChange={this.onInitialLocationNELatitudeChanged} 
            required
            showValidIcon
            disabled={!canEdit}
            // style={{ paddingTop: "0px" }}
            />
          <InputBox 
            label="North-east longitude"
            placeholder="ex. 10.6294"
            value={get(this.props.form, "config.initialLocation.ne.lng", "")} 
            valid={Number(get(this.props.form, "config.initialLocation.ne.lng", 0))} 
            onChange={this.onInitialLocationNELongitudeChanged} 
            required
            showValidIcon
            disabled={!canEdit}
            />
          <InputBox 
            label="South-west latitude"
            placeholder="ex. 59.8982"
            value={get(this.props.form, "config.initialLocation.sw.lat", "")} 
            valid={Number(get(this.props.form, "config.initialLocation.sw.lat", 0))} 
            onChange={this.onInitialLocationSWLatitudeChanged} 
            required
            showValidIcon
            disabled={!canEdit}
            />
          <InputBox 
            label="South-west longitude"
            placeholder="ex. 10.6276"
            value={get(this.props.form, "config.initialLocation.sw.lng", "")} 
            valid={Number(get(this.props.form, "config.initialLocation.sw.lng", 0))} 
            onChange={this.onInitialLocationSWLongitudeChanged} 
            required
            showValidIcon
            disabled={!canEdit}
            />
          <SmallButton
            text="Copy from map"
            onClick={this.props.onCopyInitialLocation}
            disabled={!canEdit}
            style={{ marginLeft: "0px", marginTop: "20px" }}
            />
          <SmallButton
            text="Clear"
            color="white"
            onClick={this.onClearInitialLocation}
            disabled={!canEdit}
            style={{ marginTop: "20px" }}
            />
        </div>

        <div style={{ marginTop: "40px" }} />
        <Freetext header={`Location marker`} headerDescription="(optional)" content={`Choose a placement for a "you are here" marker in the map. Either by placing a marker in the map or enter coordinates manually.`} />
        <div style={{ width: "100%", maxWidth: "400px" }}>
          <InputBox 
            label="Latitude"
            placeholder="ex. 59.8993"
            value={get(this.props.form, "config.yourLocation.lat", "")} 
            valid={Number(get(this.props.form, "config.yourLocation.lat", 0))} 
            onChange={this.onYourLocationLatitudeChanged} 
            required 
            showValidIcon
            disabled={!canEdit} 
            style={{ paddingTop: "0px" }}
          />
          <InputBox
            label="Longitude"
            placeholder="ex. 10.6282"
            value={get(this.props.form, "config.yourLocation.lng", "")} 
            valid={Number(get(this.props.form, "config.yourLocation.lng", 0))} 
            onChange={this.onYourLocationLongitudeChanged} 
            required 
            showValidIcon
            disabled={!canEdit} 
          />
          <InputBox 
            label="Marker color (hex)" 
            placeholder="ex. #1C4D82"
            value={get(this.props.form, "config.yourLocation.color", "")} 
            valid={yourLocationColorValid} 
            onChange={this.onYourLocationColorChanged} 
            required 
            showValidIcon
            disabled={!canEdit} 
          />
          <SmallButton
            text={get(this.props.form, "config.yourLocation.lat", false) || get(this.props.form, "config.yourLocation.lng", false) ? "Clear marker" : (this.props.mapMode === "simple_select" ? "Place marker in map" : "Cancel placement") }
            color={this.props.mapMode === "simple_select" && !get(this.props.form, "config.yourLocation.lat", false) && !get(this.props.form, "config.yourLocation.lng", false) ? "blue" : "red"}
            onClick={() => {
              if (get(this.props.form, "config.yourLocation.lat", false) || get(this.props.form, "config.yourLocation.lng", false)) {
                this.props.onClearYourLocation();
              }
              else {
                this.props.onMapModeChange(this.props.mapMode === "simple_select" ? "draw_point" : "simple_select")
              }
            }}
            disabled={!canEdit}
            style={{ marginLeft: "0px", marginTop: "20px" }}
          />
          {/* <SmallButton
            text="Clear"
            color="white"
            onClick={this.props.onClearYourLocation}
            disabled={!canEdit}
            style={{ marginLeft: "10px", marginTop: "20px" }}
          /> */}
        </div>
      </>
    )
  }

  render() {
    // console.log("ScreenConfigDetails.render", this.props);

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    if (this.props.configId !== "new" && isEmpty(this.props.screen)) {
      return (
        <div className={style.outerScroll}>
          <Container className={style.container}>
            <Row className={style.topRow}>
              <Col>
                <Freetext header="Woops!" content="Could not find what you were looking for." />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    let configElements = this.mapConfigurationElement();

    return (
      <>
        { configElements }
        <div style={{ paddingTop: "80px" }} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    screen: state.screen,
    form: state.screen.form,
    isLoading: state.loading.screen,
    isLoadingLocation: state.loading.location,
    isLoadingMap: state.loading[API.GET_FLOOR_MAP],
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
    user: state.auth.user,
    location: state.location
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getScreen: screenActions.getScreen,
    createScreen: screenActions.createScreen,
    updateScreen: screenActions.updateScreen,
    deleteScreen: screenActions.deleteScreen,
    updateScreenForm: screenActions.updateScreenForm,
    resetScreenForm: screenActions.resetScreenForm,
    deselectScreen: screenActions.deselectScreen,
    getLocation: locationActions.getLocation,
    getFloorMap: locationActions.getFloorMap
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenConfigDetails);