import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import PendingScreenList from "./pendingScreenList";
import style from "./style.module.scss";

class PendingScreens extends Component {

  render() {
    // console.log("PendingScreens", this.props);
    return (
      <>
        <Hidden xs sm md lg>
          <div className={ style.mainContainerHidden }>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <PendingScreenList {...this.props} />
                </div>
              </div>
              <div className={style.sideBar}></div>
            </div>
          </div>
        </Hidden>

        <Visible lg>
          <div className={style.mainContainerHidden}>
            <div className={style.scroll}>
              <PendingScreenList {...this.props} />
            </div>
          </div>
        </Visible>

        <Visible xs sm md>
          <div className={style.mainContainerHidden}>
            <div className={style.slimScroll}>
              <PendingScreenList {...this.props} />
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

export default PendingScreens;