import axios from "axios";
import Cookies from "universal-cookie";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { API_URL, SUDO_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const checkCookie = () => async (dispatch) => {

  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  if (accessToken) {
    dispatch({ type: types.AUTH_USER });
  }
  else {
    dispatch({ type: types.UNAUTH_USER });
  }
};

export const getCompanies = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_ADMIN_COMPANIES));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies`, await actions.getAxiosConfig(queryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_ADMIN_COMPANIES))
    ))
    .catch((error) => {
      console.log("GET_ADMIN_COMPANIES error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_ADMIN_COMPANIES));
      }
    });
};

export const getSudoCompanies = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SUDO_COMPANIES));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies`, await actions.getAxiosConfig(queryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_SUDO_COMPANIES)))
    )
    .catch((error) => {
      console.log("GET_SUDO_COMPANIES error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SUDO_COMPANIES));
      }
    });
};

export const changeCompany = (companyId) => (dispatch) => {
  localStorage.setItem("company_id", companyId);
  dispatch({ type: types.CLEAR_DATA });
  dispatch(getCompany(companyId));
};

// TODO: FIX THIS HACK
export const getCompany = () => async (dispatch) => {
  dispatch(actions.requestData(types.GET_COMPANY));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies`, await actions.getAxiosConfig())
    .then((response) => {

      dispatch(actions.receiveData(response.data, types.GET_ADMIN_COMPANIES));

      // Find company
      var companyId = null;
      var urlCompanyId = getCompanyId();
      var oldCompanyId = localStorage.getItem("company_id");
  
      if (!isEmpty(urlCompanyId)) {
        // Use companyId from URL
        companyId = urlCompanyId;
      }
      else if (!isEmpty(oldCompanyId)) {
        // Use companyId from localeStorage
        companyId = oldCompanyId;
      }
      
      if (companyId) {
        const company = response.data.companies.find((company) => (company._id === companyId));
        if (company) {
          dispatch(actions.receiveData(company, types.GET_COMPANY));
          return
        }
      }
      
      // If no company is selected - select the first one
      const firstCompany = get(response.data, "companies[0]", null);
      if (firstCompany) {
        dispatch(actions.receiveData(firstCompany, types.GET_COMPANY));
        return
      }

      dispatch(actions.receiveError("no company", types.GET_COMPANY));
    })
    .catch((error) => {
      console.log("GET_COMPANY error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_COMPANY));
      }
    });
};

export const getSudoCompany = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SUDO_COMPANY));

  const sudoUrl = await SUDO_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${sudoUrl}companies/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      const company = {
        ...response.data,
        _id: id
      };
      dispatch(actions.receiveData(company, types.GET_SUDO_COMPANY));
    })
    .catch((error) => {
      console.log("GET_COMPANY error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SUDO_COMPANY));
      }
    });
};

export const selectCompany = (company, redirect) => (dispatch) => {
  dispatch(actions.receiveData(company, types.GET_COMPANY));
  const companyId = getCompanyId();
  if (redirect) {
    redirect(`/companies/${companyId}/customers/${company._id}`);
  }
};

export const updateCompany = (id, company, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_COMPANY));

  const sudoUrl = await SUDO_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${sudoUrl}companies/${id}`, company, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_COMPANY));
      dispatch(getSudoCompany(id));
    })
    .catch((error) => {
      console.log("updateCompany error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_COMPANY));
      }
    });
};

export const createCompany = (company, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.CREATE_COMPANY));

  const sudoUrl = await SUDO_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${sudoUrl}companies`, company, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_COMPANY));
      if (redirect) {
        dispatch(getCompanies());
        redirect(`/companies/${companyId}/account`);
      }
    })
    .catch((error) => {
      console.log("createCompany error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_COMPANY));
      }
    });
};

export const deleteCompany = (id, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_COMPANY));

  const sudoUrl = await SUDO_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${sudoUrl}companies/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_COMPANY));
      if (redirect) {
        dispatch(getCompanies());
        redirect(`/`);
      }
    })
    .catch((error) => {
      console.log("deleteCompany error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_COMPANY));
      }
    });
};

export const syncCompanyMap = (id, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.SYNC_COMPANY_MAP));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${id}/mazemap/buildings`, {}, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.SYNC_COMPANY_MAP));
      if (redirect) {
        dispatch(getCompanies());
        redirect(`/companies/${companyId}/account`);
      }
    })
    .catch((error) => {
      console.log("syncCompanyMap error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.SYNC_COMPANY_MAP));
      }
    });
};

export const getCompanyMap = () => async (dispatch) => {
  dispatch(actions.requestData(types.GET_COMPANY_MAP));

  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const apiUrl = await API_URL();

  axios.get(`${apiUrl}companies/${companyId}/map`, await actions.getAxiosConfig())
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_COMPANY_MAP))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_COMPANY_MAP));
      }
    });
};

export const restartYanziWorker = () => async (dispatch) => {
  dispatch(actions.requestData(types.RESTART_YANZI_WORKER));

  const sudoUrl = await SUDO_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${sudoUrl}yanzi/generate-new-work-orders`, await actions.getAxiosConfig())
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.RESTART_YANZI_WORKER))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.RESTART_YANZI_WORKER));
      }
    });
};

export const syncYanzi = (companyId) => async (dispatch) => {
  dispatch(actions.requestData(types.SYNC_YANZI));

  const sudoUrl = await SUDO_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${sudoUrl}companies/${companyId}/sync-yanzi`, {}, await actions.getAxiosConfig())
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.SYNC_YANZI))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.SYNC_YANZI));
      }
    });
};

export const uploadCompanyMap = (id, body, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.UPLOAD_COMPANY_MAP));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${id}/map/create-features`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPLOAD_COMPANY_MAP));
      if (redirect) {
        dispatch(getCompanies());
        redirect(`/companies/${companyId}/account/advanced-settings`);
      }
    })
    .catch((error) => {
      console.log("syncCompanyMap error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPLOAD_COMPANY_MAP));
      }
    });
};

export const updateCompanyDraft = (draft) => async (dispatch) => {
  dispatch({ type: types.UPDATE_COMPANY_DRAFT, draft });
};

export const clearCompanyDraft = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_COMPANY_DRAFT });
};

export const sendConsentEmail = (email, admins) => async (dispatch) => {
  dispatch(actions.requestData(types.SEND_CONSENT_EMAIL));

  const sudoUrl = await SUDO_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const body = {
    emails: [email],
    admins
  };

  axios.post(`${sudoUrl}companies/${companyId}/send-consent-invitation`, body, await actions.getAxiosConfig())
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.SEND_CONSENT_EMAIL))
    ))
    .catch((error) => {
      console.log("sendConsentEmail error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.SEND_CONSENT_EMAIL));
      }
    });
}