import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row, Hidden, Visible } from "react-grid-system";
import { Route } from "react-router-dom";
import moment from "moment";
import Table from "../../components/Table";
import AtomicSensor from "./atomicSensor";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class AtomicSensors extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sortBy: "type",
      sortOrder: "asc",
    };
    this.onRowClick = this.onRowClick.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {
          let link = `/companies/${this.props.match.params.companyId}/sensors/${this.props.match.params.id}/atomic-sensors/${rowInfo.original._id}`;

          const url = window.location.href;
          const params = window.location.search;

          // Check if url includes /samples
          if (url.endsWith("/samples")) {
            link += "/samples";
          }

          // Check if url includes /data
          if (url.includes("/data")) {
            link += "/data" + params;
          }

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original._id === this.props.match.params.atomicSensorId ? "rgba(0,0,0,0.05)" : null)
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedAtomicSensors[row.original._id] !== undefined && this.props.selectedAtomicSensors[row.original._id]) {
      this.props.deselectAtomicSensor(row.original._id);
    }
    else {
      this.props.selectAtomicSensor(row.original);
    }
  }

  onToggleAll(value) {
    if (value) {
      let atomicSensors = this.props.sensor.atomicSensors;

      this.props.selectAllAtomicSensors(atomicSensors);
    }
    else {
      this.props.deselectAllAtomicSensors();
    }
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder });
  }

  render() {
    // console.log("AtomicSensors render", this.state, this.props);
    let atomicSensors = this.props.sensor.atomicSensors;

    if (this.state.sortBy && this.state.sortOrder) {
      atomicSensors = atomicSensors.sort((a, b) => {
        if (this.state.sortBy === "type") {

          // Sort by display name if it exist
          if (a.displayName) {
            if (this.state.sortOrder === "asc") {
              return a.displayName.localeCompare(b.displayName);
            }
            else {
              return b.displayName.localeCompare(a.displayName);
            }
          }

          // Sort by type
          if (this.state.sortOrder === "asc") {
            return a.type.localeCompare(b.type);
          }
          else {
            return b.type.localeCompare(a.type);
          }
        }
        else if (this.state.sortBy === "sampledAt") {
          // Sort by sampledAt date
          let aDate = moment(a.sampledAt);
          let bDate = moment(b.sampledAt);
          return this.state.sortOrder === "asc" ? aDate.diff(bDate) : bDate.diff(aDate);
        }
        return false;
      });
    }

    const anotherTypeSelected = (this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("atomicSensor"));

    const tableElement = (
      <Row className={style.topRow}>
        <Col sm={12}>
          <Table
            data={atomicSensors}
            sortBy={this.state.sortBy}
            sortOrder={this.state.sortOrder}
            onSortedChange={this.onSortedChange}
            noDataText={this.props.isLoading ? "" : "No atomic sensors found"}
            columns={[
              {
                header: ({data, column}) => {

                  let checked = true;
                  atomicSensors.forEach(atomicSensor => {
                    if (!this.props.selectedAtomicSensors[atomicSensor._id]) {
                      checked = false;
                    }
                  });
      
                  return (
                    <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-${column}-include-header`}>
                      <input
                        id={`editCheckbox-${column}-include-header`}
                        type="checkbox"
                        className="checkbox"
                        checked={checked}
                        onChange={() => this.onToggleAll(!checked)}
                        disabled={anotherTypeSelected}
                      />
                      <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
                    </label>
                  );
                }, 
                accessorKey: "_id",
                sortable: false,
                name: "isSelected",
                cell: ({ row }) => (
                  <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                    <input
                      id={`editCheckbox-${row.original._id}`}
                      type="checkbox"
                      className="checkbox"
                      checked={this.props.selectedAtomicSensors[row.original._id] !== undefined && this.props.selectedAtomicSensors[row.original._id]}
                      onChange={() => this.onToggle(row)}
                      disabled={anotherTypeSelected}
                    />
                    <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
                  </label>
                ),
                width: 60
              },
              {
                header: "Name",
                accessorKey: "type",
                cell: ({ row }) => {
                  console.log("row", row);
                  const identifier = row.original.identifier;
                  return (
                    <span title={row.original.displayName ?? row.original.type}>
                      {row.original.displayName ?? row.original.type}
                      { identifier && <span style={{ marginLeft: "5px", color: "#888" }}>({identifier})</span> }
                    </span>
                  );
                }
              },
              {
                header: "Locations",
                accessorKey: "locations",
                cell: ({ row }) => (<span title={row.original.locations.length}>{row.original.locations.length}</span>),
                width: 120
              },
              {
                header: "Last sample",
                accessorKey: "lastSample",
                cell: ({ row }) => (
                  <div>
                    {row.original.lastSample ? moment(row.original.lastSample).format("DD/MM/YY HH:mm") : "-"}
                  </div>
                ),
                width: 120
              },
              {
                id: "arrow",
                header: "",
                accessorKey: "_id",
                sortable: false,
                className: "pull-right",
                width: 60,
                cell: ({ row }) => (<div className="arrow" />)
              }
            ]}
            getTdProps={this.onRowClick}
            className="setMaxHeigth -row-clickable -highlight"
            loading={this.props.isLoading}
          />
        </Col>
      </Row>
    );

    return (
      <>
        <Hidden xs sm md>
          <div className={style.listContainer}>
            <div className={style.row}>
              <div className={style.colList}>
                <div className={style.scroll}>
                  { tableElement }
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
              <div className={style.colMap}>
                <Route path="/companies/:companyId/sensors/:sensorId/atomic-sensors/:id?" children={(props) => (<AtomicSensor {...props} sensorId={this.props.match.params.id} onDeleteDataClicked={this.props.onDeleteDataClicked} />)} />
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.listContainerMobile}>
            <div className={style.row}>
              <div className={style.col}>
                <div className={style.slimScroll}>
                  { tableElement }
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    sensor: state.sensor,
    selectedAtomicSensors: state.selected.atomicSensors,
    selectedLocations: state.selected.locations,
    selectedTypes: state.selected.allTypes,
    isLoading: state.loading.sensor,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectAtomicSensor: selectedActions.selectAtomicSensor,
    deselectAtomicSensor: selectedActions.deselectAtomicSensor,
    selectAllAtomicSensors: selectedActions.selectAllAtomicSensors,
    deselectAllAtomicSensors: selectedActions.deselectAllAtomicSensors,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AtomicSensors);
