import React from "react";
import Table from "../../../../components/Table";
import PropTypes from 'prop-types';
import moment from "moment";
import styles from "./styles.module.scss";

export class SensorTable extends React.Component { 
  static propTypes = {
    filters: PropTypes.shape({
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
      noSamplesSinceDate: PropTypes.string,
      sensorTableSearchValue: PropTypes.string,
      offset: PropTypes.number,
      batteryLevel: PropTypes.number,
      signalStrength: PropTypes.number
    }).isRequired,
    sensorResponse: PropTypes.shape({
      count: PropTypes.number,
      countByLocation: PropTypes.shape(),
      limit: PropTypes.number,
      offset: PropTypes.number,
      results: PropTypes.arrayOf(PropTypes.shape({
        createdAt: PropTypes.string,
        deviceId: PropTypes.string,
        frequency: PropTypes.string,
        id: PropTypes.string,
        locations: PropTypes.arrayOf(PropTypes.shape({
          _id: PropTypes.string
        })),
        model: PropTypes.string,
        name: PropTypes.string,
        vendor: PropTypes.string
      }))
    }).isRequired,
    handleUpdatesToFilter: PropTypes.func.isRequired,
    awaitingSensorResponseFromServer: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingText: "Fetching sensors... this might take a few seconds. Hold on tight 🚀",
      noDataText: "No sensors found.",
      highlightedId: undefined,
    }

    this._onTableColumnSortChange = this._onTableColumnSortChange.bind(this);
    this._handleTablePageChange = this._handleTablePageChange.bind(this);
    this._onRowClick = this._onRowClick.bind(this);
  }

  _renderColumns() {
    return [
      {
        header: "Created at",
        accessorKey: "createdAt",
        defaultSortDesc: true,
        sortable: true,
        width: 120,
        cell: ({ row }) => 
        (<span title={moment(row.original.createdAt).format('Do MMMM YYYY, HH:mm')}>
          {moment(row.original.createdAt).format('DD/MM/YY, HH:mm')}
        </span>)
      },
      {
        header: "Device ID",
        accessorKey: "deviceId",
        sortable: false,
        cell: ({ row }) => 
        (<span title={row.original.deviceId}>
          {`...${row.original.deviceId.substr(row.original.deviceId.length - 6)}`}
        </span>)
      },
      {
        header: "Gateway ID",
        accessorKey: "gatewayDeviceId",
        sortable: true,
        cell: ({ row }) => 
        (<span title={row.original.gatewayDeviceId}>
          {row.original.gatewayDeviceId ? `...${row.original.gatewayDeviceId.substr(row.original.gatewayDeviceId.length - 6)}` : ""}
        </span>)
      },
      {
        header: "Model",
        accessorKey: "model",
        sortable: true,
        cell: ({ row }) => 
        (<span title={row.original.model}>
          {row.original.model}
        </span>)
      },
      {
        header: "Vendor",
        accessorKey: "vendor",
        sortable: true
      },
      {
        header: "Battery",
        accessorKey: "batteryLevel",
        sortable: true,
        width: 80,
        cell: ({ row }) => (
          <div 
            className={styles.twoColumnHeightCell}
            title={moment(row.original.batteryLevelDatetime).format('Do MMMM YYYY, HH:mm')}
          >
            <span>{typeof row.original.batteryLevel === 'number' ? `${row.original.batteryLevel}%` : "" }</span>
            <span className={styles.smallText}>
              {row.original.batteryLevelDatetime ? moment(row.original.batteryLevelDatetime).format('DD/MM/YY') : ""}
            </span>
          </div>
        )
      },
      {
        header: "Signal (dBm)",
        accessorKey: "signalStrength",
        sortable: true,
        width: 120,
        cell: ({ row }) => (
          <div 
            className={styles.twoColumnHeightCell}
            title={moment(row.original.signalStrengthDatetime).format('Do MMMM YYYY, HH:mm')}
          >
            <span>{typeof row.original.signalStrength === 'number' ? `${row.original.signalStrength}` : "" }</span>
            <span className={styles.smallText}>
              {row.original.signalStrengthDatetime ? moment(row.original.signalStrengthDatetime).format('DD/MM/YY') : ""}
            </span>
          </div>
        )
      },
      {
        header: "Frequency",
        accessorKey: "frequency",
        sortable: true,
        width: 120,
      },
      {
        header: "Last sample",
        accessorKey: "previousMessageAt",
        sortable: true,
        width: 120,
        cell: ({ row }) => (
          <span title={ row.original.previousMessageAt ? moment(row.original.previousMessageAt).format('Do MMMM YYYY, HH:mm') : "" }>
            {row.original.previousMessageAt ? moment(row.original.previousMessageAt).format('DD/MM/YY, HH:mm') : ""}
          </span>
        )
      },
      {
        id: "arrow",
        header: "",
        accessorKey: "properties",
        sortable: false,
        className: "pull-right",
        width: 40,
        cell: () => <div className="arrow" />
      }
    ]
  }

  _onTableColumnSortChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";

    const { handleUpdatesToFilter, filters } = this.props;
    handleUpdatesToFilter({
      ...filters,
      sortOrder,
      sortBy,
      offset: 0,
    });
  }

  _handleTablePageChange(offset) {
    const { handleUpdatesToFilter, filters } = this.props;
    handleUpdatesToFilter({
      ...filters,
      offset
    });
  }

  _onRowClick(column, rowInfo) {
    const onHover = (id) => {
      if (this.state.highlightedId !== id) {
        this.setState({ highlightedId: id });
      }
    }

    return {
      onClick: e => {
        if (rowInfo !== undefined) {
          const link = `/companies/${this.props.match.params.companyId}/sensors/${rowInfo.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        if (rowInfo && rowInfo.original) {
          onHover(rowInfo.original.id);
        }
      },
      onMouseLeave: e => onHover(null),
      style: {
        cursor: "pointer",
      }
    }
  }

  render() {
    const {
      loadingText,
      noDataText,
    } = this.state;
    const {
      awaitingSensorResponseFromServer,
      sensorResponse,
      filters: {
        sortBy,
        sortOrder,
        offset,
      }
    } = this.props;
    const tableColumns = this._renderColumns();

    return (
      <Table
        data={sensorResponse.results}
        sortBy={sortBy}
        sortOrder={sortOrder}
        offset={offset}
        limit={sensorResponse.limit}
        count={sensorResponse.count}
        onOffsetChange={this._handleTablePageChange}
        onSortedChange={this._onTableColumnSortChange}
        noDataText={awaitingSensorResponseFromServer ? "" : noDataText}
        columns={tableColumns}
        getTdProps={this._onRowClick} 
        className="-row-clickable setMaxHeigth -highlight"
        loading={awaitingSensorResponseFromServer}
        loadingText={loadingText}
      />
    )
  }
}