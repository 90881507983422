import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-grid-system";
import { isEmpty } from "lodash";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import Table from "../../components/Table";
import * as userGroupsActions from "../../actions/userGroups"
import * as selectedActions from "../../actions/selected";

class GroupUsers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      // sortBy: "name",
      // sortOrder: "asc",
      // offset: 0,
      // limit: 20,
    };
    this.onRowClick = this.onRowClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.fetchData = this.fetchData.bind(this);

    // console.log("GroupUsers.constructor()", this.props)
  }

  componentDidMount() {
    if (!isEmpty(this.props.userGroup.name)) {
      document.title = `BLDNG.ai - ${this.props.userGroup.name} - users`;
    }

    // this.fetchData();
  }


  componentDidUpdate(prevProps) {
    if (this.props.location.pathname.includes("/org/groups")) {
      // Only load data if id change
      if (!isEmpty(this.props.groupId) && prevProps.groupId !== this.props.groupId) {
        this.props.getUserGroupUsers(this.props.groupId);
      }
    }
    else if (this.props.userGroup.id && prevProps.userGroup.id !== this.props.userGroup.id) {
      this.props.getUserGroupUsers(this.props.userGroup.id);
    }
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        // console.log(rowInfo);
        if (rowInfo && column.name !== "isSelected") {
          let link = `/companies/${this.props.match.params.companyId}/org/users/${rowInfo.original._id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedUsers[row.original._id] !== undefined && this.props.selectedUsers[row.original._id]) {
      this.props.deselectUser(row.original._id);
    }
    else if (this.props.selectedUsers[row.original.inviteId] !== undefined && this.props.selectedUsers[row.original.inviteId]) {
      this.props.deselectUser(row.original.inviteId);
    }
    else {
      this.props.selectUser(row.original);
    }
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      // sortBy: this.state.sortBy,
      // sortOrder: this.state.sortOrder,
      // offset: this.state.offset,
      // limit: this.state.limit
    };

    if (this.props.location.pathname.includes("/org/groups")) {
      this.props.getUserGroupUsers(this.props.groupId, queryParams);
    }
    else if (this.props.userGroup.id) {
      this.props.getUserGroupUsers(this.props.userGroup.id, queryParams);
    }
  }

  render() {
    const { isLoading } = this.props;

    if (isEmpty(this.props.groupId)) {
      return null;
    }

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("user");

    const tableElement = (
      <Table
        data={this.props.userGroup.users}
        noDataText={isLoading ? "" : "No users found"}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={(this.props.selectedUsers[row.original._id] !== undefined && this.props.selectedUsers[row.original._id])}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => {
              var displayName = "Unknown";
              if (!isEmpty(row.original.name)) {
                displayName = row.original.name;
              }
              else if (!isEmpty(row.original.email)) {
                displayName = row.original.email;
              }
              else if (!isEmpty(row.original.phone_number)) {
                displayName = row.original.phone_number;
              }
              return <span>{displayName}</span>
            }
          },
          // {
          //   header: "Name",
          //   accessorKey: "name",
          //   cell: ({ row }) => (<span>{row.original.name}</span>)
          // },
          // {
          //   header: "Email",
          //   accessorKey: "email",
          //   cell: ({ row }) => (<span>{row.original.email}</span>)
          // },
          // {
          //   header: "Phone",
          //   accessorKey: "phone_number",
          //   width: 100,
          //   cell: ({ row }) => (<span>{row.original.phone_number}</span>)
          // },
          {
            id: "arrow",
            header: "",
            accessorKey: '_id',
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={isLoading}
      />
    );

    return (
      <>
        <TopRowOptions
          searchbox={(
            <SearchBox
              value={this.state.searchText}
              onSearchChanged={(value) => { this.setState({ searchText: value }) }}
              onSearchClick={this.onSearchClick}
              onClear={() => { this.setState({ searchText: "" }, this.onSearchClick) }}
              inDetailsView
            />
          )}
        />
        <Row>
          <Col xs={12} md={12}>
            { tableElement }
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userGroup: state.userGroup,
    selectedCompany: state.auth.selectedCompany,
    selectedUsers: state.selected.users,
    selectedTypes: state.selected.allTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectUserGroup: selectedActions.selectUserGroup,
    getUserGroupUsers: userGroupsActions.getUserGroupUsers,
    selectUser: selectedActions.selectUser,
    deselectUser: selectedActions.deselectUser
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupUsers);
