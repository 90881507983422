import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import JSON5 from "json5";
import StaticTable from "../../components/StaticTable";
import SmallButton from "../../components/SmallButton";
import * as screenActions from "../../actions/screens";
import styled from "styled-components";

class ScreenConfigLayoutDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mode: "initial",
      selectedObject: null,
    };

    this.onAddContainerClicked = this.onAddContainerClicked.bind(this);
    this.onAddComponentClicked = this.onAddComponentClicked.bind(this);
  }

  // Put the selected object in the state, from props, if props is valid
  static getDerivedStateFromProps(newProps, prevState) {
    if (newProps.selectionPath && newProps.form && newProps.form.config) {
      
      let newState = { ...prevState };

      // Get the selected object from the selectionPath
      let view = null;
      let existingView = get(newProps.form, "config.view", "");
      if (existingView === null || existingView === "") {
        newState.mode = "initial";
        newState.selectedObject = null;
      }
      else {
        try {
          newState.mode = "documentation";
          view = JSON5.parse(newProps.form.config.view);
          const selectedObject = getLastObjectFromPath(newProps.selectionPath, view);
          // console.log("ScreenConfigLayoutDetails.getDerivedStateFromProps.selectedObject", selectedObject);

          if (selectedObject) {
            newState.selectedObject = selectedObject;
          }
          else {
            newState.selectedObject = null;
            newState.mode = "add-view";
          }
        }
        catch (error) {}
      }

      if (newProps.showAddView) {
        newState.mode = "add-view";
      }

      return newState;
    }

    return null;
  }

  onAddContainerClicked(isRoot = false) {
    const container = {
      class: "container",
      css: {},
      children: []
    };

    if (isRoot) {
      container.css = {
        position: "relative",
        width: "100%",
        height: "100%",
      }
    }

    this.props.onAddClicked(this.props.selectionPath, container);
  }

  onAddComponentClicked(type) {
    const component = {
      class: "component",
      type: type,
    };

    this.props.onAddClicked(this.props.selectionPath, component);
  }

  render() {
    // console.log("ScreenConfigLayoutDetails.render.state", this.state);
    // console.log("ScreenConfigLayoutDetails.render.selectionPath", this.props.selectionPath);

    if (!this.props.auth.hasSupportRole) {
      return null;
    }

    if (this.state.mode === "initial") {
      return (
        <>
          <Header $paddingTop="25px">Add a container</Header>
          <Paragraph>A standard root container will be added to the layout.</Paragraph>
          <SmallButton text="Container" onClick={() => this.onAddContainerClicked(true)} disabled={this.props.isLoading} onlyRightMargin />
          <div style={{ paddingTop: "40px" }} />
        </>
      );
    }
    else if (this.state.mode === "add-view") {
      return (
        <>
          <Header $paddingTop="25px">Add a container</Header>
          <Paragraph>A container will be added either as a root element or as a child in the selected container.</Paragraph>
          <SmallButton text="Container" onClick={() => this.onAddContainerClicked(false)} disabled={this.props.isLoading} onlyRightMargin />
          <Header $paddingTop="25px">Add a component</Header>
          <Paragraph>Click on the component you what to add to the selected container in the layout.</Paragraph>
          <SmallButton text="Text" onClick={() => this.onAddComponentClicked("text")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Text area" onClick={() => this.onAddComponentClicked("text-area")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Image" onClick={() => this.onAddComponentClicked("image")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Clock" onClick={() => this.onAddComponentClicked("clock")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Map" onClick={() => this.onAddComponentClicked("map")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="SVG map" onClick={() => this.onAddComponentClicked("svg-map")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Header" onClick={() => this.onAddComponentClicked("header")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Floor menu" onClick={() => this.onAddComponentClicked("floor-menu")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Compass" onClick={() => this.onAddComponentClicked("compass")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Legend" onClick={() => this.onAddComponentClicked("legend")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Occupied percentage bar" onClick={() => this.onAddComponentClicked("bar")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Card" onClick={() => this.onAddComponentClicked("card")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Available meeting rooms" onClick={() => this.onAddComponentClicked("meeting-rooms")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="All meeting rooms" onClick={() => this.onAddComponentClicked("all-meeting-rooms")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Meeting room count" onClick={() => this.onAddComponentClicked("meeting-room-count")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Graph" onClick={() => this.onAddComponentClicked("graph")} disabled={this.props.isLoading} onlyRightMargin />
          <div style={{ paddingTop: "40px" }} />
        </>
      );
    }

    const selectedClass = get(this.state.selectedObject, "class");
    const selectedType = get(this.state.selectedObject, "type");

    const componentDefinitionColumnSizes = {
      property: 40,
      value: 60
    };

    const configurationColumnSizes = {
      property: 20,
      type: 15,
      default: 15,
      description: 50,
    };

    let componentDefinition = [];
    let configurationDefinition = [];
    let contentElement = null;
    if (selectedClass === "component" && selectedType) {
      
      componentDefinition = [
        { property: "class", value: `"component"` },
        { property: "type", value: `"${selectedType}"` },
      ];

      const thresholdGroupDefinition = [
        { property: "lower", type: "Threshold", default: "-", description: "The lower threshold. Will trigger if value is between 0 and this threshold." },
        { property: "middle", type: "Threshold?", default: "-", description: "The middle threshold. Will trigger if value is between lower and this threshold." },
        { property: "upper", type: "Threshold", default: "-", description: "The upper threshold. Will trigger if value is above the middle threshold if it is define else when it is above the lower threshold." },
      ];

      const thresholdDefinition = [
        { property: "value", type: "number?", default: "33 / 66 / -", description: "Overriding the default thresholds value. Default value is different for lower, middle and upper thresholds." },
        { property: "text", type: "string?", default: "-", description: "A text to be shown when the threshold is met (instead of the value)." },
        { property: "icon", type: "string?", default: "-", description: "An icon to be shown when the threshold is met (instead of the value or text)." },
        { property: "color", type: "hex-color?", default: "green / yellow / red", description: "The color of the icon or text. Default value is different for lower, middle and upper thresholds." },
      ];

      switch (selectedType) {
        case "map":

          contentElement = (
            <>
              <Header>Map</Header>
              <Paragraph>This component is a reference to the current configured map in &quot;Details&quot;. We only support one map.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
            </>
          );

          break;
        case "svg-map":

          configurationDefinition = [
            { property: "url", type: "string", default: "-", description: "A URL to a public hosted SVG file." },
          ];

          contentElement = (
            <>
              <Header>SVG map</Header>
              <Paragraph>This component is used to render SVG floor maps. To allow the SVG to scale and fit the view, it should not have a predefined height and width. If you want room status in the map, create rectangles with locationId and locationType as attributes.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "header":

          configurationDefinition = [
            { property: "title", type: "string?", default: "-", description: "A title that will be in the middle of the header." },
            { property: "showLocationName", type: "boolean?", default: "false", description: "If true, the location name will be used instead of the title." },
            { property: "showThemeLogo", type: "boolean?", default: "false", description: "If true, the theme logo will be shown on the left side in the header."},
          ];

          contentElement = (
            <>
              <Header>Header</Header>
              <Paragraph>This component is used to render the standard banner header.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "text":

          configurationDefinition = [
            { property: "text", type: "string | Expression", default: "-", description: "A string or an Expressions to add texts based on sensor data." },
          ];

          contentElement = (
            <>
              <Header>Text</Header>
              <Paragraph>This component is used to render a text block.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "text-area":

          configurationDefinition = [
            { property: "title", type: "string?", default: "-", description: "A title that will appear above the text area." },
            { property: "text", type: "string | Expression", default: "-", description: "A string or an Expressions to add texts based on sensor data." },
            { property: "width", type: "string?", default: "-", description: "The CSS width of the component." },
            { property: "maxWidth", type: "string?", default: "-", description: "The CSS max-width of the component." },
            { property: "fontSize", type: "string?", default: "-", description: "The CSS font-size of the component." },
          ];

          contentElement = (
            <>
              <Header>Text area</Header>
              <Paragraph>This component is used to render a text area that is placed in the standard card layout.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph> 
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "image":

          configurationDefinition = [
            { property: "url", type: "string | Expression", default: "-", description: "A URL to a public hosted image or an Expressions to add images based on sensor data." },
          ];

          contentElement = (
            <>
              <Header>Image</Header>
              <Paragraph>This component is used to render an image.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "clock":

          configurationDefinition = [
            { property: "format", type: "string?", default: "HH:mm", description: "A moment.js format string." },
          ];

          contentElement = (
            <>
              <Header>Clock</Header>
              <Paragraph>This component is used to render a clock.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph> 
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "floor-menu":

          contentElement = (
            <>
              <Header>Floor menu</Header>
              <Paragraph>This component is used to render a floor menu.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
            </>
          );

          break;
        case "compass":

          contentElement = (
            <>
              <Header>Compass</Header>
              <Paragraph>This component is used to render a compass.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
            </>
          );

          break;
        case "legend":

          contentElement = (
            <>
              <Header>Legend</Header>
              <Paragraph>This component is used to render the standard legend based on theme colors and map config.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
            </>
          );

          break;
        case "bar":

          configurationDefinition = [
            { property: "locationId", type: "string", default: "-", description: "The locationId that the data will be queried from." },
            { property: "sensorType", type: "string", default: "-", description: "The type of data to query." },
            { property: "showPercentageBox", type: "boolean?", default: "false", description: "If true, a box with the percentage value in text form will appear to the left of the bar." },
            { property: "thresholds", type: "ThresholdGroup?", default: "-", description: "A group of thresholds for the bar." },
          ];

          contentElement = (
            <>
              <Header>Occupied percentage bar</Header>
              <Paragraph>This component is used to render horizontal bar chart. It will show how many people there are in a location compared to the capacity in percent.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="40px">Threshold group</Header>
              <StaticTable data={thresholdGroupDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="40px">Threshold</Header>
              <StaticTable data={thresholdDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "card":

          configurationDefinition = [
            { property: "title", type: "string?", default: "-", description: "A title that will appear above the content in a banner with the primary theme color." },
            { property: "locationId", type: "string", default: "-", description: "The locationId that the data will be queried from." },
            { property: "sensorType", type: "string", default: "-", description: "The type of data to query." },
            { property: "showPercentageBox", type: "boolean?", default: "false", description: "If true, a box with the percentage value in text form will appear to the left of the bar." },
            { property: "thresholds", type: "ThresholdGroup?", default: "-", description: "A group of thresholds for the bar." },
          ];

          contentElement = (
            <>
              <Header>Card</Header>
              <Paragraph>This component is used to render a card with information from sensor data. It will show the last received value.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="40px">Threshold group</Header>
              <StaticTable data={thresholdGroupDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="40px">Threshold</Header>
              <StaticTable data={thresholdDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "meeting-rooms":

          configurationDefinition = [
            { property: "showTouchControls", type: "boolean?", default: "false", description: "If true, the component includes visual queue for touch." },
            { property: "showIAQ", type: "boolean?", default: "false", description: "If true, the Indoor Air Quality of the meeting rooms will be shown." },
          ];

          contentElement = (
            <>
              <Header>Available meeting rooms</Header>
              <Paragraph>This component is used to render only available meeting rooms based on both sensor data and M365 calendars.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "all-meeting-rooms":

          configurationDefinition = [
            { property: "showTouchControls", type: "boolean?", default: "false", description: "If true, the component includes visual queue for touch." },
            { property: "showIAQ", type: "boolean?", default: "false", description: "If true, the Indoor Air Quality of the meeting rooms will be shown." },
          ];

          contentElement = (
            <>
              <Header>All meeting rooms</Header>
              <Paragraph>This component is used to render all meeting rooms with information from both sensor data and M365 calendars.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "meeting-room-count":

          configurationDefinition = [
            { property: "title", type: "string?", default: "-", description: "A title that will appear above the content in a banner with the primary theme color." },
            { property: "text", type: "string?", default: "-", description: "A text below the available meeting room count." },
            { property: "locationIds", type: "array", default: "-", description: "An array of locationIds that the data will be queried from." },
          ];

          contentElement = (
            <>
              <Header>Meeting room count</Header>
              <Paragraph>This component is used to render a count of available meeting rooms in a card layout. The meeting rooms need to be defined in the locationIds.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "graph":

          configurationDefinition = [
            { property: "locationId", type: "string", default: "-", description: "The locationId that the data will be queried from." },
            { property: "sensorType", type: "string", default: "-", description: "The type of data to query." },
          ];

          contentElement = (
            <>
              <Header>Graph</Header>
              <Paragraph>This component is used to render a graph of sensor data from a location. (WORK IN PROGRESS)</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        default:
          // contentElement = (
          //   <>
          //     <Header>{selectedType}</Header>
          //     <Paragraph>No component with this name is exist.</Paragraph>
          //   </>
          // );
          break;
      }
    }
    else if (selectedClass === "container") {

      componentDefinition = [
        { property: "class", value: `"container"` },
      ];

      configurationDefinition = [
        { property: "css", type: "object", default: "-", description: "The an inline CSS that will be added to the container. Supports both kebab-case and camelCase format for CSS-properties. The value for a CSS-property can be an Expression." },
        { property: "children", type: "array", default: "-", description: "A list of child components and containers." },
      ];

      contentElement = (
        <>
          {/* <Container>
            <TextContainer>
              <Header>Container</Header>
              <Paragraph>A container is used to create a layout hierarchy with inline CSS.</Paragraph>
            </TextContainer>
            <ButtonContainer>
              <SmallButton text="Add container" onClick={() => this.onAddContainerClicked("text-area")} disabled={this.props.isLoading} noLeftMargin/>
              <SmallButton text="Add component" onClick={this.onAddComponentClicked} disabled={this.props.isLoading} />
            </ButtonContainer>
          </Container> */}

          <Header>Container</Header>
          <Paragraph>A container is used to create a layout hierarchy with inline CSS.</Paragraph>
          <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
          <Paragraph $paddingTop="20px">Configuration definition for all properties in a container.</Paragraph>
          <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
        </>
      );
    }

    let expressionElement = null;
    const componentWithExpression = selectedClass === "component" && selectedType && (selectedType === "text" || selectedType === "text-area" || selectedType === "image");
    if (componentWithExpression || selectedClass === "container") {

      const propertyDefinitionColumnSizes = {
        property: 35,
        description: 65
      };

      const formatDefinitionColumnSizes = {
        property: 25,
        type: 25,
        description: 50,
      };

      const themePropertiesDefinition = [
        { property: "logoUrl", type: "string", default: "-", description: "The URL to the logo (defined in the theme)." },
        { property: "primaryColor", type: "hex-color", default: "#1C4D82", description: "The primary color (defined in the theme)." },
        { property: "vacantColor", type: "hex-color", default: "#77CF3A", description: "The vacant state color (defined in the theme)." },
        { property: "occupiedColor", type: "hex-color", default: "#D15656", description: "The occupied state color (defined in the theme)." },
        { property: "vacantBookedColor", type: "hex-color", default: "#FAD648", description: "The vacant booked state color (defined in the theme)." },
        { property: "statusOpacity", type: "number", default: "1.0", description: "The opacity of the state colors (defined in the theme)." },
      ];

      const getConfigDefinition = [
        { property: "propertyName", type: "string", description: "The name of the property to query." },
        { property: "fallback", type: "any?", description: "A value or Expression that will be used if the property is not found. If undefined, returns null." }
      ];

      const getDataDefinition = [
        { property: "locationId", type: "string", description: "The locationId that the data will be queried from." },
        { property: "propertyType", type: "string", description: "The type of data to query." },
        { property: "fallback", type: "any?", description: "A value or Expression that will be used if the property is not found. If undefined, returns null." }
      ];

      const propertyTypesDefinition = [
        { property: "temperature", description: "The temperature in the location." },
        { property: "humidity", description: "The humidity in the location." },
        { property: "carbonDioxideLevel", description: "The CO2 level in the location." },
        { property: "vocLevel", description: "The volatile organic compounds level in the location." },
        { property: "soundPressureLevel", description: "The noise level in the location." },
        { property: "peopleCount", description: "The number of people in the location." },
        { property: "roomStatus", description: "If the room is vacant or occupied." },
        { property: "indoorAirQualityIndex", description: "The IAQ index in the location." },
      ];

      const getAttributeDefinition = [
        { property: "locationId", type: "string", description: "The locationId that the attribute will be queried from." },
        { property: "attributeName", type: "string", description: "The name of the attribute to query." },
        { property: "fallback", type: "any?", description: "A value or Expression that will be used if the attribute is not found. If undefined, returns null." }
      ];

      const attributeNameDefinition = [
        { property: "name", description: "The location name." },
        { property: "description", description: "The location description." },
        { property: "type", description: "The location type." },
        { property: "capacity", description: "The people capacity in the location." },
        { property: "timezone", description: "The timezone of the location." },
        { property: "zone", description: "The zone the location is in, if any." },
        { property: "floor", description: "The floor the location is in, if any." },
        { property: "building", description: "The building the location is in, if any." },
        { property: "region", description: "The region the location is in, if any." }
      ];

      const comparisonOperatorDefinition = [
        { property: "operator", type: "string", description: "Either '==', '>', '>=', '<', '<=' or '!='." },
        { property: "leftValue", type: "number | Expression", description: "A number or Expression to compare." },
        { property: "rightValue", type: "number | Expression", description: "A number or Expression to compare." }
      ];

      const logicalOperatorDefinition = [
        { property: "operator", type: "string", description: "Either 'and', 'or', or 'not'." },
        { property: "value", type: "boolean | Expression", description: "A value or Expression to compare." },
      ];

      const caseDefinition = [
        { property: "condition", type: "boolean | Expression", description: "The condition to check." },
        { property: "returnValue", type: "any | Expression", description: "The value or Expression to return if the condition is true." },
        { property: "fallback", type: "any | Expression", description: "The value or Expression to return if no conditions are true." },
      ];

      expressionElement = (
        <>
          <Header $paddingTop="40px">Expression</Header>
          <Paragraph>The layout config has a lot of ways to customize how each container and component will look, but you can only add static css values which means that you have to add the same color code muliple places and never base the look of on any data. This is why we added expressions - to let you add logic to your css and make any layout you want.</Paragraph>
          
          <h3>Get config</h3>
          <Paragraph>A query to look up properties in the Screen Theme.</Paragraph>
          <CodeBlock>{`["get-config", <propertyName>, <fallback>]`}</CodeBlock>
          <StaticTable data={getConfigDefinition} columnSizes={formatDefinitionColumnSizes} />
          <Paragraph $paddingTop="20px">All properties in the config</Paragraph>
          <StaticTable data={themePropertiesDefinition} columnSizes={configurationColumnSizes} />

          <h3>Get data</h3>
          <Paragraph>A query that look up sensor data in a location.</Paragraph>
          <CodeBlock>{`["get-data", <locationId>, <propertyType>, <fallback>]`}</CodeBlock>
          <StaticTable data={getDataDefinition} columnSizes={formatDefinitionColumnSizes} />
          <Paragraph $paddingTop="20px">Some of the property types</Paragraph>
          <StaticTable data={propertyTypesDefinition} columnSizes={propertyDefinitionColumnSizes} />

          <h3>Get attribute</h3>
          <Paragraph>A query that look up an attribute for a location.</Paragraph>
          <CodeBlock>{`["get-attribute", <locationId>, <attributeName>, <fallback>]`}</CodeBlock>
          <StaticTable data={getAttributeDefinition} columnSizes={formatDefinitionColumnSizes} />
          <Paragraph $paddingTop="20px">Some of the available attributes</Paragraph>
          <StaticTable data={attributeNameDefinition} columnSizes={propertyDefinitionColumnSizes} />

          <h3>Case</h3>
          <Paragraph>The case function is our switch-logic operator. It either selects the first true condition or the fallback if no conditions are true. Use logical operators and comparison operators to create complex switch statements.</Paragraph>
          <CodeBlock>{`["case", <condition>, <returnValue>, <condition>, <returnValue>, ..., <fallback>]`}</CodeBlock>
          <StaticTable data={caseDefinition} columnSizes={formatDefinitionColumnSizes} />

          <h3>Logical operators</h3>
          <Paragraph>These functions evaluates all values with the specified logical operator.</Paragraph>
          <CodeBlock>{`["<operator>", <value>, ..., <value>]`}</CodeBlock>
          <StaticTable data={logicalOperatorDefinition} columnSizes={formatDefinitionColumnSizes} />

          <h3>Comparison operators</h3>
          <Paragraph>These functions compares the left value with the right value using the specified operator.</Paragraph>
          <CodeBlock>{`["<operator>", <leftValue>, <rightValue>]`}</CodeBlock>
          <StaticTable data={comparisonOperatorDefinition} columnSizes={formatDefinitionColumnSizes} />
        </>
      );
    }

    return (
      <>
        <div style={{ paddingTop: "25px" }} />
        { contentElement }
        { expressionElement }
        <div style={{ paddingTop: "40px" }} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenConfigLayoutDetails);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 44px;
  justify-content: ${props => props.$justify ?? "space-between"};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-right: 5px;
`;

const Header = styled.h2`
  font-size: 26px;
  line-height: 38.5px;
  min-height: 38.5px;
  margin: 0;
  font-family: Source Sans Pro;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #0c0f26;
  margin-top: ${props => props.$paddingTop ?? "0px"};
`;

const Paragraph = styled.p`
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #0c0f26;
  margin-top: ${props => props.$paddingTop ?? "0px"};
`;

const CodeBlock = styled.div`
  display: block;
  width: fit-content;
  max-width: 100%;
  overflow: auto;
  max-height: 400px;
  margin-bottom: 20px;

  background-color: white;
  border: 1px solid #dfe4e8;
  border-radius: 5px;
  padding: 10px 15px; // 8px; 

  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0c0f26;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 10px;
`;

const getLastObjectFromPath = (path, object) => {
  let lastProp = object;
  let lastObject = null;

  if (typeof lastProp === "object") {
    lastObject = lastProp;
  }

  for (let i = 0; i < path.length; i++) {
    const key = get(path, i, null);

    // In case of an invalid path/object
    if (key === null || key === "css") {
      break;
    }

    lastProp = get(lastProp, key);

    if (!lastProp) {
      return null;
    }

    if (typeof lastProp === "object") {
      lastObject = lastProp;

      if (lastProp.class && lastProp.class === "component") {
        return lastObject;
      }
    }
  }

  return lastObject;
}