import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-grid-system";
import { get, isEmpty } from "lodash";
import InputBox from "../../components/InputBox";
import TextBox from "../../components/TextBox";
import DetailsHeader from "../../components/DetailsHeader";
import SubNavigation from "../../components/SubNavigation";
import ColorPicker from "../../components/ColorPicker";
import CustomTagLocations from "./customTagLocations";
import { ControlledDropdownSelection } from "../../components/DropdownSelection";
import * as customTagActions from "../../actions/customTags";
import * as reportActions from "../../actions/reports"; 
import style from "./style.module.scss";

const tabBarLinks = [
  { label: `Details` },
  { label: `Locations` }
];

class CustomTagDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0
    };
    this.selectedTab = this.selectedTab.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onColorChange = this.onColorChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onWPACategoryChange = this.onWPACategoryChange.bind(this);

    if (isEmpty(this.props.tagId)) {
      this.props.clearCustomTag();
    }
    else if (this.props.customTag.id !== this.props.tagId && this.props.tagId !== "new") {
      this.props.getCustomTag(this.props.tagId);
    }

    this.props.getWpaCategories();
  }

  componentDidUpdate(prevProps) {
    // Only load data if id change
    if (isEmpty(this.props.tagId)) {
      this.props.clearCustomTag();
    }
    else if (prevProps.tagId !== this.props.tagId && this.props.tagId !== "new") {
      this.props.getCustomTag(this.props.tagId);
    }
  }

  selectedTab(index) {
    this.setState({ selectedIndex: index });
  }

  onNameChange(event) {
    this.props.updateCustomTagDraft({ name: event.target.value });
  }

  onColorChange(color) {
    this.props.updateCustomTagDraft({ colorTheme: color.hex });
  }

  onDescriptionChange(event) {
    this.props.updateCustomTagDraft({ description: event.target.value });
  }

  onWPACategoryChange(event) {
    // Find the wpaCategoryId from the event.target.value
    const wpaCategory = this.props.wpaCategories.find((wpaCategory) => (wpaCategory.name === event.target.value));
    if (wpaCategory) {
      this.props.updateCustomTagDraft({ wpaCategories: [wpaCategory] });
    }
    else {
      this.props.updateCustomTagDraft({ wpaCategories: [] });
    }
  }
  
  render() {
    if (isEmpty(this.props.tagId)) {
      return null;
    }
    else if (this.props.tagId !== "new" && isEmpty(this.props.customTag.id)) {
      return null;
    }

    const canUpdateCustomTag = this.props.auth.hasAdminRole;
    const title = this.props.customTag.name ?? "New";

    // Get the first wpaCategoryId
    const selectedWpaCategories = get(this.props.customTagDraft, "wpaCategories", get(this.props.customTag, "wpaCategories", []));
    const selectedWpaCategory = selectedWpaCategories.length > 0 ? selectedWpaCategories[0] : undefined;

    let detailsElement = null;
    switch (this.state.selectedIndex) {
      case 0:
        detailsElement = <div>
          <Row>
            <Col>
              <InputBox
                label="Name"
                value={get(this.props.customTagDraft, "name", get(this.props.customTag, "name", ""))}
                onChange={this.onNameChange}
                disabled={!canUpdateCustomTag}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ColorPicker
                label="Color" 
                value={get(this.props.customTagDraft, "colorTheme", get(this.props.customTag, "colorTheme", "#6c757d"))}
                onChange={this.onColorChange}
                disabled={!canUpdateCustomTag}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ControlledDropdownSelection
                label="WPA Category" 
                options={this.props.wpaCategories ? [{ value: "", name: "None" }, ...this.props.wpaCategories.map(wpaCategory => ({ value: wpaCategory._id, name: wpaCategory.name }))] : []}
                value={selectedWpaCategory ? selectedWpaCategory.name : ""}
                onChange={this.onWPACategoryChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextBox
                label="Description"
                value={get(this.props.customTagDraft, "description", get(this.props.customTag, "description", ""))}
                onChange={this.onDescriptionChange} 
                size="medium"
                disabled={!canUpdateCustomTag}
              />
            </Col>
          </Row>
          <div style={{ paddingTop: "40px" }} />
        </div>;
        break;
      case 1:
        detailsElement = <CustomTagLocations {...this.props} />
        break;
      default:
        break;
    }

    return (
      <div>
        <DetailsHeader title={title} />
        <SubNavigation links={tabBarLinks} selectedIndex={this.state.selectedIndex} onClick={this.selectedTab} slim />
        { detailsElement }
        <div style={{ paddingTop: "40px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customTag: state.customTag.data,
    customTagDraft: state.customTag.draft,
    isLoading: state.customTag.isLoading,
    wpaCategories: state.report.wpaCategories,
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getCustomTag: customTagActions.getCustomTag,
    updateCustomTagDraft: customTagActions.updateCustomTagDraft,
    clearCustomTag: customTagActions.clearCustomTag,
    getWpaCategories: reportActions.getWpaCategories
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTagDetails);