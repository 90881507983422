import axios from "axios";
import get from "lodash/get";
import Cookies from "universal-cookie";
import FileSaver from "file-saver";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as authActions from "./auth";
import * as selectionActions from "./selected";
import { API_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";
import { getSensorTypes } from "./sensors";

const cookies = new Cookies();

export const getRootLocation = () => (dispatch) => {
  dispatch(actions.requestData(types.GET_ROOT_LOCATION));
  dispatch(actions.receiveData(null, types.GET_ROOT_LOCATION));
};

export const getRootLocations = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_LOCATIONS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/locations?hasParent=false`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_LOCATIONS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_LOCATIONS));
      }
    });
};

export const getLocation = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_LOCATION, { locationId: id }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/locations/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_LOCATION));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_LOCATION));
      }
    });
};

export const getLocationChildren = (id, queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_LOCATIONS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/locations/${id}/children`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_LOCATIONS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_LOCATIONS));
      }
    });
};

export const getLocations = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_LOCATIONS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/locations`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_LOCATIONS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_LOCATIONS));
      }
    });
};

export const searchLocations = (filter = { or: [{ and: [] }] }, queryParams) => async (dispatch) => { //{ property: 'name', value: '', operator: 'regex'}
  dispatch(actions.requestData(types.SEARCH_LOCATIONS, filter));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // console.log("filter", filter);

  // Add extra ands around each separate location filter
  let modifiedFilter = { or: [] };
  modifiedFilter.or = filter.or.map(outerFilter => {
    const and = outerFilter.and.map(innerFilter => {
      if (innerFilter.or) {
        const or = innerFilter.or.map(innerOr => {
          if (innerOr.and) {
            return innerOr;
          }
          else {
            return { and: [innerOr] };
          }
        });
        return { or };
      }
      else {
        return innerFilter;
      }
    });
    return { and };
  });

  // console.log("modifiedFilter", modifiedFilter);

  axios.post(`${apiUrl}companies/${companyId}/location-query-search`, modifiedFilter, await actions.getAxiosConfig(queryParams)) // locations
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.SEARCH_LOCATIONS, filter));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.SEARCH_LOCATIONS, filter));
      }
    });
};

export const changeLocationsQuery = (queryParams) => (dispatch) => {
  dispatch({
    type: types.CHANGE_LOCATIONS_QUERY,
    payload: queryParams
  });
};

export const moveLocations = (locationId, movingLocationIds) => async (dispatch) => {

  dispatch(actions.requestData(types.MOVE_LOCATIONS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  const body = {
    parents: [locationId]
  };

  // Looping through all locations
  axios.all(movingLocationIds.map((movingLocationId) => (
      axios.put(`${apiUrl}companies/${companyId}/locations/${movingLocationId}`, body, config)
    )))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.MOVE_LOCATIONS));
      dispatch(selectionActions.clearSelection());
      dispatch(getLocationChildren(locationId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.MOVE_SENSORS));
      }
    });
}

export const deleteLocations = (locationIds, currentLocationId, includeChildren) => async (dispatch) => {

  dispatch(actions.requestData(types.DELETE_LOCATIONS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const body = { 
    locationIds, 
    includeChildren
  };

  axios.post(`${apiUrl}companies/${companyId}/batch-delete-locations`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_LOCATIONS));
      dispatch(selectionActions.clearSelection());

      if (currentLocationId === "root") {
        dispatch(getRootLocations());
      } else {
        dispatch(getLocation(currentLocationId));
      }
    })
    .catch((error) => {
      console.log("deleteLocations error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_LOCATIONS));
      }
    });
}

export const addLocation = (body, parentId, redirect) => async (dispatch) => {

  dispatch(actions.requestData(types.ADD_LOCATION));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  axios.post(`${apiUrl}companies/${companyId}/locations`, body, config)
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.ADD_LOCATION));

      if (parentId === "root") {
        dispatch(getRootLocations());
      } else {
        dispatch(getLocationChildren(parentId));
      }

      dispatch(selectionActions.clearSelection());
      
      if (redirect) {
        redirect(`/companies/${companyId}/locations/${parentId}/locations`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.ADD_LOCATION));
      }
    });
};

export const bulkCreateLocations = (body, parentId, redirect) => async (dispatch) => {

  dispatch(actions.requestData(types.ADD_LOCATION));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  axios.post(`${apiUrl}companies/${companyId}/batch-create-locations`, body, config)
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.ADD_LOCATION));

      if (parentId === "root") {
        dispatch(getRootLocations());
      } else {
        dispatch(getLocationChildren(parentId));
      }

      dispatch(selectionActions.clearSelection());
      
      if (redirect) {
        redirect(`/companies/${companyId}/locations/${parentId}/locations`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.ADD_LOCATION));
      }
    });
};


export const editLocation = (location, body, redirect) => async (dispatch) => {

  dispatch(actions.requestData(types.EDIT_LOCATION));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // console.log("location", location);
  // console.log("body", body);

  const features = get(body, "map.feature", null);

  actions.createFeatures(location.id, features, apiUrl)
    .then((createResponse) => {
      // console.log("Save map response", createResponse);
      axios.put(`${apiUrl}companies/${companyId}/locations/${location.id}`, body, config)
        .then((response) => {
          dispatch(actions.receiveData(response.data, types.EDIT_LOCATION));
          dispatch(selectionActions.clearSelection());
          dispatch(getLocation(location.id));
          if (redirect) {
            redirect(`/companies/${companyId}/locations/${location.id}/locations`);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            actions.sessionTokenExpired(cookiePrefix, accessToken);
          } else {
            dispatch(actions.receiveError(error, types.EDIT_LOCATION));
          }
        })
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.EDIT_LOCATION));
      }
    });
};

export const getFloorMap = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_FLOOR_MAP, { id }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/locations/${id}/map`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_FLOOR_MAP, { id }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_FLOOR_MAP, { id }));
      }
    });
};

export const syncFloorMap = (id, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.SYNC_FLOOR_MAP));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/mazemap/locations/${id}`, {}, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.SYNC_FLOOR_MAP));
      if (redirect) {
        dispatch(getFloorMap(id));
        redirect(`/companies/${companyId}/locations/${id}/locations`);
      }
    })
    .catch((error) => {
      console.log("syncFloorMap error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.SYNC_FLOOR_MAP));
      }
    });
};

export const saveGeoJsonFeature = (id, body) => async (dispatch) => {

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}/locations/${id}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.EDIT_LOCATION));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.EDIT_LOCATION));
      }
    });
};

export const mapFeatureToLocation = (featureId, locationId, viewedLocationId, viewedLocationType) => async (dispatch) => {

  dispatch(actions.requestData(types.MAP_FEATURE_TO_LOCATION));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}/locations/${locationId}`, {
      geoJsonFeatureId: featureId
    }, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.MAP_FEATURE_TO_LOCATION));
      
      if (viewedLocationId === "root") {
        dispatch(getRootLocations());
      }
      else {
        dispatch(getLocationChildren(viewedLocationId));
      }

      if (["root", "region", "building"].includes(viewedLocationType)) {
        dispatch(authActions.getCompanyMap());
      }
      else {
        dispatch(getFloorMap(viewedLocationId));
      }

      dispatch(selectionActions.deselectAll());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.MAP_FEATURE_TO_LOCATION));
      }
    });
};

export const removeFeaturesFromLocations = (ids, currentLocationId) => async (dispatch) => {

  dispatch(actions.requestData(types.REMOVE_FEATURES_FROM_LOCATIONS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Looping through all location ids... 
  axios.all(ids.map((id) => (
      axios.put(`${apiUrl}companies/${companyId}/locations/${id}`, {
        geoJsonFeatureId: null
      }, config)
    )))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.REMOVE_FEATURES_FROM_LOCATIONS));
      dispatch(getFloorMap(currentLocationId));
      dispatch(authActions.getCompanyMap());
      dispatch(getLocationChildren(currentLocationId));
      dispatch(selectionActions.deselectAll());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.REMOVE_FEATURES_FROM_LOCATIONS));
      }
    });
};

export const uploadFloorMap = (id, body, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.UPLOAD_FLOOR_MAP));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/locations/${id}/map/create-features`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPLOAD_FLOOR_MAP));
      if (redirect) {
        dispatch(getFloorMap(id));
        redirect(`/companies/${companyId}/locations/${id}/locations`);
      }
    })
    .catch((error) => {
      console.log("syncCompanyMap error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPLOAD_FLOOR_MAP));
      }
    });
};

export const downloadResourceCSV = () => async (dispatch) => {
  dispatch(actions.requestData(types.GET_RESOURCE_CSV));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/csv/bookings`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(null, types.GET_RESOURCE_CSV));
      var filename;
      const disposition = response.headers['content-disposition'];
      if (disposition !== undefined) {
        filename = disposition.split("filename=")[1];
      }
      else {
        filename = "resources.csv"
      }
      const blob = new Blob([response.data], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(blob, filename)
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_RESOURCE_CSV));
      }
    });
};

export const getLocationHierarchy = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_LOCATION_HIERARCHY));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/locations-hierarchy`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_LOCATION_HIERARCHY));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_LOCATION_HIERARCHY));
      }
    });
};

export const downloadLocationsCSV = () => async (dispatch) => {
  dispatch(actions.requestData(types.GET_LOCATION_CSV));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // axios.get(`${apiUrl}companies/${companyId}/location-hierarchy-export`, await actions.getAxiosConfig())
  axios.get(`${apiUrl}companies/${companyId}/csv/locations`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(null, types.GET_LOCATION_CSV));

      var filename;
      const disposition = response.headers['content-disposition'];
      if (disposition !== undefined) {
        filename = disposition.split("filename=")[1];
      }
      else {
        filename = "locations.csv"
      }
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), response.data], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(blob, filename)
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_LOCATION_CSV));
      }
    });
}; 

export const uploadLocationsCSV = (binaryFile) => async (dispatch) => {
  dispatch(actions.requestData(types.UPLOAD_LOCATION_CSV));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  let config = await actions.getAxiosConfig();
  config.headers["Content-Type"] = "multipart/form-data";

  let formData = new FormData();
  formData.append("csv", binaryFile);

  return axios.post(`${apiUrl}companies/${companyId}/csv/locations`, formData, config)
    .then(response => {
      console.log("upload response", response);
      dispatch(actions.receiveData(response.data, types.UPLOAD_LOCATION_CSV));
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.UPLOAD_LOCATION_CSV));
      }
    });
};

export const verifyLocationsCSV = (binaryFile, uploadBody) => async (dispatch) => {
  dispatch(actions.requestData(types.UPLOAD_LOCATION_CSV));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  let config = await actions.getAxiosConfig();
  config.headers["Content-Type"] = "multipart/form-data";

  let formData = new FormData();
  formData.append("csv", binaryFile);

  // Append body - if defined
  let hash = get(uploadBody, "csvHash", null);
  let locationsToBeDeleted = get(uploadBody, "locationsToBeDeleted", null)

  if (hash) {
    formData.append("csvHash", hash);
  }

  if (locationsToBeDeleted) {
    formData.append("locationsToBeDeleted", JSON.stringify(locationsToBeDeleted.map(e=>e.id)));
  }

  return axios.post(`${apiUrl}companies/${companyId}/csv/locations/create-jobs`, formData, config)
    .then(response => {
      console.log("verify csv response", response);
      dispatch(actions.receiveData(response.data, types.UPLOAD_LOCATION_CSV));
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.UPLOAD_LOCATION_CSV));
      }
    });
};

export const clearLocationsCSVResponse = () => async (dispatch) => {
  dispatch(actions.clearData(types.UPLOAD_LOCATION_CSV));
};

export const clearLocation = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_LOCATION });
};

export const generateQRCodes = (locationIds, email, bookingQRCodes) => async (dispatch) => {
  dispatch(actions.requestData(types.GENERATE_QR_CODES));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/exports/qrcodes`, { locationIds, columns: 4, rows: 3, recipientEmails: [email], quickReservation: bookingQRCodes } , await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GENERATE_QR_CODES));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GENERATE_QR_CODES));
      }
    });
}