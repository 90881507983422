import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  data: {
    count: 0,
    configs: [],
    limit: 100
  },
  waitingDevices: []
};

export default function screensReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_SCREEN_CONFIGS) {
        return {...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_WAITING_SCREEN_DEVICES) {
        return {...state, waitingDevices: [] };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_SCREEN_CONFIGS) {
        return {...state, data: action.payload };
      }

      if (action.fetchType === types.GET_WAITING_SCREEN_DEVICES) {
        return {...state, waitingDevices: get(action.payload, "payload", []) };
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_SCREEN_CONFIGS) {
        toast.error(`${statusCode}: Could not get screens`);
        return {...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_WAITING_SCREEN_DEVICES) {
        toast.error(`${statusCode}: Could not get waiting devices`);
        return {...state, waitingDevices: [] };
      }

      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }
    
    default: {
      return state;
    }
  }
}
