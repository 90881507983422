import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import Table from "../../components/Table";
import * as displayActions from "../../actions/display";

class DisplayList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
    };

    // Old displays
    this.fetchDisplayData = this.fetchDisplayData.bind(this);
    this.onDisplaySearchClick = this.onDisplaySearchClick.bind(this);
    this.onDisplaySearchChange = this.onDisplaySearchChange.bind(this);
    this.onDisplaySortedChange = this.onDisplaySortedChange.bind(this);
    this.onDisplayOffsetChange = this.onDisplayOffsetChange.bind(this);
    this.onDisplayRowClick = this.onDisplayRowClick.bind(this);
  }

  componentDidMount() {
    this.fetchDisplayData();
  }

  onDisplaySearchClick() {
    this.setState({ offset: 0 }, this.fetchDisplayData);
  }

  onDisplaySearchChange(value) {
    this.setState({ searchText: value });
  }

  onDisplaySortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder, offset: 0 }, this.fetchDisplayData);
  }

  onDisplayOffsetChange(offset) {
    this.setState({ offset }, this.fetchDisplayData);
  }

  onDisplayRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (column.name !== 'isSelected' && rowInfo !== undefined) {

          let link = `/companies/${this.props.match.params.companyId}/displays/edit/${rowInfo.original._id}/`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  fetchDisplayData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getDisplays(queryParams);
  }

  render() {
    // console.log("Screens.render", this.state);

    const oldDisplayDescription = "The old display system is still running, but will be shut down in 2024. Please use the new screen system instead - it's easier to setup, more secure, more customizable and includes live samples.";

    const displayTable = getDisplayTable(this.props.displayData, this.state.sortBy, this.state.sortOrder, this.state.offset, this.state.limit, this.onDisplaySortedChange, this.onDisplayOffsetChange, this.onDisplayRowClick, this.props.isLoadingDisplays);

    const options = (
      <TopRowOptions
        description={oldDisplayDescription}
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onDisplaySearchChange}
            onSearchClick={this.onDisplaySearchClick}
            onClear={() => this.setState({ searchText: "" }, this.onDisplaySearchClick)}
            inListView
          />
        )}
      />
    );

    return (
      <>
        { options }
        { displayTable }
        <div style={{ paddingTop: "40px" }} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
    displayData: state.displays.data,
    isLoadingDisplays: state.loading.displays,
    selectedDisplays: state.selected.displays,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getDisplays: displayActions.getDisplays
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayList);

function getDisplayTable(data, sortBy, sortOrder, offset, limit, onSortedChange, onOffsetChange, onRowClick, isLoading) {
  return (
    <Table
      data={data.displays}
      sortBy={sortBy}
      sortOrder={sortOrder}
      offset={offset}
      limit={limit}
      count={data.count}
      onSortedChange={onSortedChange}
      onOffsetChange={onOffsetChange}
      noDataText={isLoading ? "" : "No displays found"}
      columns={[
        {
          header: "Name",
          accessorKey: "name",
          cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
        },
        {
          header: "Type",
          sortable: false,
          cell: ({ row }) => {
            if (row.original.map) {
              return <span title="Map">Map</span>;
            }
            
            if (row.original.powerbi) {
              return <span title="Report">Report</span>;
            }
            if (row.original.dashboard) {
              return <span title="Insight">Insight</span>;
            }

            return <span title="Unknown">Unknown</span>;
          }
        },
        {
          header: "ID",
          accessorKey: "_id",
          sortable: false,
        },
        {
          id: "arrow",
          header: "",
          accessorKey: 'properties',
          sortable: false,
          className: "pull-right",
          width: 60,
          cell: () => (<div className="arrow" />)
        }
      ]}
      getTdProps={onRowClick}
      className="-row-clickable setMaxHeigth -highlight"
      loading={isLoading}
    />
  );
}