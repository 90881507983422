import React, { Component } from "react";
import { get, isEmpty } from "lodash";
import { SafeTripleColors } from "../../../constants/colors";
import { GraphContainer, TimePeriod } from "ui";
import styled from "styled-components";
import strings from "../strings";

class UtilizationWeekdays extends Component {

  render() {
    // Do not render if there are no queries
    if (isEmpty(this.props.queries)) {
      return null;
    }

    // Get graph name
    const graphKey = "weekday";

    // Get localized strings
    strings.setLanguage(this.props.language || "en");

    // Add either workplace categories or custom tags as entity names
    let entities = {};
    this.props.queries.forEach(query => {
      let selectedWorkplaceCategory = query.workplaceCategory;
      if (selectedWorkplaceCategory) {
        // Add custom tags
        get(selectedWorkplaceCategory, `customTags`, []).forEach(customTag => {
          entities[customTag._id] = { name: customTag.name };
        });
      }
      else {
        // Add workplace categories
        get(this.props.workplaceFilters, "wpaCategories", []).forEach(category => {
          if (category.customTags.length > 0) {
            entities[category._id] = { name: category.name };
          }
        });
      }
    });

    // Add colors to each entity
    Object.keys(entities).forEach((entityId, index) => {

      let keys = [];
      let colors = [];

      // Add color to each active data key
      this.props.graphDataKeys.forEach((keyObject, keyIndex) => {
        if (keyObject.show) {
          keys.push(keyObject.key);
          colors.push(SafeTripleColors[index % 11][keyIndex]);
        }
      });

      entities[entityId] = { ...entities[entityId], colors, keys };
    });

    // Get maximum value for all graphs
    const min = 0;
    let max = 100;
    const allPossibleKeys = this.props.graphDataKeys.filter(dk => dk.show).map(dk => dk.key);

    if (this.props.queries && this.props.queries.length > 0) {
      max = Math.max(...this.props.queries.map(query => {
        const hashKey = `${graphKey}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.customTagId}-${query.summed}`;
        const samples = this.props.queryData[hashKey] || [];

        // Sample data is an array of objects with keys: avgAvg, avgPeak, peakPeak, capacity
        if (samples.length > 0) {
          
          // Get all values in an array, filtering out undefined values
          const allValues = samples.flatMap(sample => 
            allPossibleKeys.map(key => sample[key]).filter(value => value !== undefined)
          );

          // Return the maximum value, ensuring we consider at least 0 if allValues is empty
          return allValues.length > 0 ? Math.max(...allValues, 0) : 0;
        }

        return 100;
      }));
    }
    max = Math.max(Math.ceil(max / 10) * 10, 10);
    
    return (
      <Row>
        {
          this.props.queries.map((query, index) => {
            const hashKey = `${graphKey}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.workplaceCategoryId}`;
            const samples = this.props.queryData[hashKey] || [];
            const loadingStatus = this.props.queryLoading[hashKey] ?? { type: "not started" };
            const isLoading = loadingStatus.type === "loading";

            return (
              <Col key={hashKey + index} $isGraph $expanded={this.props.queries.length === 1}>
                <GraphContainer
                  source="report"
                  dataType="utilization"
                  workplaceCategory={get(query.workplaceCategory, "name", "")}
                  locationName={get(query.location, "name", "?")}
                  samples={samples}
                  y={{ min: 0, max: 100, zoomMin: min, zoomMax: max, unit: "%" }}
                  x={{ min: get(query.dateRanges, "[0].startDate", null), max: get(query.dateRanges, `[${query.dateRanges.length - 1}].endDate`, null) }}
                  allKeys={this.props.graphValueKeys}
                  entities={entities}
                  graphType={query.graphType}
                  graphShowLabels={query.graphShowLabels}
                  includeWeekends={query.includeWeekends}
                  language={this.props.language}
                  isLoading={isLoading}
                  statusMessage={!isLoading && isEmpty(samples) ? strings.noData : undefined}
                  timePeriod={TimePeriod.WeekDays}
                  onDraggingStart={() => this.setState({ isDraggingSlider: true })}
                  onDraggingEnd={() => this.setState({ isDraggingSlider: false })}
                  showPNGOption
                  showCSVOption
                  toggleGraphType={() => this.props.onGraphTypeChanged(index, query.graphType === "line" ? "bar" : "line")}
                  toggleGraphShowLabels={() => this.props.onGraphShowLabelsChanged(index, !query.graphShowLabels)}
                />
              </Col>
            );
          })
        }
      </Row>
    );
  }
}

export default UtilizationWeekdays;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  padding-bottom: 40px;
`;

// Set a fixed width for the graphs
// This is to prevent the graphs from being too small on small screens
// and show the graphs side by side on large screens
const Col = styled.div`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "50%" : "100%")};
  width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "50%" : "100%")};
  min-width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "508px" : "200px")};
  max-width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "calc(50% - 5px)" : "100%")};

  @media (max-width: 1600px) {
    flex-basis: 100%;
    width: 100%;
    min-width: 200px;
    max-width: 100%;
  }
`;