import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  id: null,
  name: null,
  config: null,
  devices: [],
  form: {},
};

export default function screenReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_SCREEN_CONFIG) {
        return initialState;
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_SCREEN_CONFIG) {

        // Keep form object if it is the same screen
        if (action.payload.id === state.form.id) {
          return {
            ...state,
            id: action.payload.id,
            name: get(action.payload, "name", ""),
            config: { ...get(action.payload, "config", {}) }
          };
        }

        // Create form object
        const form = {
          id: get(action.payload, "id", ""),
          name: get(action.payload, "name", ""),
          config: { ...get(action.payload, "config", {}) }
        };

        if (get(action.payload, "config.ui", null)) {
          try {
            form.config.ui = JSON.stringify(get(action.payload, "config.ui", ""), null, 2);
          }
          catch (e) {
            form.config.ui = "";
          }
        }

        if (get(action.payload, "config.view", null)) {
          console.log("ScreenConfigLayoutDetails.RECV_DATA.config.view", get(action.payload, "config.view", ""));
          try {
            form.config.view = JSON.stringify(get(action.payload, "config.view", ""), null, 2);
          }
          catch (e) {
            form.config.view = "";
          }
        }

        if (get(action.payload, "config.yourLocation", null)) {
          form.config.yourLocation = { ...get(action.payload, "config.yourLocation", {})};
        }

        return {
          ...state,
          id: action.payload.id,
          name: get(action.payload, "name", ""),
          config: { ...get(action.payload, "config", {}) },
          devices: get(action.payload, "screens", []),
          form
        };
      }

      if (action.fetchType === types.CREATE_SCREEN_CONFIG) {
        toast.success("Screen created");
        return state;
      }

      if (action.fetchType === types.UPDATE_SCREEN_CONFIG_FORM) {
        return {...state, form: action.payload };
      }

      if (action.fetchType === types.UPDATE_SCREEN_CONFIG) {
        toast.success("Screen updated");
        return state;
      }

      if (action.fetchType === types.GET_SCREEN_DEVICES) {
        return {
          ...state,
          devices: get(action.payload, "screens", [])
        };
      }

      if (action.fetchType === types.UPDATE_SCREEN_DEVICE) {
        toast.success("Device updated");
        let newDevices = JSON.parse(JSON.stringify(state.devices));
        const index = newDevices.findIndex((device) => device.id === action.metadata.id);
        
        if (index === -1) {
          return state;
        }
        
        // Update device
        newDevices[index] = { ...newDevices[index], ...action.metadata };
        
        return {...state, devices: newDevices };
      }

      if (action.fetchType === types.DELETE_SCREEN_DEVICE) {
        toast.success("Device deleted");
        let newDevices = JSON.parse(JSON.stringify(state.devices));
        const index = newDevices.findIndex((device) => device.id === action.metadata.id);
        
        if (index === -1) {
          return state;
        }

        // Remove device from screen
        newDevices.splice(index, 1);
        
        return {...state, devices: newDevices };
      }

      if (action.fetchType === types.DELETE_SCREEN_CONFIG) {
        toast.success("Screen deleted");
        return initialState;
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_SCREEN_CONFIG) {
        toast.error(`${statusCode}: Could not get screen config`);
        return initialState;
      }

      if (action.fetchType === types.CREATE_SCREEN_CONFIG) {
        toast.error(`${statusCode}: Could not create screen config`);
        return state;
      }

      if (action.fetchType === types.UPDATE_SCREEN_CONFIG) {
        toast.error(`${statusCode}: Could not update screen config`);
        return state;
      }

      if (action.fetchType === types.DELETE_SCREEN_CONFIG) {
        toast.error(`${statusCode}: Could not delete screen config`);
        return state;
      }

      if (action.fetchType === types.UPDATE_SCREEN_DEVICE) {
        toast.error(`${statusCode}: Could not update device`);
        return state;
      }

      if (action.fetchType === types.DELETE_SCREEN_DEVICE) {
        toast.error(`${statusCode}: Could not delete device`);
        return state;
      }

      return state;
    }

    case types.RESET_SCREEN_FORM: {
      // Create form object
      const form = {
        name: get(state, "name", ""),
        config: { ...get(state, "config", undefined) }
      };

      if (get(state, "config.yourLocation", null)) {
        form.config.yourLocation = { ...get(state, "config.yourLocation", {})};
      }

      if (get(state, "config.ui", null)) {
        try {
          form.config.ui = JSON.stringify(get(state, "config.ui", ""), null, 2);
        }
        catch (e) {
          form.config.ui = "";
        }
      }

      if (get(state, "config.view", null)) {
        try {
          form.config.view = JSON.stringify(get(state, "config.view", ""), null, 2);
        }
        catch (e) {
          form.config.view = "";
        }
      }

      return {...state, form };
    }

    case types.CLEAR_DATA:
    case types.CLEAR_SCREEN_DATA: {
      return initialState;
    }
    
    default: {
      return state;
    }
  }
}
