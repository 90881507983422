import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import Loader from "../../components/Loader";
import NotFound from "../NotFound";
import SubNavigation from "../../components/SubNavigation";
import LargeHeader from "../../components/LargeHeader";
import PendingScreens from "./pendingScreens";
import Displays from "./displays";
import Screens from "./screens";
import style from "./style.module.scss";

class ScreenContainer extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    // console.log("ScreenContainer", this.props);
    if (this.props.isAuthLoading) {
      return <Loader fullScreen />;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/configs`, label: `Configs` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/waiting`, label: `Devices waiting` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/displays`, label: `Displays`, notifications: `deprecated` });

    return (
      <div className={style.page}>
        <LargeHeader title="Screens" />
        <SubNavigation links={subNavigationLinks} /> 
        <Switch>
          <Redirect exact from="/companies/:companyId/screens" to={{ pathname: `/companies/${this.props.match.params.companyId}/screens/configs` }} />
          <Route path="/companies/:companyId/screens/waiting" children={(props) => <PendingScreens {...props} />} />
          <Route path="/companies/:companyId/screens/configs/:configId?" children={(props) => <Screens {...props} configId={props.match.params.configId} />} />
          <Route path="/companies/:companyId/screens/displays/:displayId?" children={(props) => <Displays {...props} displayId={props.match.params.displayId} />} />
          <Route render={NotFound} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth,
    locationHierarchy: state.locations.hierarchy,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
   }, dispatch);
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScreenContainer));
