import axios from "axios";
import FileSaver from "file-saver";
import Cookies from "universal-cookie";
import jose from "node-jose";
import moment from "moment";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as locationActions from "./locations";
import * as selectionActions from "./selected";
import * as gatewayActions from "./gateways";
import { API_URL, WEBEX_API_URL, BASE_URL, COOKIE_PREFIX, WEBEX_APIS } from "../env";
import { getCompanyId, toShortISOString } from "../helpers";

const cookies = new Cookies();

export const getAllSensors = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_ALL_SENSORS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/sensor-metrics`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_ALL_SENSORS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_ALL_SENSORS));
      }
    });
};

export const getSamples = (sensorId, atomicSensorId) => async (dispatch) => {
  // dispatch(actions.requestData(types.GET_SAMPLES, { sensorId, atomicSensorId }));

  // const apiUrl = await API_URL();
  // const companyId = getCompanyId();
  // const cookiePrefix = await COOKIE_PREFIX();
  // const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // const date = new Date();
  // date.setHours(date.getHours() - 24);
  // const queryParams = {
  //   limit: 20,
  //   offset: 0,
  //   startdate: date.toISOString()
  // }

  // axios.get(`${apiUrl}companies/${companyId}/sensors/${sensorId}/atomic-sensors/${atomicSensorId}/samples`, await actions.getAxiosConfig(queryParams))
  //   .then((response) => {
  //     dispatch(actions.receiveData(response.data, types.GET_SAMPLES, { sensorId, atomicSensorId }));
  //   })
  //   .catch((error) => {
  //     if (error.response && error.response.status === 401) {
  //       actions.sessionTokenExpired(cookiePrefix, accessToken);
  //     } else {
  //       dispatch(actions.receiveError(error, types.GET_SAMPLES, { sensorId, atomicSensorId }));
  //     }
  //   });
};

export const getSensor = (sensorId, hasAccessRights, queryParams) => async (dispatch) => {
  dispatch({
    type: types.REQ_DATA,
    payload: {
      id: sensorId
    },
    fetchType: types.GET_SENSOR
  });

  const apiUrl = await API_URL();
  const webexApiUrl = await WEBEX_API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/sensors/${sensorId}`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SENSOR, webexApiUrl));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SENSOR));
      }
    });
};

export const findSensor = (vendorSensorId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SENSOR));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/yanzi-sensors/${vendorSensorId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SENSOR));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SENSOR));
      }
    });
};

export const getSensors = (id, queryParams) => async (dispatch) => {
  const hashParams = queryParams ?? {"offset":0,"limit":20,"sortBy":"name","sortOrder":"asc"};
  dispatch(actions.requestData(types.GET_SENSORS, { id, hashParams }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/locations/${id}/sensors`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SENSORS, { id, hashParams }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SENSORS));
      }
    });
};

export const moveSensors = (ids, newLocationId, transferSampleHistoryToo) => async (dispatch) => {
  dispatch(actions.requestData(types.MOVE_SENSORS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Looping through all SENSOR IDS
  axios.all(ids.map((id) => (
      axios.put(`${apiUrl}companies/${companyId}/sensors/${id}?transferSampleHistoryToo=${transferSampleHistoryToo}`, {
        locationIds: [newLocationId]
      }, config)
    )))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.MOVE_SENSORS));
      dispatch(selectionActions.clearSelection());
      dispatch(getSensors(newLocationId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.MOVE_SENSORS));
      }
    });
};

export const removeSensors = (ids, newLocationId) => async (dispatch) => {
  dispatch(actions.requestData(types.MOVE_SENSORS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Looping through all SENSOR IDS
  axios.all(ids.map((id) => (
      axios.put(`${apiUrl}companies/${companyId}/sensors/${id}?transferSampleHistoryToo=${true}`, {
        locationIds: []
      }, config)
    )))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.MOVE_SENSORS));
      dispatch(selectionActions.clearSelection());
      dispatch(getSensors(newLocationId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.MOVE_SENSORS));
      }
    });
};

export const updateSensor = (id, body, locationId) => async (dispatch) => {
  dispatch({
    type: types.REQ_DATA,
    payload: {
      id
    },
    fetchType: types.UPDATE_SENSOR
  });

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}/sensors/${id}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_SENSOR));
      dispatch(selectionActions.clearSelection());
      if (locationId) {
        dispatch(locationActions.getFloorMap(locationId));
      }
      dispatch(getSensor(id));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_SENSOR));
      }
    });
};

export const moveSensorsToGateway = (sensorIds, gatewayId) => async (dispatch) => {
  dispatch({
    type: types.REQ_DATA,
    payload: {
      sensorIds
    },
    fetchType: types.MOVE_SENSORS_TO_GATEWAY
  });

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();
  const body = { gatewayId };

  // Looping through all SENSOR IDS
  axios.all(sensorIds.map((id) => (
      axios.put(`${apiUrl}companies/${companyId}/sensors/${id}`, body, config)
    )))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.MOVE_SENSORS_TO_GATEWAY));
      dispatch(selectionActions.clearSelection());

      if (gatewayId) {
        dispatch(gatewayActions.getGatewaySensors(gatewayId));
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.MOVE_SENSORS_TO_GATEWAY));
      }
    });
};

export const createSensor = (sensorType, sensor, currentLocationId, redirect) => async (dispatch) => {

  dispatch(actions.requestData(types.CREATE_SENSOR));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  let url = "";
  const thisSensor = sensor;
  const { gatewayId } = thisSensor;

  switch (sensorType) {
    case "hono":
      url = `${apiUrl}companies/${companyId}/hono/sensors`;
      break;
    case "yanzi":
      delete thisSensor.gatewayId;
      url = `${apiUrl}companies/${companyId}/gateways/${gatewayId}/yanzi-sensors`;
      break;
    case "webex":
    case "webex-manual":
      url = `${apiUrl}companies/${companyId}/webex-sensor`;
      break;
    default:
      dispatch(actions.receiveError(null, types.CREATE_SENSOR));
      return;
  }

  axios.post(url, thisSensor, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_SENSOR));
      dispatch(selectionActions.clearSelection());

      if (currentLocationId && redirect && sensorType !== "hono" && sensorType !== "webex") {
        redirect(`/companies/${companyId}/locations/${currentLocationId}/sensors`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_SENSOR));
      }
    });
};

export const deleteSensor = (sensorId, currentLocationId, keepSampleHistory) => async (dispatch) => {

  dispatch(actions.requestData(types.DELETE_SENSOR));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  let queryParams = {};
  if (keepSampleHistory) {
    queryParams = { keepSampleHistory };
  }

  axios.delete(`${apiUrl}companies/${companyId}/sensors/${sensorId}`, await actions.getAxiosConfig(queryParams))
    .then((response) => {

      dispatch(actions.receiveData(response.data, types.DELETE_SENSOR));
      dispatch(selectionActions.clearSelection());
      dispatch(getSensors(currentLocationId));

    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_SENSOR));
      }
    });
};

export const getDiscoveredSensors = (gatewayId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_DISCOVERED_SENSORS, gatewayId));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/gateways/${gatewayId}/discovered-yanzi-sensors`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_DISCOVERED_SENSORS, gatewayId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_DISCOVERED_SENSORS));
      }
    });
};

export const downloadSensorsCSV = () => async (dispatch) => {
  dispatch(actions.requestData(types.DOWNLOAD_SENSOR_CSV));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/csv/sensors`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(null, types.DOWNLOAD_SENSOR_CSV));
      const blob = new Blob([response.data], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(blob, "sensors.csv")
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DOWNLOAD_SENSOR_CSV));
      }
    });
};

export const createMeshNode = (meshNode, currentLocationId, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.CREATE_MESH_NODE));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const thisMeshNode = meshNode;
  const { gatewayId } = thisMeshNode;
  delete thisMeshNode.gatewayId;

  axios.post(`${apiUrl}companies/${companyId}/gateways/${gatewayId}/yanzi-mesh-nodes`, thisMeshNode, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_MESH_NODE));
      dispatch(selectionActions.clearSelection());

      if (currentLocationId && redirect) {
        redirect(`/companies/${companyId}/locations/${currentLocationId}/gateways`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_MESH_NODE));
      }
    });
};

export const getWebexMacro = (keystore, sensorId, version) => async (dispatch) => {

  const baseUrl = await BASE_URL();
  const webexApiUrl = await WEBEX_API_URL();
  
  const path = version === 11 ? "/macrofile-v11" : "/macrofile";
  const macro = await axios.get(path);
  const newKeystore = await jose.JWK.asKeyStore(keystore);
      
  const [key] = newKeystore.all({ use: 'sig', kty: 'RSA' });
  const privateKey = key.toPEM(true);
    
  let editedMacro = macro.data;
  editedMacro = editedMacro.replace("<Smarte Bygg device id>", sensorId);
  editedMacro = editedMacro.replace("<Smarte Bygg certificate>", privateKey);
  editedMacro = editedMacro.replace("<Smarte Bygg api url>", webexApiUrl);
          
  dispatch(actions.receiveData(editedMacro, types.GET_WEBEX_MACRO, { version }));
};

export const clearCreatedJob = () => async (dispatch) => {
  dispatch(actions.requestData(types.CLEAR_CREATED_JOB));
};

// https://developer.webex.com/docs/api/v1/devices/list-devices
export const getWebexDevices = (displayName) => async (dispatch) => {

  const apiUrl = await WEBEX_APIS();
  const ciscoAccessToken = cookies.get(`bldng_ai_cisco_access_token`);

  const options = { headers: { Authorization: `Bearer ${ciscoAccessToken}`, Accept: "application/json" } };

  var url = `${apiUrl}/devices`;
  if (displayName) {
    url = `${url}?displayName=${displayName}`;
  }

  axios.get(url, options)
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_WEBEX_DEVICES));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        
        var baseUrl;
        if (process.env.NODE_ENV === "development") {
          baseUrl = "http://localhost:8081";
        }
        else {
          baseUrl = window.location.origin;
        }

        window.location.href = `${baseUrl}/authenticate/cisco?from=${window.location.href}`;

      } else {
        dispatch(actions.receiveError(error, types.GET_WEBEX_DEVICES));
      }
    });
}

export const getWebexDeviceMacroStatus = (id) => async (dispatch) => {

  const apiUrl = await WEBEX_APIS();
  const ciscoAccessToken = cookies.get(`bldng_ai_cisco_access_token`);

  const options = { headers: { Authorization: `Bearer ${ciscoAccessToken}`, Accept: "application/json" } };
  const body = { deviceId: id, "arguments": { "Name": "bldng-ai-macro" } };

  axios.post(`${apiUrl}/xapi/command/Macros.Macro.Get`, body, options)
    .then((response) => {
      dispatch(actions.receiveData(200, types.GET_WEBEX_MACRO_STATUS));
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
        
          var baseUrl;
          if (process.env.NODE_ENV === "development") {
            baseUrl = "http://localhost:8081";
          }
          else {
            baseUrl = window.location.origin;
          }

          window.location.href = `${baseUrl}/authenticate/cisco?from=${window.location.href}`;

        } else {
          dispatch(actions.receiveData(error.response.status, types.GET_WEBEX_MACRO_STATUS));
        }
      }
    });
}

export const getSensorTypes = (locationIds, filter) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SENSOR_TYPES));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/sensor-types`, { locationIds }, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SENSOR_TYPES, filter));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SENSOR_TYPES, filter));
      }
    });
}

export const updateAtomicSensor = (sensorId, atomicSensorId, body) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_ATOMIC_SENSOR));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}/sensors/${sensorId}/atomic-sensors/${atomicSensorId}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_ATOMIC_SENSOR));
      dispatch(getSensor(sensorId));
      dispatch(selectionActions.clearSelection());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_ATOMIC_SENSOR));
      }
    });
}

export const getSensorUptime = (sensorId, span = "day") => async (dispatch) => {
  const today = moment().startOf("day").toISOString();
  dispatch(actions.requestData(types.GET_SENSOR_UPTIME, { sensorId, date: today, span }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = { span };

  if (span === "day") {
    const startDate = moment().subtract(30, "days").startOf("day").toISOString();
    const endDate = moment().startOf("day").toISOString();
    queryParams.startdate = startDate;
    queryParams.enddate = endDate;
  }
  else if (span === "hour") {
    const startDate = moment().subtract(7, "days").startOf("day").toISOString();
    const endDate = moment().startOf("hour").toISOString();
    queryParams.startdate = startDate;
    queryParams.enddate = endDate;
  }
  else if (span === "quarter") {
    const startDate = moment().startOf("day").toISOString();

    // Round down to nearest quarter hour
    const roundedMinutes = Math.floor(moment().minutes() / 15) * 15;
    const endDate = moment().startOf("hour").minutes(roundedMinutes).toISOString();
    
    queryParams.startdate = startDate;
    queryParams.enddate = endDate;
  }
  
  axios.get(`${apiUrl}companies/${companyId}/sensors/${sensorId}/samples-per-day`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SENSOR_UPTIME, { sensorId, date: today, span }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SENSOR_UPTIME));
      }
    });
}

export const getUnregisteredSensors = () => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UNREGISTERED_SENSORS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/hono/unregistered-sensors`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UNREGISTERED_SENSORS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UNREGISTERED_SENSORS));
      }
    });
}

export const getSensorSamples = (sensorId, atomicSensorId, startdate, enddate, span) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SAMPLES, { sensorId, atomicSensorId, startdate, enddate, span }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = { 
    startdate: toShortISOString(startdate), 
    enddate: moment().isAfter(enddate) ? toShortISOString(enddate) : undefined,
    span,
    limit: 3600
  };

  axios.get(`${apiUrl}companies/${companyId}/sensors/${sensorId}/atomic-sensors/${atomicSensorId}/samples`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SAMPLES, { sensorId, atomicSensorId, startdate, enddate, span }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SAMPLES, { sensorId, atomicSensorId, startdate, enddate, span }));
      }
    });
}

export const deleteSamples = (sensorId, atomicSensorId, startdate, enddate) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_SAMPLES, { sensorId, atomicSensorId, startdate, enddate }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const body = { 
    startdate: toShortISOString(startdate), 
    enddate: toShortISOString(enddate)
  };

  axios.post(`${apiUrl}companies/${companyId}/sensors/${sensorId}/atomic-sensors/${atomicSensorId}/delete-data-range`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_SAMPLES, { sensorId, atomicSensorId, startdate, enddate }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_SAMPLES, { sensorId, atomicSensorId, startdate, enddate }));
      }
    });
}