import axios from "axios";
import Cookies from "universal-cookie";
import get from "lodash/get";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { API_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getWaitingScreenDevices = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_WAITING_SCREEN_DEVICES));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // const testDevices = [
  //   {
  //     "id": "dvice1",
  //     "code": "F3D2",
  //     "screenConfig": { "id": "config1", "name": "Kart K2" }
  //   }
  // ];
  // dispatch(actions.receiveData(testDevices, types.GET_WAITING_SCREEN_DEVICES));
    
  axios.get(`${apiUrl}companies/${companyId}/screen-devices/not-approved`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_WAITING_SCREEN_DEVICES));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_WAITING_SCREEN_DEVICES));
      }
    });
};

export const getScreens = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SCREEN_CONFIGS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/screen-groups`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SCREEN_CONFIGS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SCREEN_CONFIGS));
      }
    });
};

export const getScreen = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SCREEN_CONFIG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/screen-groups/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SCREEN_CONFIG));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SCREEN_CONFIG));
      }
    });
};

export const createScreen = (config, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.CREATE_SCREEN_CONFIG));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const axiosConfig = await actions.getAxiosConfig();

  axios.post(`${apiUrl}companies/${companyId}/screen-groups`, config, axiosConfig)
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_SCREEN_CONFIG));

      if (response.data && response.data.id) {
        if (redirect) {
          redirect(`/companies/${companyId}/screens/configs/${response.data.id}/edit`);
        }
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_SCREEN_CONFIG));
      }
    });
};

export const updateScreen = (id, config, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.UPDATE_SCREEN_CONFIG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // Clean up config
  if (get(config, "yourLocation", null)) {
    const lat = Number(get(config, "yourLocation.lat", null));
    const lng = Number(get(config, "yourLocation.lng", null));
    const color = get(config, "yourLocation.color", null);
    if (lat && lng) {
      config.yourLocation = {};
      config.yourLocation.lat = lat;
      config.yourLocation.lng = lng;
      if (color) {
        config.yourLocation.color = color;
      }
    }
    else {
      delete config.yourLocation;
    }
  }

  const bearing = Number(get(config, "initialBearing", 0));
  if (bearing) {
    config.initialBearing = bearing;
  }

  const pitch = Number(get(config, "initialPitch", 0));
  if (pitch) {
    config.initialPitch = pitch;
  }
  
  axios.put(`${apiUrl}companies/${companyId}/screen-groups/${id}`, config, await actions.getAxiosConfig())
    .then((response) => {
      // if (redirect) {
      //   redirect(`/companies/${companyId}/screens`);
      // }
      dispatch(actions.receiveData(response.data, types.UPDATE_SCREEN_CONFIG));
      dispatch(getScreen(id));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_SCREEN_CONFIG));
      }
    });
};

export const deleteScreen = (id, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.DELETE_SCREEN_CONFIG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/screen-groups/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_SCREEN_CONFIG));
      if (redirect) {
        redirect(`/companies/${companyId}/screens`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_SCREEN_CONFIG));
      }
    });
};

export const getScreenDevices = (screenId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SCREEN_DEVICES));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/screen-groups/${screenId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SCREEN_DEVICES));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SCREEN_DEVICES));
      }
    });
};

export const patchScreenDevice = (configId, deviceId, body, callback) => async (dispatch) => {
  dispatch(actions.postData(types.UPDATE_SCREEN_DEVICE));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.patch(`${apiUrl}companies/${companyId}/screen-devices/${configId}/${deviceId}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_SCREEN_DEVICE, { id: deviceId, ...body }));
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_SCREEN_DEVICE));
      }
    });
};

export const deleteScreenDevice = (configId, deviceId, callback) => async (dispatch) => {
  dispatch(actions.postData(types.DELETE_SCREEN_DEVICE));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/screen-devices/${configId}/${deviceId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_SCREEN_DEVICE, { id: deviceId }));
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_SCREEN_DEVICE));
      }
    });
};

export const updateScreenForm = (form) => async (dispatch) => {
  dispatch(actions.receiveData(form, types.UPDATE_SCREEN_CONFIG_FORM));
};

export const resetScreenForm = () => async (dispatch) => {
  dispatch({ type: types.RESET_SCREEN_FORM });
};

export const deselectScreen = () => async (dispatch) => {
  dispatch({ type: types.DESELECT_SCREEN });
}

export const clearScreenData = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_SCREEN_DATA });
}