import React, { Component } from "react";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { SafeTripleColors } from "../../../constants/colors";
import { SimpleGraph, GraphType } from "ui";
import styled from "styled-components";
import strings from "../strings";

class PeopleCountYearOverYear extends Component {

  render() {

    // Do not render if there are no queries
    if (isEmpty(this.props.queries)) {
      return null;
    }
    
    // Get graph name
    const graphKey = "month";

    // Get localized strings
    strings.setLanguage(this.props.language || "en");

    // Add either workplace categories or custom tags as entity names
    let entities = {};
    this.props.queries.forEach(query => {
      let selectedCustomTagId = query.customTagId;
  
      // Add custom tag
      if (selectedCustomTagId && selectedCustomTagId !== "all") {
        const customTag = this.props.customTags.find(tag => tag.id === selectedCustomTagId);
        if (customTag) {
          entities[selectedCustomTagId] = { name: customTag.name };
        }
      }
      else {
        // Add location
        entities[query.locationId] = { name: get(query, "location.name", "") };
      }
    });

    // Get maximum value for all graphs
    let min = 0;
    let max = 10;
    const allPossibleKeys = [...this.props.graphDataKeys.filter(dk => dk.show).map(dk => dk.key), "capacity"];
    if (this.props.queries && this.props.queries.length > 0) {
      max = Math.max(...this.props.queries.map(query => {
        let hashKey = `${graphKey}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.customTagId}-true`;
        hashKey += query.graphScaleType === "percentage" ? "-yearOverYear-percentage" : "-yearOverYear";
        const samples = this.props.queryData[hashKey] || [];
  
        // Sample data is an array of objects with keys: avgAvg, avgPeak, peakPeak, capacity
        if (samples.length > 0) {
          
          // Get all values in an array, filtering out undefined values
          const allValues = samples.flatMap(sample => 
            allPossibleKeys.map(key => sample[key]).filter(value => value !== undefined)
          );

          // Return the maximum value, ensuring we consider at least 0 if allValues is empty
          return allValues.length > 0 ? Math.max(...allValues, 0) : 0;
        }
  
        return query.graphScaleType === "percentage" ? 100 : 10;
      }));
    }
    max = Math.ceil(max / 10) * 10;


    let ticks = [];

    // Create month array
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach(month => {
      let tick = {
        label: moment().set("month", month - 1).format("MMM"),
        value: month,
      }
      ticks.push(tick);
    });

    let allUniqueYears = {};
    this.props.queries.forEach((query, index) => {

      const startDate = moment(get(query.dateRanges, "[0].startDate", null));
      const endDate = moment(get(query.dateRanges, `[${query.dateRanges.length-1}].endDate`, null));

      // Create ticks (x-axis) for labels and datasets for each date range
      let startMoment = startDate.clone();
      let endMoment = endDate.clone();
      
      // Make tick contain whole month
      startMoment = startMoment.startOf("month");
      endMoment = endMoment.endOf("month");

      // Get all years in the time span startMoment - endMoment
      let currentYear = startMoment.year();
      while (currentYear <= endMoment.year()) {
        allUniqueYears[currentYear] = currentYear;
        currentYear++;
      }
    });

    // Add colors to each entity
    const allYears = {};
    Object.keys(allUniqueYears).forEach((year, index) => {

      let keys = [];
      let colors = [];

      // Add color to each active data key
      this.props.graphDataKeys.forEach((keyObject, keyIndex) => {
        if (keyObject.show) {
          keys.push(keyObject.key);
          colors.push(SafeTripleColors[index % 11][keyIndex]);
        }
      });

      allYears[year] = { name: year, colors, keys };
    });

    return (
      <Row>
        {
          this.props.queries.map((query, index) => {
            let hashKey = `${graphKey}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.customTagId}-true`;

            const loadingStatus = this.props.queryLoading[hashKey] ?? { type: "not started" };
            const isLoading = loadingStatus.type === "loading";

            hashKey += query.graphScaleType === "percentage" ? "-yearOverYear-percentage" : "-yearOverYear";
            let samples = this.props.queryData[hashKey] || [];

            // Add capacity to entities and keys
            // let queryKeys = this.props.graphDataKeys;
            // let queryEntities = { ...entities };
            // for (let j=0; j < samples.length; j++) {
            //   if (get(samples[j], "entityId", undefined) === "capacity") {
            //     queryEntities["capacity"] = { name: "capacity", colors: ["#222"], keys: ["capacity"] };
            //     queryKeys = [...queryKeys, "capacity"];
            //     break;
            //   }
            // }

            const startDate = moment(get(query.dateRanges, "[0].startDate", null));
            const endDate = moment(get(query.dateRanges, `[${query.dateRanges.length-1}].endDate`, null));
            const title = strings.formatString(strings.peopleCount.graphTitle, get(query.location, "name", "?") || strings.unknown, startDate.format("DD/MM/YYYY"), endDate.format("DD/MM/YYYY"));


            const y = { min: 0, max: max, zoomMin: min, zoomMax: max, unit: query.graphScaleType === "percentage" ? "%" : "" };
            const x = { min: get(query.dateRanges, "[0].startDate", null), max: get(query.dateRanges, `[${query.dateRanges.length-1}].endDate`, null), dateRanges: query.dateRanges };

            // Create ticks (x-axis) for labels and datasets for each date range
            let startMoment = startDate.clone();
            let endMoment = endDate.clone();
            
            // Make tick contain whole month
            startMoment = startMoment.startOf("month");
            endMoment = endMoment.endOf("month");

            // Get all years in the time span startMoment - endMoment
            let uniqueYears = {};
            let currentYear = startMoment.year();
            while (currentYear <= endMoment.year()) {
              uniqueYears[currentYear] = currentYear;
              currentYear++;
            }

            // Find unique entityIds in samples
            let uniqueYearsArray = Object.keys(uniqueYears);
            if (uniqueYearsArray.length === 0) {
              uniqueYearsArray = [null];
            }
            uniqueYearsArray.sort();

            // console.log("hashKey", hashKey);
            // console.log("valueKeys", this.props.graphDataKeys);
            // console.log("entities", allYears);
            // console.log("samples", samples);
            // console.log("loadingStatus", loadingStatus);

            return (
              <Col key={hashKey + index} $isGraph $expanded={this.props.queries.length === 1}>
                <SimpleGraph
                  source="report"
                  dataType="peopleCount"
                  title={title}
                  locationName={get(query.location, "name", "?")}
                  samples={samples}
                  aspectRatio={2.2}
                  y={y}
                  x={x}
                  ticks={ticks}
                  uniqueEntityIds={uniqueYearsArray}

                  entities={allYears}
                  allKeys={this.props.graphDataKeys}
                  // allKeys={queryKeys}
                  // entities={queryEntities}

                  graphType={GraphType.Line}
                  graphScaleType={query.graphScaleType}
                  graphShowLabels={query.graphShowLabels}
                  language={this.props.language}
                  isLoading={isLoading}
                  statusMessage={!isLoading && isEmpty(samples) ? strings.noData : undefined}
                  showPNGOption
                  showCSVOption
                  toggleGraphScaleType={() => this.props.onGraphScaleTypeChanged(index, query.graphScaleType === "count" ? "percentage" : "count")}
                  toggleGraphShowLabels={() => this.props.onGraphShowLabelsChanged(index, !query.graphShowLabels)}
                />
              </Col>
            );
          })
        }
      </Row>
    );
  }
}

export default PeopleCountYearOverYear;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  padding-bottom: 40px;
`;

// Set a fixed width for the graphs
// This is to prevent the graphs from being too small on small screens
// and show the graphs side by side on large screens
const Col = styled.div`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "50%" : "100%")};
  width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "50%" : "100%")};
  min-width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "508px" : "200px")};
  max-width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "calc(50% - 5px)" : "100%")};

  @media (max-width: 1600px) {
    flex-basis: 100%;
    width: 100%;
    min-width: 200px;
    max-width: 100%;
  }
`;