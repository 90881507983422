import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faFileDownload, faFileBinary, faCheck, faXmark, faEdit } from "@fortawesome/pro-duotone-svg-icons";
import styled from "styled-components";

export enum IconButtonType {
  COPY,
  DELETE,
  EDIT,
  DOWNLOAD,
  DOWNLOAD_BINARY
}

interface IconButtonProps {
  type: IconButtonType;
  onClick: () => void;
}

interface IconButtonState {
  active: boolean;
}

class IconButton extends React.Component<IconButtonProps, IconButtonState> {

  constructor(props: IconButtonProps) {
    super(props);
    this.state = {
      active: false
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({ active: true });
    this.props.onClick();
    setTimeout(() => {
      this.setState({ active: false });
    }, 2000);
  }

  render() {
    var icon: any;

    switch (this.props.type) {
      case IconButtonType.COPY:

        if (!this.state.active) {
          icon = faCopy;
        }
        else {
          icon = faCheck;
        }

        break;

      case IconButtonType.DOWNLOAD:

        if (!this.state.active) {
          icon = faFileDownload;
        }
        else {
          icon = faCheck;
        }
  
        break;

      case IconButtonType.DOWNLOAD_BINARY:

        if (!this.state.active) {
          icon = faFileBinary;
        }
        else {
          icon = faCheck;
        }
  
        break;

      case IconButtonType.DELETE:

        if (!this.state.active) {
          icon = faXmark;
        }
        else {
          icon = faCheck;
        }
  
        break;

      case IconButtonType.EDIT:

        if (!this.state.active) {
          icon = faEdit;
        }
        else {
          icon = faCheck;
        }
  
        break;

      default:
        break;
    }
  
    // #1C4D82
    return (
      <Container onClick={this.onClick} active={this.state.active}>
        <FontAwesomeIcon icon={icon} size="xl"/>
      </Container>
    );
  }
};

export default IconButton;

const Container = styled.div<{ active: boolean }>`
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border-radius: 5px;
  color: #333;

  // Show the "selected" for a while after clicking
  background-color: ${props => props.active && "#00000015"};
  color: ${props => props.active && "#111"};

  &:hover {
    background-color: #00000015;
    color: #111;
  }
`;