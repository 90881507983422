import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import moment from "moment";
import Modal from "../../components/Modal";
import TopRowOptions from "../../components/TopRowOptions";
import Table from "../../components/Table";
import * as screenActions from "../../actions/screens";

class PendingScreenList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedWaitingDevice: null,
    };

    this.onWaitingDeviceRowClick = this.onWaitingDeviceRowClick.bind(this);
    this.fetchWaitingDevices = this.fetchWaitingDevices.bind(this);
    this.onApproveWaitingDevice = this.onApproveWaitingDevice.bind(this);
  }

  componentDidMount() {
    this.fetchWaitingDevices();
  }

  onWaitingDeviceRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo !== undefined) {
          this.setState({ selectedWaitingDevice: rowInfo.original });
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  fetchWaitingDevices(state, instance) {
    this.props.getWaitingScreenDevices({ offset: 0, limit: 10, sortBy: "createdAt", sortOrder: "desc"});
  }
  
  onApproveWaitingDevice() {
    this.props.patchScreenDevice(this.state.selectedWaitingDevice.screenGroup.id, this.state.selectedWaitingDevice.id, { isApproved: true }, () => { 
      this.setState({ selectedWaitingDevice: null });
      this.props.getWaitingScreenDevices({ offset: 0, limit: 10, sortBy: "createdAt", sortOrder: "desc"});
    });
  }

  render() {
    // console.log("PendingScreenList.render", this.state);

    const deviceTable = getDeviceTable(this.props.waitingDevices, this.onWaitingDeviceRowClick, this.props.isLoading);

    const description = "These devices has been created by opening a Screen Config URL on a device. They need to be approved before they can be used.";

    const options = (
      <TopRowOptions
        description={description}
      />
    );

    return (
      <>
        { options }
        { deviceTable }
        <div style={{ paddingTop: "40px" }} />

        <Modal
          show={!isEmpty(this.state.selectedWaitingDevice)}
          onHide={() => this.setState({ selectedWaitingDevice: null })}
          title="Approve device"
          primaryBtn={{
            text: "Approve",
            onClick: this.onApproveWaitingDevice
          }}
          secondaryBtn={{
            text: "Cancel",
            color: "white",
            onClick: () => this.setState({ selectedWaitingDevice: null })
          }}>
          <p>
            <span>Do you want to approve the screen with code  </span>
            <span style={{ color: "#1C4D82", fontWeight: 800, fontFamily: "Courier" }}>{get(this.state.selectedWaitingDevice, "authCode", "unknown")}</span>
            <span> for the config named </span>
            <span style={{ color: "#1C4D82", fontWeight: 800 }}>{get(this.state.selectedWaitingDevice, "screenGroup.name", "unknown")}</span>
            <span>?</span>
          </p>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    waitingDevices: state.screens.waitingDevices,
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getWaitingScreenDevices: screenActions.getWaitingScreenDevices,
    patchScreenDevice: screenActions.patchScreenDevice,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingScreenList);

function getDeviceTable(data, onRowClick, isLoading) {
  return (
    <Table
      data={data}
      noDataText={isLoading ? "" : "No device needs approval"}
      columns={[
        {
          header: "Created",
          accessorKey: "createdAt",
          width: 140,
          cell: ({ row }) => {
            const date = `${moment(row.original.createdAt).format("DD/MM/YY HH:mm")}`;
            return <span title={date}>{date}</span>;
          }
        },
        {
          header: "Screen",
          accessorKey: "screenGroup",
          cell: ({ row }) => (<span title={row.original.screenGroup.name}>{row.original.screenGroup.name}</span>)
        },
        {
          header: "Code",
          accessorKey: "authCode",
          cell: ({ row }) => {
            // Set an mono-spaced font for the code
            return (<div><span style={{ color: "#1C4D82", fontWeight: 800, fontFamily: "Courier" }}>{row.original.authCode}</span></div>);
          }
        },
        {
          id: "arrow",
          accessorKey: "",
          sortable: false,
          className: "pull-right",
          width: 50,
          cell: ({ row }) => (<div className="arrow" />)
        }
      ]}
      getTdProps={onRowClick}
      className="-row-clickable setMaxHeigth -highlight"
      loading={isLoading}
    />
  );
}