import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import moment from "moment";
import UNITCODES from "../../../Sensor/constants";
import { StepSize, TimePeriod } from "../../../../components/Dashboard/Graph";
import GraphContainer from "../../../../components/Dashboard/Graph/container";
import SmallButton from "../../../../components/SmallButton";
import Table from "../../../../components/Table";
import ListCard from "../../../../components/ListCard";
import { getDayData, getMonthData, queryHash } from "../../../../dashboardHelpers";
import * as API from "../../../../ApiTypes";
import * as anomalyActions from "../../../../actions/anomalies";
import * as sensorActions from "../../../../actions/sensors";
import * as dashboardActions from "../../../../actions/dashboards";

class AnomalyDetailsInfo extends Component {

  constructor(props) {
    super(props);
    // console.log("AnomalyDetailsInfo.constructor", this.props);

    this.getSensorSamples = this.getSensorSamples.bind(this);

    let start = moment(props.anomaly.start);
    let end = moment(props.anomaly.end);

    // If anomaly is within same day, show day view
    if (start.isSame(end, "day")) {

      // Limit endDate to current date
      let newEnd = start.clone().endOf("day");
      if (newEnd.isAfter(moment())) {
        newEnd = moment().startOf("hour");
      }

      this.state = {
        timePeriod: props.anomaly.isAggregated ? TimePeriod.Day : TimePeriod.Dynamic, // TimePeriod.Day,
        startDate: start.startOf("day"),
        endDate: newEnd
      };
    }
    else {
      // Add 50% of the time between start and end to start and end
      const timeDiff = end.diff(start);
      const timeDiffHalf = Math.round(timeDiff / 2);
      let newStart = start.subtract(timeDiffHalf).startOf("day");
      let newEnd = end.add(timeDiffHalf).endOf("day");

      // Limit endDate to current date
      if (newEnd.isAfter(moment())) {
        newEnd = moment().startOf("hour");
      }

      this.state = {
        timePeriod: TimePeriod.Month,
        startDate: newStart,
        endDate: newEnd
      };
    }

    // Get samples if necessary
    this.getSensorSamples();
  }

  componentDidUpdate(prevProps, prevState) {
    // Update samples if necessary
    this.getSensorSamples();
  }

  getSensorSamples() {
    const sensorId = this.props.anomaly.sensorId;
    const atomicSensorId = this.props.anomaly.atomicSensorId;
    const locationId = get(this.props.anomaly, "locations[0].id", null);
    const property = this.props.anomaly.property;

    // Skip update if we don't have the necessary data
    if (isEmpty(this.props.locationHierarchy) || isEmpty(property)) {
      return;
    }

    // Fix startDate and endDate to reflect timePeriod
    let newStartDate = this.state.startDate.clone();
    let newEndDate = this.state.endDate.clone();

    // Set span for graph (undefined = raw data)
    let timeScale;
    if (this.state.timePeriod !== TimePeriod.Dynamic || newEndDate.diff(newStartDate, "hours") > 24) {
      if (this.state.timePeriod === TimePeriod.Day) {
        timeScale = "hour";
      }
      else {
        timeScale = "day";
      }
    }
    else if (this.props.anomaly.isAggregated) {
      timeScale = "hour";
    }

    // There are two types of anomaly sources:
    // 1. Aggregated data from a location (e.g. people count)
    // 2. Raw data from sensor (e.g. temperature)
    // 
    // Each of these are handled differently 
    // - aggregated data is loaded from the dashboard API and raw data is loaded from the sensor API

    if (this.props.anomaly.isAggregated) {

      // We re-use dashboard data models to load aggregated data
      const options = {
        date: newStartDate,
        timeScale,
        showWholeDay: true
      };

      const newProps = {
        location: { id: locationId },
        dataLoadingStatus: this.props.dataLoadingStatus,
        getDaySamples: this.props.getDaySamples,
        getMonthSamples: this.props.getMonthSamples,
      };

      const hash = queryHash(property, options, newProps);
      const hashKey = property + "-" + hash;

      // Only load data if source change
      if (hashKey === this.props.sensor.samplesQueryHash) {
        return;
      }

      // Get aggregated data for location (ignore core hours)
      switch (this.state.timePeriod) {
        case TimePeriod.Day:
          getDayData(true, [locationId], options, newProps, [property], null);
          break;
        case TimePeriod.Month:
          getMonthData(true, [locationId], options, newProps, [property], null);
          break;
        default:
          getDayData(true, [locationId], options, newProps, [property], null);
          break;
      }
    }
    else {
      const sensorSamplesQueryHash = `${sensorId}-${atomicSensorId}-${newStartDate}-${newEndDate}-${timeScale}`;
      
      // Only load data if source change
      if (sensorSamplesQueryHash === this.props.sensor.samplesQueryHash) {
        return;
      }

      this.props.getSensorSamples(sensorId, atomicSensorId, newStartDate, newEndDate, timeScale);
    }
  }

  getGraphData(propertyName, samples) {

    const commonCode = get(this.props.sensor.properties, `[${propertyName}].metadata.unitCode`, null);
    const unitCode = UNITCODES[commonCode];

    const graphData = [];
    for (let i = 0; i < samples.length; i++) {
      const sample = samples[i];

      let data = {};

      if (commonCode === "KEL" && propertyName === "temperature") {
        if (sample.value !== undefined) {
          data.value = Math.round((sample.value - 273.15) * 10) / 10;
        }
        else {
          data.max = Math.round((sample.max - 273.15) * 10) / 10;
          data.min = Math.round((sample.min - 273.15) * 10) / 10;
        }
        data.unit = "°C";
      }
      else {
        data.value = sample.value;
        data.max = sample.max;
        data.min = sample.min;
        data.unit = unitCode;
      }

      if (sample.sampledAt) {
        data.datetime = moment(sample.sampledAt).toISOString();
      }
      else {
        data.datetime = moment(sample.datetime).toISOString();
      }

      data.entityId = "test";
      graphData.push(data);
    }

    // Remove duplicate graphData samples (with same datetime)
    for (let i = 0; i < graphData.length - 1; i++) {
      if (graphData[i].datetime === graphData[i + 1].datetime) {
        graphData.splice(i, 1);
        i--;
      }
    }

    return graphData;
  }

  onUserRowClick = (column, rowInfo) => {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {
          console.log("AnomalyDetailsInfo.onUserRowClick", rowInfo);
          let link = `/companies/${this.props.match.params.companyId}/org/users/${rowInfo.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  render() {
    // console.log("AnomalyInfo.render2()", this.props);

    if (isEmpty(this.props.anomaly)) {
      return null;
    }

    // Fix startDate and endDate to reflect timePeriod
    let newStartDate = this.state.startDate.clone();
    let newEndDate = this.state.endDate.clone();

    // Set span for graph (undefined = raw data)
    let timeScale;
    if (this.state.timePeriod !== TimePeriod.Dynamic || newEndDate.diff(newStartDate, "hours") > 24) {
      if (this.state.timePeriod === TimePeriod.Day) {
        timeScale = "hour";
      }
      else {
        timeScale = "day";
      }
    }
    else if (this.props.anomaly.isAggregated) {
      timeScale = "hour";
    }

    const property = this.props.anomaly.property;

    let samples = [];
    if (this.props.anomaly.isAggregated) {
      const locationId = get(this.props.anomaly, "locations[0].id", null);

      // Skip update if we don't have the necessary data
      const options = {
        date: newStartDate,
        timeScale: timeScale,
        showWholeDay: true
      };

      const newProps = { location: { id: locationId } };

      const hash = queryHash(property, options, newProps);
      const hashKey = property + "-" + hash;

      // Only show graph if we have samples
      if (isEmpty(this.props.dataLoadingStatus[hashKey])) {
        return null;
      }

      samples = this.props.data[hashKey] ?? [];

    }
    else {
      const sensorSamplesQueryHash = `${this.props.anomaly.sensorId}-${this.props.anomaly.atomicSensorId}-${newStartDate}-${newEndDate}-${timeScale}`;

      // Only show graph if we have samples
      if (sensorSamplesQueryHash !== this.props.sensor.samplesQueryHash) {
        return null;
      }

      samples = JSON.parse(JSON.stringify(this.props.sensor.samples));
      samples = this.getGraphData(property, samples);
    }

    let stepSize = undefined; // undefined means that the step size is automatically calculated (does not support holes in time series)
    if (this.state.timePeriod === TimePeriod.Day) {
      stepSize = StepSize.Hour;
    }
    else if (this.state.timePeriod === TimePeriod.Month) {
      stepSize = StepSize.Day;
    }

    let userTableElement = null;

    if (this.props.anomaly.user && this.props.anomaly.user.id) {
      // User table with no header
      userTableElement = (
        <>
          <div style={{ paddingTop: "20px" }} />
          <h3>Last user action {this.props.anomaly.updatedAt ? `(${moment(this.props.anomaly.updatedAt).format("DD/MM/YY HH:mm")})` : ""}</h3>
          <ListCard>
            <Table
              data={[{ ...this.props.anomaly.user, state: this.props.anomaly.state }]}
              noDataText={""}
              columns={[
                {
                  id: "name",
                  header: "",
                  sortable: false,
                  accessorKey: "name",
                  style: { paddingLeft: "20px", marginRight: "10px" },
                  cell: ({ row }) => (<span title={row.name}>{row.name}</span>)
                },
                {
                  id: "state",
                  header: "",
                  sortable: false,
                  accessorKey: "state",
                  className: "pull-right",
                  cell: ({ row }) => (<span title={row.state}>{row.state}</span>)
                },
                {
                  id: "arrow",
                  header: "",
                  sortable: false,
                  className: "pull-right",
                  width: 60,
                  cell: ({ row }) => (<div className="arrow" />)
                }
              ]}
              getTdProps={this.onUserRowClick}
              hideHeaders
              className="-row-clickable -minimalist -no-header -highlight"
            />
          </ListCard>
        </>
      );
    }

    return (
      <>
        <div style={{ paddingTop: "20px" }} />
        <GraphContainer
          type={property}
          startDate={newStartDate.toDate()}
          endDate={newEndDate.toDate()}
          samples={samples}
          timePeriod={this.state.timePeriod}
          stepSize={stepSize}
          isLoading={this.props.isLoadingSamples}
          statusMessage={samples.length === 0 && !this.props.isLoadingSamples ? (newStartDate.isAfter(moment()) ? "Cannot look into the future (yet)" : "No data available") : undefined}
          enableSelection={false}
          selectionStartDate={new Date(this.props.anomaly.start)}
          selectionEndDate={new Date(this.props.anomaly.end)}
        />
        <p>This location has irregular data which might make your reports inaccurate if not adressed. You can either archive the message if you believe it to be benign, mark for attention if you want someone else to analyse it or delete the data in the period.</p>
        <SmallButton
          text="Archive message"
          disabled={this.props.anomaly.state === "ARCHIVED" || this.props.anomaly.state === "DELETED"}
          onClick={() => {
            this.props.updateAnomaly(this.props.match.params.anomalyId, { state: "ARCHIVED" });
          }}
          noLeftMargin
        />
        <SmallButton
          text="Mark for attention"
          disabled={this.props.anomaly.state === "ATTENTION_REQUIRED" || this.props.anomaly.state === "DELETED"}
          onClick={() => {
            this.props.updateAnomaly(this.props.match.params.anomalyId, { state: "ATTENTION_REQUIRED" });
          }}
          onlyLeftMargin
        />
        <SmallButton
          text="Delete data"
          color="red"
          disabled={this.props.anomaly.state === "MARKED_FOR_DELETION" || this.props.anomaly.state === "DELETED" || this.props.anomaly.isAggregated}
          onClick={() => {
            this.props.updateAnomaly(this.props.match.params.anomalyId, { state: "MARKED_FOR_DELETION" });
          }}
          onlyLeftMargin
        />

        {userTableElement}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    anomaly: state.anomaly,
    sensor: state.sensor,
    locationHierarchy: state.locations.hierarchy,
    dataLoadingStatus: state.dashboards.dataLoadingStatus,
    data: state.dashboards.data,
    isLoadingSamples: state.loading[API.GET_SAMPLES]
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateAnomaly: anomalyActions.updateAnomaly,
    getSensorSamples: sensorActions.getSensorSamples,
    getDaySamples: dashboardActions.getDaySamples,
    getMonthSamples: dashboardActions.getMonthSamples,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AnomalyDetailsInfo);
