import axios from "axios";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as selectionActions from "./selected";
import { API_URL, COOKIE_PREFIX, WS_URL } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getCompany = () => async (dispatch) => {
  dispatch(actions.requestData(types.GET_COMPANY_DETAILS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_COMPANY_DETAILS));
    })
    .catch((error) => {
      console.log("getCompany error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_COMPANY_DETAILS));
      }
    });
};

export const updateCompany = (body) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_COMPANY_DETAILS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_COMPANY_DETAILS));
      dispatch(getCompany());
    })
    .catch((error) => {
      console.log("updateCompany error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_COMPANY_DETAILS));
      }
    });
};

export const updateCompanyDraft = (draft) => async (dispatch) => {
  dispatch({ type: types.UPDATE_COMPANY_DETAILS_DRAFT, draft });
}

export const clearCompanyDraft = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_COMPANY_DETAILS_DRAFT });
}

export const getGraphResources = () => async (dispatch) => {
  dispatch(actions.requestData(types.GET_GRAPH_RESOURCES));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/graph-resources`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_GRAPH_RESOURCES));
    })
    .catch((error) => {
      console.log("getGraphResources error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_GRAPH_RESOURCES));
      }
    });
};

// export const getGraphResource = (id) => async (dispatch) => {
//   dispatch(actions.requestData(types.GET_GRAPH_RESOURCES));

//   const apiUrl = await API_URL();
//   const companyId = getCompanyId();
//   const cookiePrefix = await COOKIE_PREFIX();
//   const accessToken = cookies.get(`${cookiePrefix}_access_token`);

//   axios.get(`${apiUrl}companies/${companyId}/graph-resources/${id}`, await actions.getAxiosConfig())
//     .then((response) => {
//       dispatch(actions.receiveData(response.data, types.GET_GRAPH_RESOURCES));
//     })
//     .catch((error) => {
//       console.log("getGraphResources error", error);
//       if (error.response && error.response.status === 401) {
//         actions.sessionTokenExpired(cookiePrefix, accessToken);
//       } else {
//         dispatch(actions.receiveError(error, types.GET_GRAPH_RESOURCES));
//       }
//     });
// };

// export const updateGraphResources = (resourceSlots, redirect) => async (dispatch) => {
//   dispatch(actions.requestData(types.UPDATE_GRAPH_RESOURCES));

//   const apiUrl = await API_URL();
//   const companyId = getCompanyId();
//   const cookiePrefix = await COOKIE_PREFIX();
//   const accessToken = cookies.get(`${cookiePrefix}_access_token`);

//   axios.put(`${apiUrl}companies/${companyId}/graph-resources`, { resourceSlots }, await actions.getAxiosConfig())
//     .then((response) => {
//       dispatch(actions.receiveData(response.data, types.UPDATE_GRAPH_RESOURCES));
//       if (redirect) {
//         dispatch(getGraphResources());
//       }
//     })
//     .catch((error) => {
//       console.log("updateCompany error", error);
//       if (error.response && error.response.status === 401) {
//         actions.sessionTokenExpired(cookiePrefix, accessToken);
//       } else {
//         dispatch(actions.receiveError(error, types.UPDATE_GRAPH_RESOURCES));
//       }
//     });
// };

export const addGraphResource = (resourceSlot) => async (dispatch) => {
  dispatch(actions.requestData(types.CREATE_GRAPH_RESOURCE));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/graph-resources`, resourceSlot, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_GRAPH_RESOURCE));
      dispatch(getGraphResources());
    })
    .catch((error) => {
      console.log("addGraphResource error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_GRAPH_RESOURCE));
      }
    });
};

export const updateGraphResource = (resourceType, resourceSlot) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_GRAPH_RESOURCE));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  let body = { ...resourceSlot };
  delete body.resourceType;

  axios.put(`${apiUrl}companies/${companyId}/graph-resources/${resourceType}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_GRAPH_RESOURCE));
      dispatch(getGraphResources());
    })
    .catch((error) => {
      console.log("updateGraphResource error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_GRAPH_RESOURCE));
      }
    });
};

export const deleteGraphResource = (resourceType) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_GRAPH_RESOURCE));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/graph-resources/${resourceType}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_GRAPH_RESOURCE));
      dispatch(getGraphResources());
    })
    .catch((error) => {
      console.log("deleteGraphResource error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_GRAPH_RESOURCE));
      }
    });
};

export const getServiceAccounts = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SERVICE_ACCOUNTS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/service-accounts`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SERVICE_ACCOUNTS));
    })
    .catch((error) => {
      console.log("getGraphResources error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SERVICE_ACCOUNTS));
      }
    });
};

export const getServiceAccount = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SERVICE_ACCOUNT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/service-accounts/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SERVICE_ACCOUNT));
    })
    .catch((error) => {
      console.log("getGraphResources error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SERVICE_ACCOUNT));
      }
    });
};

export const createServiceAccount = (body, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.CREATE_SERVICE_ACCOUNT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/service-accounts`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_SERVICE_ACCOUNT));
      dispatch(getServiceAccounts());
      if (redirect) {
        redirect(`/companies/${companyId}/account/service-accounts`);
      }
    })
    .catch((error) => {
      console.log("createServiceAccount error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_SERVICE_ACCOUNT));
      }
    });
};

export const updateServiceAccount = (id, body, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_SERVICE_ACCOUNT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}/service-accounts/${id}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_SERVICE_ACCOUNT));
      // dispatch(getServiceAccounts());
      dispatch(getServiceAccounts());
      dispatch(getServiceAccount(id));
      dispatch(selectionActions.clearSelection());
      // if (redirect) {
      //   redirect(`/companies/${companyId}/account/service-accounts/${id}`);
      // }
    })
    .catch((error) => {
      console.log("createServiceAccount error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_SERVICE_ACCOUNT));
      }
    });
};

export const deleteServiceAccount = (id, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_SERVICE_ACCOUNT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/service-accounts/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_SERVICE_ACCOUNT));
      dispatch(getServiceAccounts());
      dispatch(clearServiceAccount());
      dispatch(clearServiceAccountDraft());
      if (redirect) {
        redirect(`/companies/${companyId}/account/service-accounts`);
      }
    })
    .catch((error) => {
      console.log("deleteServiceAccount error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_SERVICE_ACCOUNT));
      }
    });
};

export const updateServiceAccountDraft = (tag) => async (dispatch) => {
  dispatch({ type: types.UPDATE_SERVICE_ACCOUNT_DRAFT, draft: tag });
  dispatch(selectionActions.clearSelection());
}

export const clearServiceAccountDraft = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_SERVICE_ACCOUNT_DRAFT });
  dispatch(selectionActions.clearSelection());
}

export const clearServiceAccount = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_SERVICE_ACCOUNT });
}

export const getAccountSummary = () => async (dispatch) => {
  dispatch(actions.requestData(types.GET_ACCOUNT_SUMMARY));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/account-summary`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_ACCOUNT_SUMMARY));
    })
    .catch((error) => {
      console.log("getAccountSummary error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } 
      dispatch(actions.receiveError(error, types.GET_ACCOUNT_SUMMARY));
    });
}

export const forceReloadOfScreens = () => async (dispatch) => {

  // curl -v -XPOST --location 'https://ws.dev.bldng.ai/restart-screens'
  const wsAPI = await WS_URL();
  // const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${wsAPI}restart-screens`, {}, await actions.getAxiosConfig())
    .then((response) => {
      console.log("forceReloadOfScreens response", response);
      dispatch(actions.receiveData(response.data, types.FORCE_RELOAD_OF_SCREENS));
    })
    .catch((error) => {
      console.log("forceReloadOfScreens error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } 
      dispatch(actions.receiveError(error, types.FORCE_RELOAD_OF_SCREENS));
    });
}