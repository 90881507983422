import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get } from "lodash";
import InputModal from "../../components/InputModal";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import Table from "../../components/Table";
import SmallButton from "../../components/SmallButton";
import * as screenActions from "../../actions/screens";

class Screens extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 100,
      newScreenName: "",
      showNewScreenModal: false
    };
    this.onNewScreenClick = this.onNewScreenClick.bind(this);
    this.onNewScreenNameChanged = this.onNewScreenNameChanged.bind(this);
    this.onNewScreen = this.onNewScreen.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onHover = this.onHover.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onNewScreenClick() {
    this.setState({ showNewScreenModal: true });
  }

  onNewScreenNameChanged(event) {
    this.setState({ newScreenName: event.target.value });
  }

  onNewScreen() {
    const push = this.props.history.push;
    this.props.createScreen({ name: this.state.newScreenName, config: {} }, push);
    this.setState({ showNewScreenModal: false, newScreenName: "" });
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(value) {
    this.setState({ searchText: value === '' ? undefined : value });
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder, offset: 0 }, this.fetchData);
  }

  onOffsetChange(offset) {
    this.setState({ offset }, this.fetchData);
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        // console.log("onRowClick", e, rowInfo, column, instance);
        if (column.name !== 'isSelected' && rowInfo !== undefined) {

          const link = `/companies/${this.props.match.params.companyId}/screens/configs/${rowInfo.original.id}/edit`;
          this.props.resetScreenForm();

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        if (rowInfo && rowInfo.original.id) {
          this.onHover(rowInfo.original.id);
        }
      },
      onMouseLeave: e => this.onHover(null),
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.id === this.state.highlightedId ? "rgba(0,0,0,0.05)" : (rowInfo && rowInfo.original.id === this.props.configId ? "rgba(0,0,0,0.05)" : null))
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getScreens(queryParams);
  }

  render() {
    // console.log("Screens.render", this.state);

    const canCreateScreen = this.props.auth.hasAdminRole;

    const description = 'A "screen config" is an website with a configuration that can be displayed on multiple devices. To set up a new device either create a new screen config or reuse an exisiting one. When you have selected a config, click \"Copy map link\" and use that URL on your device. The device will then connect to bldng.ai and wait for approval on this page.';

    const options = (
      <TopRowOptions
        description={description}
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: "" }, this.onSearchClick)}
            inListView
          />
        )}
        buttons={[
          canCreateScreen && <SmallButton key="b1" text="New config" onClick={this.onNewScreenClick} singleLine noMargin />
        ]}
      />
    );

    const configTable = getConfigTable(this.props.data, this.state.sortBy, this.state.sortOrder, this.state.offset, this.state.limit, this.onSortedChange, this.onOffsetChange, this.onRowClick, this.props.isLoading);

    return (
      <>
        { options }
        { configTable }
        <div style={{ paddingTop: "40px" }} />

        <InputModal
          show={this.state.showNewScreenModal}
          onHide={() => this.setState({ showNewScreenModal: false })}
          title="Create screen config"
          // text=""
          inputLabel="Name"
          inputValid={this.state.newScreenName}
          inputValue={this.state.newScreenName}
          onInputChange={this.onNewScreenNameChanged}
          primaryBtn={{
            text: "Create",
            onClick: this.onNewScreen
          }}
          secondaryBtn={{
            text: "Close",
            onClick: () => this.setState({ showNewScreenModal: false })
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.screens.data,
    isLoading: state.loading.screens,
    selectedScreens: state.selected.screens,
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getScreens: screenActions.getScreens,
    createScreen: screenActions.createScreen,
    // selectScreen: screenActions.selectScreen,
    deselectScreen: screenActions.deselectScreen,
    resetScreenForm: screenActions.resetScreenForm,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Screens);

function getConfigTable(data, sortBy, sortOrder, offset, limit, onSortedChange, onOffsetChange, onRowClick, isLoading) {
  return (
    <Table
      data={data.configs}
      sortBy={sortBy}
      sortOrder={sortOrder}
      offset={offset}
      limit={limit}
      count={data.totalScreenConfigCount}
      onSortedChange={onSortedChange}
      onOffsetChange={onOffsetChange}
      noDataText={isLoading ? "" : "No screen config found"}
      columns={[
        {
          header: "Name",
          accessorKey: "name",
          cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
        },
        {
          header: "Devices",
          accessorKey: "screens",
          cell: ({ row }) => {
            if (get(row, "original.screens.length", 0) === 0) { 
              return (<span title="0">0</span>);
            }

            const approvedDevices = row.original.screens.filter(device => device.isApproved);
            const approvedActiveDevices = row.original.screens.filter(device => device.isApproved && !device.isDisabled);
            // const disabledDevices = row.original.devices.filter(device => device.isDisabled);
            // if (disabledDevices.length > 0) {
            //   return (<span title={approvedDevices.length}>{approvedDevices.length} ({disabledDevices.length} disabled)</span>);
            // }

            if (approvedDevices.length < row.original.screens.length) {
              return (
                <>
                  <span>{approvedActiveDevices.length} active </span>
                  <span style={{ color: "#666" }}>({row.original.screens.length - approvedDevices.length} waiting for approval)</span>
                </>
              );
            }

            return (<span title={approvedDevices.length}>{approvedDevices.length}</span>);
          }
        },
        {
          id: "arrow",
          header: "",
          accessorKey: 'properties',
          sortable: false,
          className: "pull-right",
          width: 60,
          cell: () => (<div className="arrow" />)
        }
      ]}
      getTdProps={onRowClick}
      className="-row-clickable setMaxHeigth -highlight"
      loading={isLoading}
    />
  );
}