import { toast } from "react-toastify";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import capitalize from "lodash/capitalize";
import { populateAncestors } from "../locationHelpers";
import * as types from "../ApiTypes";

const initialState = {
  buildings: [],
  buildingsTotal: {},
  inboundIntegrations: [],
  outboundIntegrations: [],
  unsortedBuildings: [],
  uglyBuildingsTotal: {},
  vortoIds: [],
  breadcrumbs: {},
  vendors: {},
};

const prettifyBuildingData = (building, vortoIds) => {
  
  // If building is empty, return empty sensors and gateways
  if (isEmpty(building)) {
    return { sensors: [], gateways: [] };
  }

  // Expand sensors with vorto data
  const sensors = building.sensors.map(sensor => {
    const vorto = vortoIds.find(vorto => vorto.id === sensor.model);
    const model = get(vorto, "model", sensor.model ?? "");
    const vendor = capitalize(get(vorto, "vendor", sensor.vendor ?? ""));
    return {
      ...sensor,
      model,
      vendor
    };
  });

  // Expand gateways with vorto data
  const gateways = building.gateways.map(gateway => {
    const vorto = vortoIds.find(vorto => vorto.id === gateway.model);
    const model = get(vorto, "model", gateway.model ?? "");
    const vendor = capitalize(get(vorto, "vendor", gateway.vendor ?? ""));
    return {
      ...gateway,
      model,
      vendor
    };
  });

  return {
    ...building,
    sensors,
    gateways
  };
};

const sortBuildingData = (building) => {
  building.sensors = building.sensors.sort((a, b) => {
    if (a.totalCount < b.totalCount) return 1;
    if (a.totalCount > b.totalCount) return -1;

    return 0;
  });

  building.gateways = building.gateways.sort((a, b) => {
    if (a.totalCount < b.totalCount) return 1;
    if (a.totalCount > b.totalCount) return -1;

    return 0;
  });

  return building;
};

const expandAndSortBuildingsData = (buildings, vortoIds, breadcrumbs) => {
  let expandedBuildings = buildings.map(building => prettifyBuildingData(building, vortoIds));

  // Expand buildings with locations without sensors or gateways
  let newBuildings = Object.keys(breadcrumbs).map(buildingId => {

    // Expand name with breadcrumb names
    const name = breadcrumbs[buildingId].map(breadcrumb => breadcrumb.name).join(", ");

    const foundBuilding = expandedBuildings.find(building => building.id === buildingId);
    if (foundBuilding) {
      return { id: buildingId, name, sensors: foundBuilding.sensors, gateways: foundBuilding.gateways };
    }

    return { id: buildingId, name, sensors: [], gateways: [] };
  });

  // Sort buildings by name and each building's sensors and gateways by totalCount
  newBuildings = newBuildings.sort((a, b) => {
    // If "n/a" is in the name, put it at the end
    if (a.name === "n/a") return 1;
    if (b.name === "n/a") return -1;

    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

    return 0;
  }).map(building => sortBuildingData(building));

  return newBuildings;
};

export default function accountSummeryReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_ACCOUNT_SUMMARY) {
        return state;
      }

      if (action.fetchType === types.GET_VORTO_IDS) {
        return {...state, vortoIds: [] };
      }

      if (action.fetchType === types.GET_LOCATION_HIERARCHY) {
        return {...state, breadcrumbs: {} };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_ACCOUNT_SUMMARY) {

        let unsortedBuildings = get(action, "payload.buildings", []);
        let uglyBuildingsTotal = get(action, "payload.total", {});

        // Find all vendors
        let vendors = {};
        unsortedBuildings.forEach(building => {
          building.sensors.forEach(sensor => {
            vendors[sensor.vendor] = true;
          });
          building.gateways.forEach(gateway => {
            vendors[gateway.vendor] = true;
          });
        });

        // If we have vortoIds and breadcrumbs, expand and sort the buildings
        if (!isEmpty(state.vortoIds) && !isEmpty(state.breadcrumbs)) {
          let buildings = expandAndSortBuildingsData(unsortedBuildings, state.vortoIds, state.breadcrumbs);
          let buildingsTotal = sortBuildingData(prettifyBuildingData(uglyBuildingsTotal, state.vortoIds));
          return {
            ...state,
            buildings,
            unsortedBuildings,
            buildingsTotal,
            uglyBuildingsTotal,
            inboundIntegrations: get(action, "payload.inboundIntegrations", []),
            outboundIntegrations: get(action, "payload.outboundIntegrations", []),
            vendors
          }
        }

        return {
          ...state,
          unsortedBuildings,
          uglyBuildingsTotal,
          inboundIntegrations: get(action, "payload.inboundIntegrations", []),
          outboundIntegrations: get(action, "payload.outboundIntegrations", []),
          vendors
        }
      }

      if (action.fetchType === types.GET_VORTO_IDS) {
        let vortoIds = action.payload;

        if (!isEmpty(state.unsortedBuildings) && !isEmpty(state.breadcrumbs)) {
          let buildings = expandAndSortBuildingsData(state.unsortedBuildings, vortoIds, state.breadcrumbs);
          let buildingsTotal = sortBuildingData(prettifyBuildingData(state.uglyBuildingsTotal, state.vortoIds));
          return {...state, vortoIds, buildings, buildingsTotal };
        }

        return {...state, vortoIds };
      }

      if (action.fetchType === types.GET_LOCATION_HIERARCHY) {

        // Add breadcrumbs to locations
        let breadcrumbs = {};
        if (action.payload.length > 0) {

          populateAncestors(action.payload, [], breadcrumbs);

          // Remove all locations that are not buildings
          Object.keys(breadcrumbs).forEach(locationId => {
            // Check if the last breadcrumb is a building
            const crumbs = breadcrumbs[locationId];
            if (crumbs.length > 0 && crumbs[crumbs.length - 1].type !== "building") {
              delete breadcrumbs[locationId];
            }
          });
        }

        if (!isEmpty(state.unsortedBuildings) && !isEmpty(state.vortoIds)) {
          let buildings = expandAndSortBuildingsData(state.unsortedBuildings, state.vortoIds, breadcrumbs);
          let buildingsTotal = sortBuildingData(prettifyBuildingData(state.uglyBuildingsTotal, state.vortoIds));
          return {...state, breadcrumbs, buildings, buildingsTotal };
        }

        return {...state, breadcrumbs };
      }

      if (action.fetchType === types.FORCE_RELOAD_OF_SCREENS) {
        toast.success("Screens reloaded");
        return state;
      } 

      return state;
    }

    case types.RECV_ERROR: {
      if (action.fetchType === types.GET_ACCOUNT_SUMMARY) {
        toast.error("Could not get account summary");
        return {...state, buildings: [], exportConfigs: [] };
      }

      if (action.fetchType === types.GET_VORTO_IDS) {
        return {...state, vortoIds: [] };
      }

      if (action.fetchType === types.GET_LOCATION_HIERARCHY) {
        return {...state, breadcrumbs: {} };
      }

      if (action.fetchType === types.FORCE_RELOAD_OF_SCREENS) {
        toast.error("Could not reload screens");
        return state;
      } 

      return state;
    }

    default: {
      return state;
    }
  }
}
