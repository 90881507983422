import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route } from "react-router-dom";
import { Hidden, Visible } from "react-grid-system";
import { get } from "lodash";
import InputBox from "../../components/InputBox";
import InputPointMap from "../Map/InputPointMap";
import NotFound from "../NotFound";
import ScreenConfigDetails from "./screenConfigDetails";
import ScreenConfigOverlay from "./screenConfigOverlay";
import ScreenConfigLayout from "./screenConfigLayout";
import ScreenConfigLayoutDetails from "./screenConfigLayoutDetails";
import * as screenActions from "../../actions/screens";
import * as locationActions from "../../actions/locations";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";
import styled from "styled-components";

class ScreenConfigContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mapMode: "simple_select",
      aspectRatioString: "16:9",
      aspectRatio: 16/9,
      width: 0,
      height: 0,
      layoutSelectionPath: [],
      showAddView: false
    }

    this.containerRef = React.createRef(); // Creating a ref for the container

    this.onUpdateLayoutSelectionPath = this.onUpdateLayoutSelectionPath.bind(this);
    this.onInitialLocationBoundsChanged = this.onInitialLocationBoundsChanged.bind(this);
    this.onCopyInitialLocation = this.onCopyInitialLocation.bind(this);
    this.onYourLocationPointChanged = this.onYourLocationPointChanged.bind(this);
    this.onClearYourLocation = this.onClearYourLocation.bind(this);
    this.onMapModeChange = this.onMapModeChange.bind(this);
    this.onAspectRatioChange = this.onAspectRatioChange.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.onAddLayoutElementClicked = this.onAddLayoutElementClicked.bind(this);
    this.onShowAddView = this.onShowAddView.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onUpdateLayoutSelectionPath(path) {
    this.setState({ layoutSelectionPath: path, showAddView: false });
  }

  updateDimensions() {
    const container = this.containerRef.current;
    if (!container) return;

    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;

    const { aspectRatio } = this.state;
    let height = containerHeight;
    let width = containerHeight * aspectRatio;

    if (width > containerWidth) {
      width = containerWidth;
      height = width / aspectRatio;
    }

    this.setState({ width, height });
  }

  onAspectRatioChange(event) {

    const container = this.containerRef.current;
    if (!container) return;

    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;

    const newAspectRatio = event.target.value;

    let aspectRatio = newAspectRatio.replaceAll(":", "/").replaceAll(" ", "");
    const splitAspectRatio = aspectRatio.split("/");
    if (splitAspectRatio.length !== 2 || isNaN(splitAspectRatio[0]) || isNaN(splitAspectRatio[1])) {
      aspectRatio = 16/9;
    }
    else {
      aspectRatio = splitAspectRatio[0] / splitAspectRatio[1];
    }

    let height = containerHeight;
    let width = containerHeight * aspectRatio;

    if (width > containerWidth) {
      width = containerWidth;
      height = width / aspectRatio;
    }

    this.setState({ aspectRatio, aspectRatioString: newAspectRatio, width, height });
  }

  onInitialLocationBoundsChanged(ne, sw) {
    this.setState({ initialLocation: { ne, sw } });
  }

  onCopyInitialLocation() {
    const form = { ...this.props.form };
    form.config.initialLocation = this.state.initialLocation;
    this.props.updateScreenForm(form);
  }

  onYourLocationPointChanged(point) {
    const lat = get(point, "geometry.coordinates[1]", 0);
    const lng = get(point, "geometry.coordinates[0]", 0);

    if (lat && lng) {
      const form = { ...this.props.form };
    
      if (!form.config.yourLocation) {
        form.config.yourLocation = {};
      }

      form.config.yourLocation.lat = lat;
      form.config.yourLocation.lng = lng;
      this.props.updateScreenForm(form);
    }
  }

  onClearYourLocation() {
    const form = { ...this.props.form };
    form.config.yourLocation = null;
    this.props.updateScreenForm(form);
    this.onMapModeChange("simple_select");
  }

  onMapModeChange(mode) {
    this.setState({ mapMode: mode });
  }

  onShowAddView() {
    this.setState({ showAddView: true });
  }

  onAddLayoutElementClicked(path, element) {
    // console.log("Add layout element clicked", path, element);

    // Get the selected object from the selectionPath
    let view = null;
    try {
      view = JSON.parse(get(this.props.form, "config.view", ""));
    }
    catch (error) {}

    // If the view is empty, create a new one
    if (view === null) {
      view = element;

      // Update the form with the new view
      const form = { ...this.props.form };
      form.config.view = JSON.stringify(view, null, 2);
      this.props.updateScreenForm(form);
 
      // Update the layout selection path
      this.setState({ layoutSelectionPath: ["children"], showAddView: false });
      return;
    }

    let pathToContainer = null;
    let container = null;
    let indexOfSelectedChild = -2; // -1 means before the first child, -2 means unknown
    for (let i = 0; i < path.length - 1; i++) {
      const apath = path.slice(0, i);

      let viewElement;
      if (apath.length === 0) {
        viewElement = view;
      }
      else {
        viewElement = get(view, apath.join("."), null);
      }

      if (viewElement && viewElement.class === "container") {
        if (path[i] === "children" && typeof path[i + 1] === "number") {
          container = viewElement;
          pathToContainer = apath;
          indexOfSelectedChild = path[i + 1];
        }
      }
    }

    // If we found a container, add the element to the children array
    if (container) {

      let indexToNewElement = 0;
      if (container.children === undefined) {
        container.children = [];
      }

      if (indexOfSelectedChild > -2) {
        container.children.splice(indexOfSelectedChild + 1, 0, element);
        indexToNewElement = indexOfSelectedChild + 1;
      }
      else {
        container.children.push(element);
        indexToNewElement = container.children.length - 1;
      }

      // Update the form with the new view
      const form = { ...this.props.form };
      form.config.view = JSON.stringify(view, null, 2);
      this.props.updateScreenForm(form);

      // Update the layout selection path
      this.setState({ layoutSelectionPath: [...pathToContainer, "children", indexToNewElement], showAddView: false });
    }
  }

  render() {
    // Get yourLocation to render in map
    var yourLocationFeature = null;
    const yourLocationLat = get(this.props.form, "config.yourLocation.lat", 0);
    const yourLocationLng = get(this.props.form, "config.yourLocation.lng", 0);
    if (yourLocationLat && yourLocationLng) {
      yourLocationFeature = { 
        type: "Feature",
        geometry: { 
          type: "Point", 
          coordinates: [yourLocationLng, yourLocationLat] 
        }
      };
    }

    // Only show the map if we are in the details view
    let rightSide = null;
    if (this.props.match.path.endsWith("/details")) {
      rightSide = (
        <>
          <MapContainerOptions>
            <InputTitle>Display aspect ratio</InputTitle>
            <InputBox value={this.state.aspectRatioString} placeholder="ex. 16:9" onChange={this.onAspectRatioChange} style={{ maxWidth: "120px" }} />
          </MapContainerOptions>
          <CenteredWrapper ref={this.containerRef}>
            <SizeWrapper width={this.state.width} height={this.state.height}>
              <MapContainer>
                { this.props.location.floorMap && this.state.aspectRatio && this.state.width && this.state.height > 200 && (
                  <InputPointMap
                    key={this.state.aspectRatioString + get(this.props.form, "config.initialBearing", 0) + get(this.props.form, "config.initialPitch", 0)}
                    company={this.props.selectedCompany}
                    location={this.props.location}
                    map={this.props.location.floorMap}
                    mapMode={this.state.mapMode}
                    point={yourLocationFeature}
                    initialLocation={get(this.props.form, "config.initialLocation", null)}
                    onPointChanged={this.onYourLocationPointChanged}
                    onBoundsChanged={this.onInitialLocationBoundsChanged}
                    aspectRatio={this.state.aspectRatio}
                    bearing={get(this.props.form, "config.initialBearing", 0)}
                    pitch={get(this.props.form, "config.initialPitch", 0)}
                  />
                )}
              </MapContainer>
            </SizeWrapper>
          </CenteredWrapper>
        </>
      )
    }
    else if (this.props.match.path.endsWith("/layout")) {
      rightSide = (
        <div className={style.scroll}>
          <ScreenConfigLayoutDetails
            form={this.props.form}
            selectionPath={this.state.layoutSelectionPath}
            showAddView={this.state.showAddView}
            auth={this.props.auth}
            onAddClicked={this.onAddLayoutElementClicked}
          />
        </div>
      );
    }
    
    return (
      <>
        <Hidden xs sm md>
          <div className={style.singleView}>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <Switch>
                    <Route path="/companies/:companyId/screens/configs/:configId/edit/details" children={(props) => <ScreenConfigDetails {...props} configId={this.props.match.params.configId} mapMode={this.state.mapMode} onMapModeChange={this.onMapModeChange} onClearYourLocation={this.onClearYourLocation} onCopyInitialLocation={this.onCopyInitialLocation}/>} />
                    <Route path="/companies/:companyId/screens/configs/:configId/edit/overlay" children={(props) => <ScreenConfigOverlay {...props} configId={this.props.match.params.configId}  />} />
                    <Route path="/companies/:companyId/screens/configs/:configId/edit/layout" children={(props) => <ScreenConfigLayout {...props} configId={this.props.match.params.configId} onShowAddView={this.onShowAddView} onUpdateLayoutSelectionPath={this.onUpdateLayoutSelectionPath} />} />
                    <Route render={NotFound} />
                  </Switch>
                </div>
              </div>
              <div className={style.sideBar}>
                { rightSide }
              </div>
            </div>
          </div>
        </Hidden>

        <Visible xs sm md>
          <div className={style.singleView}>
            <div className={style.slimScroll}>
              <Switch>
                <Route path="/companies/:companyId/screens/configs/:configId/edit/details" children={(props) => <ScreenConfigDetails {...props} configId={this.props.match.params.configId} />} />
                <Route path="/companies/:companyId/screens/configs/:configId/edit/overlay" children={(props) => <ScreenConfigOverlay {...props} configId={this.props.match.params.configId} />} />
                <Route path="/companies/:companyId/screens/configs/:configId/edit/layout" children={(props) => <ScreenConfigLayout {...props} configId={this.props.match.params.configId} onShowAddView={this.onShowAddView} onUpdateLayoutSelectionPath={this.onUpdateLayoutSelectionPath} />} />
                <Route render={NotFound} />
              </Switch>
            </div>
          </div>
        </Visible>
      </>
    );
  }

}

function mapStateToProps(state) {
  return {
    screen: state.screen,
    form: state.screen.form,
    isLoading: state.loading.screen,
    isLoadingLocation: state.loading.location,
    isLoadingMap: state.loading[API.GET_FLOOR_MAP],
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
    user: state.auth.user,
    location: state.location
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateScreenForm: screenActions.updateScreenForm,
    resetScreenForm: screenActions.resetScreenForm,
    getFloorMap: locationActions.getFloorMap
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenConfigContainer);

const MapContainerOptions = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  background-color: white;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  box-sizing: border-box;

  box-shadow: inset 0 -1px 0 0 #dbdbdb;
`;

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 56px); // Adjust for any offsets
  width: 100%;
  overflow: hidden;
  background-color: #555;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const SizeWrapper = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const InputTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  margin-right: 10px;
  margin-left: 10px;
`;