import React, { Component } from "react";
import { Container, Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import Freetext from "../../components/Freetext";
import InputGroup from "../../components/InputGroup";
import OptionFooter from "../../components/OptionFooter";
import Table from "../../components/Table";
import * as sensorTagActions from "../../actions/sensorTags";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class SensorTag extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      name: "",
      description: "",
      hardCapacity: undefined,
      sensors: [],
      hasLoadedState: false
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onHardCapacityChange = this.onHardCapacityChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    
    if (prevState.hasLoadedState) {
      return null;
    }

    // Only copy props if they exist and if sensor id is correct
    
      if (nextProps.match.params.id === undefined) {

        const newSelectedSensors = Object.keys(nextProps.selectedSensors).map((sensorId) => ({ ...nextProps.selectedSensors[sensorId], selected: true }));
  
        const newState = {
          ...prevState,
          name: "",
          description: "",
          hardCapacity: undefined,
          sensors: newSelectedSensors,
          hasLoadedState: true
        };
        
        return newState;
      }
      else if (nextProps.tag._id === nextProps.match.params.id) {
  
        const newSelectedSensors = Object.keys(nextProps.selectedSensors).map((sensorId) => ({ ...nextProps.selectedSensors[sensorId], selected: false }));
        const existingSensors = nextProps.tag.sensors.map((sensor) => ({ ...sensor, selected: true, id: sensor._id }));
  
        const newState = {
          ...prevState,
          name: nextProps.tag.name,
          description: nextProps.tag.description,
          hardCapacity: get(nextProps, "tag.capacity.hard", undefined),
          sensors: existingSensors.concat(newSelectedSensors),
          hasLoadedState: true
        };
        
        return newState;
      }
    // }

    return null;
  }

  componentDidMount() {
    if (this.props.tag._id !== this.props.match.params.id) {
      this.props.getSensorTag(this.props.match.params.id);
    }
  }

  onBackClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/installation/sensor-tags`);
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  onHardCapacityChange(event) {
    const hardCapacity = parseInt(event.target.value);
    this.setState({ hardCapacity });
  }

  onToggle(row) {
    this.setState((prevState) => { 
      const sensor = prevState.sensors.find((sensor) => (sensor.id === row.id));
      if (sensor) {
        if (sensor.selected === undefined || sensor.selected) {
          sensor.selected = false;
        }
        else {
          sensor.selected = true;
        }
      }

      return prevState;
    });
  }

  onSave() {
    const { push } = this.props.history;

    const sensors = this.state.sensors.filter((sensor) => (sensor.selected));
    const sensorIds = sensors.map((sensor) => (sensor.id));

    // Add capacity
    var capacity;
    if (this.state.hardCapacity === null || Object.is(this.state.hardCapacity, NaN)) {
      capacity = null;
    }
    else if (this.state.hardCapacity !== undefined) {
      capacity = { hard: this.state.hardCapacity };
    }

    if (this.state.id) {
      const body = {
        name: this.state.name,
        description: this.state.description,
        sensorIds,
        capacity
       };
       
      this.props.updateSensorTag(this.state.id, body, push);
    }
    else {
      const body = {
        name: this.state.name,
        description: this.state.description,
        sensorIds,
        capacity
       };
  
      this.props.createSensorTag(body, push);
    }

    
  }

  onCancel() {
    this.onBackClick();
  }

  render() {
    let headerRow = (
        <Row className={style.topRow}>
          <Col md={9}>
            <div>
              <Freetext header={this.state.id ? "Edit sensor tag" : "New sensor tag" } content="" />
            </div>
          </Col>
        </Row>
      );

    const locationsTable = (
      <Table
        data={this.state.sensors}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.id}`}>
                <input
                  id={`editCheckbox-${row.id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={get(row.original, "selected", true)}
                  onChange={() => this.onToggle(row)}
                  disabled={false}
                />
                <span className="checkmark" />
              </label>
            ),
            width: 60
          },
          { 
            header: "Name", 
            accessorKey: "name", 
          }
          ]}
        className="setMaxHeigth"
      />
    );

    const selectedSensors = this.state.sensors.filter((sensor => sensor.selected));

    return (
      <div className={style.outerEditScroll}>
        <Container className={style.editContainer}>
          { headerRow }
          <Row>
            <Col sm={12} md={8} lg={8}>
              <InputGroup autoFocus onChange={this.onNameChange} label="Name" placeholder="Sensor tag name" valid={this.state.name} value={this.state.name} required />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={8} lg={8}>
              <InputGroup onChange={this.onDescriptionChange} label="Description" placeholder="Sensor tag description" valid={this.state.description} value={this.state.description} required />
            </Col>
          </Row>
          <div style={{ paddingTop: "20px" }} />
          <Row>
            <Col sm={12} md={3} lg={3}>
              <InputGroup type="number" onChange={this.onHardCapacityChange} label="People capacity" valid={this.state.hardCapacity} value={this.state.hardCapacity} />
            </Col>
          </Row>
          <div style={{ paddingTop: "40px" }} />
          <Row>
            <Col sm={12} md={12} lg={12}>
              { locationsTable }
            </Col>
          </Row>
          <div style={{ paddingTop: "80px" }} />
        </Container>
        <OptionFooter 
          cancel={this.onCancel} 
          cancelButtonLabel="Cancel"
          options={[
            { 
              label: "Save", 
              callback: this.onSave, 
              disabled: this.state.name.length === 0 || this.state.description.length === 0 || selectedSensors.length < 2
            }
          ]} 
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tag: state.sensorTags.tag,
    selectedSensors: state.selected.sensors
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getSensorTag: sensorTagActions.getSensorTag,
    createSensorTag: sensorTagActions.createSensorTag,
    updateSensorTag: sensorTagActions.updateSensorTag,
    selectSensorTag: selectedActions.selectSensorTag,
    deselectSensorTag: selectedActions.deselectSensorTag
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(SensorTag);
