import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navigation from "./Navigation";
import Main from "./Main";
import SelectionFooter from "./SelectionFooter/selectionFooter";
import style from "./style.module.scss";

const App = () => (
  <div className={style.app}>
    <Navigation title="BLDNG.ai" />
    <Main />
    <SelectionFooter />
    <ToastContainer 
      hideProgressBar
      draggable={false}
      theme="colored"
      // eslint-disable-next-line react/jsx-boolean-value
      pauseOnVisibilityChange={true}
    />
  </div>
);

export default App;