import * as types from "../ApiTypes";
import { getCompanyId } from "../helpers";

export const selectLocation = (location) => (dispatch) => {
  dispatch({ type: types.SELECT_LOCATION, payload: location });
}

export const deselectLocation = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_LOCATION, payload: id });
}

export const selectLocations = (locations) => (dispatch) => {
  dispatch({ type: types.SELECT_LOCATIONS, payload: locations });
}

export const deselectLocations = (locations) => (dispatch) => {
  dispatch({ type: types.DESELECT_LOCATIONS, payload: locations });
}

export const deselectAllLocations = () => (dispatch) => {
  dispatch({ type: types.DESELECT_ALL_LOCATIONS });
}

export const selectSensorWithRedirect = (sensor, redirect, locationId) => (dispatch) => {
  dispatch({ type: types.CLEAR_SELECTION });
  dispatch({ type: types.SELECT_SENSOR, payload: sensor });
  const companyId = getCompanyId();
  if (redirect) {
    if (locationId) {
      redirect(`/companies/${companyId}/locations/${locationId}/locations`);
    }
    else {
      redirect(`/companies/${companyId}/locations/root/locations`);
    }
  }
}

export const selectSensor = (sensor) => (dispatch) => {
  dispatch({ type: types.SELECT_SENSOR, payload: sensor });
}

export const deselectSensor = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_SENSOR, payload: id });
}

export const selectSensors = (sensors) => (dispatch) => {
  dispatch({ type: types.SELECT_SENSORS, payload: sensors });
}

export const deselectSensors = (sensors) => (dispatch) => {
  dispatch({ type: types.DESELECT_SENSORS, payload: sensors });
}

export const selectAtomicSensor = (sensor) => (dispatch) => {
  dispatch({ type: types.SELECT_ATOMIC_SENSOR, payload: sensor });
}

export const deselectAtomicSensor = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_ATOMIC_SENSOR, payload: id });
}

export const selectAllAtomicSensors = (sensors) => (dispatch) => {
  dispatch({ type: types.SELECT_ALL_ATOMIC_SENSORS, payload: sensors });
}

export const deselectAllAtomicSensors = () => (dispatch) => {
  dispatch({ type: types.DESELECT_ALL_ATOMIC_SENSORS });
}

export const selectGateway = (gateway, redirect, locationId) => (dispatch) => {
  dispatch({ type: types.CLEAR_SELECTION });
  dispatch({ type: types.SELECT_GATEWAY, payload: gateway });
  const companyId = getCompanyId();
  if (redirect) {
    if (locationId) {
      redirect(`/companies/${companyId}/locations/${locationId}/locations`);
    }
    else {
      redirect(`/companies/${companyId}/locations/root/locations`);
    }
  }
}

export const deselectGateway = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_GATEWAY, payload: id });
}

export const selectGateways = (gateways) => (dispatch) => {
  dispatch({ type: types.SELECT_GATEWAYS, payload: gateways });
}

export const deselectGateways = (gateways) => (dispatch) => {
  dispatch({ type: types.DESELECT_GATEWAYS, payload: gateways });
}

export const selectLocationTag = (tag) => (dispatch) => {
  dispatch({ type: types.SELECT_LOCATION_TAG, payload: tag });
}

export const deselectLocationTag = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_LOCATION_TAG, payload: id });
}

export const selectSensorTag = (tag) => (dispatch) => {
  dispatch({ type: types.SELECT_SENSOR_TAG, payload: tag });
}

export const deselectSensorTag = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_SENSOR_TAG, payload: id });
}

export const selectDisplay = (display) => (dispatch) => {
  dispatch({ type: types.SELECT_DISPLAY, payload: display });
}

export const deselectDisplay = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_DISPLAY, payload: id });
}

export const selectScreen = (display) => (dispatch) => {
  dispatch({ type: types.SELECT_SCREEN, payload: display });
}

export const deselectScreen = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_SCREEN, payload: id });
}

export const selectReport = (report) => (dispatch) => {
  dispatch({ type: types.SELECT_REPORT, payload: report });
}

export const deselectReport = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_REPORT, payload: id });
}

export const selectCompany = (company) => (dispatch) => {
  dispatch({ type: types.SELECT_COMPANY, payload: company });
}

export const deselectCompany = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_COMPANY, payload: id });
}

export const deselectAll = () => (dispatch) => {
  dispatch({ type: types.DESELECT_ALL });
}

export const clearSelection = () => (dispatch) => {
  dispatch({ type: types.CLEAR_SELECTION });
}

export const selectMapFeature = (feature) => (dispatch) => {
  dispatch({ type: types.SELECT_MAP_FEATURE, payload: feature });
}

export const deselectMapFeature = () => (dispatch) => {
  dispatch({ type: types.DESELECT_MAP_FEATURE });
}

export const setSelectedFeatures = (features, mode) => (dispatch) => {
  if (mode) {
    dispatch({ type: types.SET_SELECTED_FEATURES, payload: { features, mode } });
  }
  else {
    dispatch({ type: types.SET_SELECTED_FEATURES, payload: { features } });
  }
}

export const changeConfigMode = (mode) => (dispatch) => {
  dispatch({ type: types.CHANGE_CONFIG_MODE, payload: mode });
}

export const changeEditMode = (mode) => (dispatch) => {
  dispatch({ type: types.CHANGE_EDIT_MODE, payload: mode });
}

export const setMapMode = (mode) => (dispatch) => {
  dispatch({ type: types.SET_MAP_MODE, payload: mode });
}

export const storeEditedFeatures = (features) => (dispatch) => {
  dispatch({ type: types.STORE_EDITED_FEATURES, payload: features });
};

export const addCreatedFeature = (feature) => (dispatch) => {
  dispatch({ type: types.ADD_CREATED_FEATURE, payload: feature });
};

export const setHoveredFeature = (feature) => (dispatch) => {
  dispatch({ type: types.SET_HOVERED_FEATURE, payload: feature });
};

export const selectContent = (content, redirect, redirectUrl) => (dispatch) => {
  dispatch({ type: types.SELECT_CONTENT, payload: content });
  if (redirect && redirectUrl) {
    redirect(redirectUrl);
  }
};

export const deselectContent = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_CONTENT, payload: id });
};

export const selectUserGroup = (userGroup, redirect, redirectUrl) => (dispatch) => {
  dispatch({ type: types.SELECT_USER_GROUP, payload: userGroup });
  if (redirect && redirectUrl) {
    redirect(redirectUrl);
  }
};

export const deselectUserGroup = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_USER_GROUP, payload: id });
};

export const selectUser = (user) => (dispatch) => {
  dispatch({ type: types.SELECT_USER, payload: user });
};

export const deselectUser = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_USER, payload: id });
};

export const selectInvite = (user) => (dispatch) => {
  dispatch({ type: types.SELECT_INVITE, payload: user });
};

export const deselectInvite = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_INVITE, payload: id });
};

export const selectCustomTag = (tag) => (dispatch) => {
  dispatch({ type: types.SELECT_CUSTOM_TAG, payload: tag });
}

export const deselectCustomTag = (id) => (dispatch) => {
  dispatch({ type: types.DESELECT_CUSTOM_TAG, payload: id });
}

export const selectUnregisteredSensors = (sensors) => (dispatch) => {
  dispatch({ type: types.SELECT_UNREGISTERED_SENSORS, payload: sensors });
}

export const deselectUnregisteredSensors = (ids) => (dispatch) => {
  dispatch({ type: types.DESELECT_UNREGISTERED_SENSORS, payload: ids });
}