import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get } from "lodash";
import Freetext from "../../components/Freetext";
import Loader from "../../components/Loader";
import TextBox from "../../components/TextBox";
import * as screenActions from "../../actions/screens";

class ScreenConfigOverlay extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasLoadedState: false
    };

    this.onOverlayUIChanged = this.onOverlayUIChanged.bind(this);
  }

  onOverlayUIChanged(event) {
    const form = { ...this.props.form };
    if (!form.config) { 
      form.config = {};
    }
    form.config.ui = event.target.value;
    this.props.updateScreenForm(form);
  }

  render() {
    // console.log("Screen.render", this.state);

    const canEdit = this.props.auth.hasSupportRole;

    if (!canEdit) {
      return null;
    }

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    // Validate overlay UI
    let overlayUIValid = false;
    if (get(this.props, "form.config.ui", false)) {
      try {
        JSON.parse(this.props.form.config.ui);
        overlayUIValid = true;
      }
      catch (e) {
        overlayUIValid = false;
      }
    }
    else {
      overlayUIValid = true;
    }

    return (
      <>
        <div style={{ paddingTop: "25px" }} />
        <Freetext header={`Add overlay UI to the map (deprecated)`} content={`Overlay UI is anchored in each corner of the map and can contain multiple UI components. Check uiTypes.ts in screen-client for å full description.`} />
        <TextBox 
          label="JSON" 
          value={get(this.props.form, "config.ui", "")} 
          valid={overlayUIValid} 
          onChange={this.onOverlayUIChanged}
          required 
          showValidIcon
          disabled={!canEdit} 
          style={{ maxWidth: "600px" }}
        />
        <div style={{ paddingTop: "40px" }} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    screen: state.screen,
    form: state.screen.form,
    isLoading: state.loading.screen,
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    updateScreenForm: screenActions.updateScreenForm,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenConfigOverlay);