import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import ScreenList from "./screenList";
import style from "./style.module.scss";

class ScreensContainer extends Component {
  
  componentDidMount() {
    document.title = `BLDNG.ai - Screens`;
  }

  render() {
    const hasSelection = !isEmpty(this.props.selectedLocations) || !isEmpty(this.props.selectedUserGroups) || !isEmpty(this.props.selectedContent);
    return (
      <>
        <Hidden xs sm md lg>
          <div className={ hasSelection ? style.mainContainer : style.mainContainerHidden }>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <ScreenList {...this.props} />
                </div>
              </div>
              <div className={style.sideBar}></div>
            </div>
          </div>
        </Hidden>

        <Visible lg>
          <div className={ hasSelection ? style.mainContainer : style.mainContainerHidden }>
            <div className={style.scroll}>
              <ScreenList {...this.props} />
            </div>
          </div>
        </Visible>

        <Visible xs sm md>
          <div className={ hasSelection ? style.mainContainer : style.mainContainerHidden } style={{ backgroundColor: !isEmpty(this.props.configId) && "#F6F6F6" }}>
            <div className={style.slimScroll}>
              <ScreenList {...this.props} />
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedLocations: state.selected.locations,
    selectedUserGroups: state.selected.userGroups,
    selectedContent: state.selected.content
  };
}

export default connect(mapStateToProps, null)(ScreensContainer);