import React from "react";
import styled from "styled-components";

const Title = ({text, onClick, onTitleClick, inLargeHeader}) => (
  <Container onClick={onClick}>
    <TitleText onClick={onTitleClick} $inLargeHeader={inLargeHeader}>{text}</TitleText>
  </Container>
);

export default Title;

const Container = styled.div`
  display: ${props => props.$inLargeHeader ? "block" : "inline-block"};
  flex: 1;
  min-width: 0;
  max-width: fit-content;
`;

const TitleText = styled.h2`
  font-size: 26px;
  line-height: 38.5px;
  min-height: 38.5px;
  margin: 0;
  font-family: Source Sans Pro;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #0c0f26;

  white-space: nowrap;
  overflow: ${props => props.$inLargeHeader ? "hidden" : "visible"};
  text-overflow: ellipsis;
`;