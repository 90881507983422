import React, { Component } from "react";
import { Container, Col, Row, Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import moment from "moment";
import Freetext from "../../components/Freetext";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import SmallButton from "../../components/SmallButton";
import Tag from "../../components/Tag";
import * as screenActions from "../../actions/screens";
import * as locationActions from "../../actions/locations";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";

class ScreenConfigDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sortBy: "createdAt",
      sortOrder: "desc"
    }

    this.onDeviceRowClick = this.onDeviceRowClick.bind(this);
    this.onApproveDevice = this.onApproveDevice.bind(this);
    this.onRejectDevice = this.onRejectDevice.bind(this);
    this.onEnableDevice = this.onEnableDevice.bind(this);
    this.onDisableDevice = this.onDisableDevice.bind(this);
    this.onDeleteDevice = this.onDeleteDevice.bind(this);
    this.getDeviceTable = this.getDeviceTable.bind(this);
  }

  componentDidMount() {
    this.props.getScreenDevices(this.props.configId);
  }
  
  onDeviceRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo !== undefined) {
          this.setState({ selectedDevice: rowInfo.row });
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onApproveDevice(deviceId) {
    this.props.patchScreenDevice(this.props.configId, deviceId, { isApproved: true });
  }

  onRejectDevice(deviceId) {
    this.props.patchScreenDevice(this.props.configId, deviceId, { isApproved: false });
  }

  onEnableDevice(deviceId) {
    this.props.patchScreenDevice(this.props.configId, deviceId, { isDisabled: false });
  }

  onDisableDevice(deviceId) {
    this.props.patchScreenDevice(this.props.configId, deviceId, { isDisabled: true });
  }

  onDeleteDevice(deviceId) {
    // this.props.patchScreenDevice(this.props.configId, deviceId, { isApproved: false });
    this.props.deleteScreenDevice(this.props.configId, deviceId);
  }

  getDeviceTable() {
    const devices = this.props.screen.devices;
    
    if (isEmpty(devices)) return null;

    // Get status weight
    const getStatusWeight = (device) => {
      if (device.isOnline) {
        if (device.isApproved) {
          return device.isDisabled ? 2 : 1;
        }
        else {
          return device.isDisabled ? 4 : 3;
        }
      }
      else {
        if (device.isApproved) {
          return device.isDisabled ? 6 : 5;
        }
        else {
          return device.isDisabled ? 8 : 7;
        }
      }
    };

    // Sort devices
    let sortedDevices = devices.sort((a, b) => {
      if (this.state.sortBy === "createdAt") {
        if (this.state.sortOrder === "desc") {
          return moment(b.createdAt).diff(a.createdAt);
        }
        else {
          return moment(a.createdAt).diff(b.createdAt);
        }
      }
      else if (this.state.sortBy === "authCode") {
        if (this.state.sortOrder === "desc") {
          return b.authCode.localeCompare(a.authCode);
        }
        else {
          return a.authCode.localeCompare(b.authCode);
        }
      }
      else if (this.state.sortBy === "isApproved") {
        
        const weightA = getStatusWeight(a);
        const weightB = getStatusWeight(b);

        if (this.state.sortOrder === "desc") {
          return weightB - weightA;
        } else {
          return weightA - weightB;
        }
      }
      else {
        return 0;
      }
    });

    // Fix reload
    sortedDevices = sortedDevices.map(s => s);

    return (
      <Table
        data={sortedDevices}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        onSortedChange={(newSorted) => {
          this.setState({ sortBy: newSorted[0].id, sortOrder: newSorted[0].desc ? "desc" : "asc" });
        }}
        noDataText={"No devices"}
        columns={[
          {
            header: "Created at",
            accessorKey: "createdAt",
            defaultSortDesc: true,
            width: 140,
            cell: ({ row }) => 
            (<span title={moment(row.original.createdAt).format('Do MMMM YYYY, HH:mm')}>
              {moment(row.original.createdAt).format('DD/MM/YY, HH:mm')}
            </span>)
          },
          {
            header: "Device code",
            accessorKey: "authCode",
            cell: ({ row }) => (<span title={row.original.authCode}>{row.original.authCode}</span>)
          },
          {
            header: "Status",
            accessorKey: "isApproved",
            cell: ({ row }) => {
              let status = "Offline";
              let color = "#d15656";
              if (row.original.isApproved) {
                  if (row.original.isDisabled) {
                    status = "Disabled";
                    color = "#555";
                  }
                  else if (row.original.isOnline) {
                    status = "Online";
                    color = "#429953";
                  }
              }
              else {
                if (row.original.isOnline) {
                  status = "Waiting for approval...";
                  color = "#999";
                }
                else {
                  status = "Offline and not approved";
                  color = "#999";
                }
              }

              return (<Tag text={status} color={color} />);
            }
          },
          // {
          //   header: "Network",
          //   accessorKey: "isOnline",
          //   cell: ({ row }) => {
          //     const status = row.original.isOnline ? "Online" : "Offline";
          //     const color = row.original.isOnline ? "#429953" : "#d15656";
          //     return (<Tag text={status} color={color} />);
          //   }
          // },
          // {
          //   header: "Status",
          //   accessorKey: "isApproved",
          //   cell: ({ row }) => {
          //     const status = row.original.isApproved ? (row.original.isDisabled ? "Disabled" : "Approved") : "Waiting for approval...";
          //     const color = row.original.isApproved ? (row.original.isDisabled ? "#d15656" : "#429953") : "#999"; // blue 1C4D82
          //     // row.original.isDisabled ? "Approved, but disabled" : 
          //     return (<Tag text={status} color={color} />);
          //     // const status = (row.original.isApproved ? "Approved" : "Waiting for approval...");
          //     // return (<span title={status}>{status}</span>);
          //   }
          // },
          {
            id: "arrow",
            header: "",
            accessorKey: "id",
            sortable: false,
            className: "pull-right",
            width: 240,
            style: { display: "inline-flex", justifyContent: "end" },
            cell: ({ row }) => {
              let buttons = [];
              
              if (!row.original.isApproved) {
                buttons.push(<SmallButton text="Approve" color="blue" onClick={() => this.onApproveDevice(row.original.id)} key="approve" />);
                buttons.push(<SmallButton text="Delete" color="red" onClick={() => this.onDeleteDevice(row.original.id)} key="delete" />);
                return buttons;
              }

              if (row.original.isDisabled) {
                buttons.push(<SmallButton text="Re-enable" color="white" onClick={() => this.onEnableDevice(row.original.id)} key="enable" />);
              }
              else {
                buttons.push(<SmallButton text="Disable" color="white-red" onClick={() => this.onDisableDevice(row.original.id)} key="disable" />);
              }
              
              if (row.original.isDisabled) {
                buttons.push(<SmallButton text="Delete" color="red" onClick={() => this.onDeleteDevice(row.original.id)} key="delete" />);
              }
              else {
                buttons.push(<SmallButton text="Delete" color="gray" onClick={() => this.onDeleteDevice(row.original.id)} key="delete" disabled />);
              }
              
              return buttons;
            }
          }
        ]}
        getTdProps={this.onDeviceRowClick}
        // hideHeaders
        className="-row-clickable -highlight"
      />
    );
  }

  render() {
    // console.log("ScreenConfigDevices.render", this.props);

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    if (this.props.configId !== "new" && isEmpty(this.props.screen)) {
      return (
        <div className={style.outerScroll}>
          <Container className={style.container}>
            <Row className={style.topRow}>
              <Col>
                <Freetext header="Woops!" content="Could not find what you were looking for." />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    // Create table for devices   
    const deviceTable = this.props.configId !== "new" ? this.getDeviceTable() : null;
    
    return (
      <>
        <Hidden xs sm md lg>
          <div className={style.mainContainerHidden}>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <div style={{ paddingTop: "25px" }} />
                  { deviceTable }
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
              <div className={style.sideBar}>
                <div className={style.scroll}></div>
              </div>
            </div>
          </div>
        </Hidden>

        <Visible lg>
          <div className={style.mainContainerHidden}>
            <div className={style.scroll}>
              <div style={{ paddingTop: "25px" }} />
              { deviceTable }
              <div style={{ paddingTop: "40px" }} />
            </div>
          </div>
        </Visible>

        <Visible xs sm md>
          <div className={style.mainContainerHidden}>
            <div className={style.slimScroll}>
              <div style={{ paddingTop: "25px" }} />
              { deviceTable }
              <div style={{ paddingTop: "40px" }} />
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    screen: state.screen,
    form: state.screen.form,
    isLoading: state.loading.screen,
    isLoadingLocation: state.loading.location,
    isLoadingMap: state.loading[API.GET_FLOOR_MAP],
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
    user: state.auth.user,
    location: state.location
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getScreen: screenActions.getScreen,
    createScreen: screenActions.createScreen,
    updateScreen: screenActions.updateScreen,
    deleteScreen: screenActions.deleteScreen,
    getScreenDevices: screenActions.getScreenDevices,
    patchScreenDevice: screenActions.patchScreenDevice,
    deleteScreenDevice: screenActions.deleteScreenDevice,
    updateScreenForm: screenActions.updateScreenForm,
    resetScreenForm: screenActions.resetScreenForm,
    deselectScreen: screenActions.deselectScreen,
    getLocation: locationActions.getLocation,
    getFloorMap: locationActions.getFloorMap
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenConfigDetails);