import React from "react";
import Table from "../../../../components/Table";
import PropTypes from 'prop-types';
import moment from "moment";
import {isNaN} from "lodash";

export class GatewayTable extends React.Component { 
  static propTypes = {
    filters: PropTypes.shape({
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
      noSamplesSinceDate: PropTypes.string,
      gatewayTableSearchValue: PropTypes.string,
      sortOrder: PropTypes.string,
      sortBy: PropTypes.string,
      offset: PropTypes.number,
      activeSensors: PropTypes.number
    }).isRequired,
    gatewayResponse: PropTypes.shape({
      count: PropTypes.number,
      countByLocation: PropTypes.shape(),
      limit: PropTypes.number,
      offset: PropTypes.number,
      results: PropTypes.arrayOf(PropTypes.shape({
        createdAt: PropTypes.string,
        deviceId: PropTypes.string,
        frequency: PropTypes.string,
        id: PropTypes.string,
        locations: PropTypes.arrayOf(PropTypes.shape({
          _id: PropTypes.string
        })),
        model: PropTypes.string,
        name: PropTypes.string,
        vendor: PropTypes.string
      }))
    }).isRequired,
    handleUpdatesToFilter: PropTypes.func.isRequired,
    awaitingGatewayResponseFromServer: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingText: "Fetching gateways... this might take a few seconds. Hold on tight 🚀",
      noDataText: "No gateways found.",
      highlightedId: undefined,
    }

    this._onTableColumnSortChange = this._onTableColumnSortChange.bind(this);
    this._handleTablePageChange = this._handleTablePageChange.bind(this);
    this._onRowClick = this._onRowClick.bind(this);
  }

  _renderColumns() {
    return [
      {
        header: "Created at",
        accessorKey: "createdAt",
        defaultSortDesc: true,
        sortable: true,
        width: 120,
        cell: ({ row }) => 
        (<span title={moment(row.original.createdAt).format('Do MMMM YYYY, HH:mm')}>
          {moment(row.original.createdAt).format('DD/MM/YY, HH:mm')}
        </span>)
      },
      {
        header: "Name",
        accessorKey: "name",
        sortable: false,
        cell: ({ row }) => 
        (<span>
          {row.original.name}
        </span>)
      },
      {
        header: "Device ID",
        accessorKey: "deviceId",
        sortable: false,
        cell: ({ row }) => 
        (<span title={row.original.deviceId}>
          {`...${row.original.deviceId.substr(row.original.deviceId.length - 6)}`}
        </span>)
      },
      {
        header: "Model",
        accessorKey: "model",
        sortable: true,
        cell: ({ row }) => 
        (<span title={row.original.model}>
          {row.original.model}
        </span>)
      },
      {
        header: "Vendor",
        accessorKey: "vendor",
        sortable: true
      },
      {
        header: "Active sensors",
        accessorKey: "activeSensors",
        sortable: true,
        width: 125,
        cell: ({ row }) => 
        (<div>
          <span>
            {row.original.totalSensors !== undefined ? `${row.original.activeSensors || 0} / ${row.original.totalSensors}` : `${row.original.activeSensors || 0} / 0`}
          </span>
        </div>)
      },
      {
        header: "Min/max/avg signal strength",
        accessorKey: "sensorSignalStrength",
        sortable: false,
        cell: ({ row }) => 
        (<div>
          <span>
            {isNaN(Math.ceil(row.original.sensorSignalStrength.min)) ? "-" : Math.ceil(row.original.sensorSignalStrength.min)} / 
            {isNaN(Math.ceil(row.original.sensorSignalStrength.avg)) ? "-" : Math.ceil(row.original.sensorSignalStrength.avg)} /
            {isNaN(Math.ceil(row.original.sensorSignalStrength.max)) ? "-" : Math.ceil(row.original.sensorSignalStrength.max)}
          </span>
        </div>)
      },
      {
        header: "Last sample",
        accessorKey: "lastSampleAt",
        sortable: true,
        width: 120,
        cell: ({ row }) => 
        (<span title={ row.original.lastSampleAt ? moment(row.original.lastSampleAt).format('Do MMMM YYYY, HH:mm') : "" }>
          {row.original.lastSampleAt ? moment(row.original.lastSampleAt).format('DD/MM/YY, HH:mm') : ""}
        </span>)
      },
      {
        header: "",
        accessorKey: "properties",
        sortable: false,
        className: "pull-right",
        width: 40,
        cell: () => <div className="arrow" />
      }
    ]
  }

  _onTableColumnSortChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";

    const { handleUpdatesToFilter, filters } = this.props;
    handleUpdatesToFilter({
      ...filters,
      sortOrder,
      sortBy,
      offset: 0,
    });
  }

  _handleTablePageChange(offset) {
    const { handleUpdatesToFilter, filters } = this.props;
    handleUpdatesToFilter({
      ...filters,
      offset
    });
  }

  _onRowClick(column, rowInfo) {
    const onHover = (id) => {
      if (this.state.highlightedId !== id) {
        this.setState({ highlightedId: id });
      }
    }

    return {
      onClick: e => {
        if (rowInfo !== undefined) {
          const link = `/companies/${this.props.match.params.companyId}/gateways/${rowInfo.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        if (rowInfo && rowInfo.original) {
          onHover(rowInfo.original.id);
        }
      },
      onMouseLeave: e => onHover(null),
      style: {
        cursor: "pointer",
      }
    }
  }

  render() {
    const {
      loadingText,
      noDataText,
    } = this.state;
    const {
      awaitingGatewayResponseFromServer,
      gatewayResponse,
      filters: {
        sortBy,
        sortOrder,
        offset
      }
    } = this.props;
    const tableColumns = this._renderColumns();
    
    return (
      <Table
        data={gatewayResponse.results}
        sortBy={sortBy}
        sortOrder={sortOrder}
        offset={offset}
        limit={gatewayResponse.limit}
        count={gatewayResponse.count}
        onOffsetChange={this._handleTablePageChange}
        onSortedChange={this._onTableColumnSortChange}
        noDataText={awaitingGatewayResponseFromServer ? "" : noDataText}
        columns={tableColumns}
        getTdProps={this._onRowClick} 
        className="-row-clickable setMaxHeigth -highlight"
        loading={awaitingGatewayResponseFromServer}
        loadingText={loadingText}
      />
    )
  }
}