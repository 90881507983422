import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import Table from "../../components/Table";
import DetailsHeader from "../../components/DetailsHeader";
import InputBox from "../../components/InputBox";
import TextBox from "../../components/TextBox";
import SubNavigation from "../../components/SubNavigation";
import * as accountActions from "../../actions/account";
import * as selectedActions from "../../actions/selected";
import { Row, Col } from ".";
import styled from "styled-components";

const tabBarLinks = [
  { label: `Details` },
  { label: `Locations` }
];

class ServiceAccountDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: 0,
    };
    this.onRowClick = this.onRowClick.bind(this);
    this.selectedTab = this.selectedTab.bind(this);

    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onCertificateChange = this.onCertificateChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onEditNameSave = this.onEditNameSave.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.onToggleLocation = this.onToggleLocation.bind(this);

    if (isEmpty(this.props.serviceAccountId)) {
      this.props.clearServiceAccount();
    }
    else if (this.props.serviceAccount.id !== this.props.serviceAccountId && this.props.serviceAccountId !== "new") {
      this.props.getServiceAccount(this.props.serviceAccountId);
    }
  }

  componentDidUpdate(prevProps) {
    // Only load data if id change
    if (isEmpty(this.props.serviceAccountId)) {
      this.props.clearServiceAccount();
    }
    else if (prevProps.serviceAccountId !== this.props.serviceAccountId && this.props.serviceAccountId !== "new") {
      this.props.getServiceAccount(this.props.serviceAccountId);
    }
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo !== undefined && column.name !== "isSelected") {
          // console.log("rowInfo", rowInfo);
          let link = `/companies/${this.props.match.params.companyId}/locations/${rowInfo.original.id}/locations`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.id === this.state.id ? "rgba(0,0,0,0.05)" : null)
      }
    }
  }

  onNameChange(event) {
    this.props.updateServiceAccountDraft({ name: event.target.value });
  }

  onDescriptionChange(event) {
    this.props.updateServiceAccountDraft({ description: event.target.value });
  }

  onCertificateChange(event) {
    this.props.updateServiceAccountDraft({ certificate: event.target.value });
  }

  selectedTab(index) {
    this.setState({ selectedTabIndex: index });
  }

  onEditNameSave(name) {
    if (isEmpty(name)) {
      return;
    }

    const push = this.props.history.push;

    const body = {
      name
    };

    this.props.updateServiceAccount(this.props.serviceAccountId, body, push);
  }

  onDeleteClicked() {
    const push = this.props.history.push;
    this.props.deleteServiceAccount(this.props.serviceAccountId, push);
  }

  onToggleLocation(row) {
    if (this.props.selectedLocations[row.id] !== undefined && this.props.selectedLocations[row.id]) {
      this.props.deselectLocation(row.id);
    }
    else {
      this.props.selectLocation(row.original);
    }
  }
  
  render() {
    let details = null;
    if (!isEmpty(this.props.serviceAccountId)) {
      switch (this.state.selectedTabIndex) {
        case 0:
        details = (
          <>
            {
              this.props.serviceAccountId === "new" && (
                <Row>
                  <Col>
                    <InputBox
                      type="text"
                      label="Name"
                      placeholder="Required"
                      value={get(this.props.serviceAccountDraft, "name", get(this.props.serviceAccount, "name", ""))}
                      onChange={this.onNameChange}
                      valid={get(this.props.serviceAccountDraft, "name", get(this.props.serviceAccount, "name", ""))}
                      showValidOutline={!get(this.props.serviceAccountDraft, "name", get(this.props.serviceAccount, "name", ""))}
                      required
                    />
                  </Col>
                </Row>
              )
            }
            <Row>
              <Col>
                <InputBox
                  type="text"
                  label="Description"
                  value={get(this.props.serviceAccountDraft, "description", get(this.props.serviceAccount, "description", ""))}
                  onChange={this.onDescriptionChange}
                  valid={get(this.props.serviceAccountDraft, "description", get(this.props.serviceAccount, "description", ""))}
                  showValidOutline={!get(this.props.serviceAccountDraft, "description", get(this.props.serviceAccount, "description", ""))}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextBox
                  label="Certificate"
                  value={get(this.props.serviceAccountDraft, "certificate", get(this.props.serviceAccount, "certificate", ""))}
                  onChange={this.onCertificateChange}
                  // disabled
                  hasDarkBackground
                  // showCopy
                />
              </Col>
            </Row>
          </>
        );
        break;
      case 1:
        details = (
          <>
            <Row $paddingTop="20px">
              <Col>
                <Table
                  data={get(this.props.serviceAccountDraft, "locations", get(this.props.serviceAccount, "locations", []))}
                  noDataText="No locations"
                  columns={[
                    {
                      id: "id",
                      header: "",
                      accessorKey: "id",
                      sortable: false,
                      name: "isSelected",
                      cell: ({ row }) => (
                        <label className="checkboxContainer" htmlFor={`editCheckbox-${row.id}`}>
                          <input
                            id={`editCheckbox-${row.id}`}
                            type="checkbox"
                            className="checkbox"
                            checked={this.props.selectedLocations[row.id] !== undefined && this.props.selectedLocations[row.id]}
                            onChange={() => this.onToggleLocation(row)}
                          />
                          <span className={"checkmark"} />
                        </label>
                      ),
                      width: 60
                    },
                    {
                      header: "Name", 
                      accessorKey: "name", 
                      cell: ({ row }) => {
                        return <div>{row.name}</div>
                      }
                    },
                    {
                      id: "arrow",
                      header: "",
                      accessorKey: "name",
                      sortable: false,
                      className: "pull-right",
                      width: 60,
                      cell: () => <div className="arrow" />
                    }
                  ]}
                  getTdProps={this.onRowClick}
                  className="-row-clickable setMaxHeigth -highlight"
                  loading={this.props.isLoading}
                />
              </Col>
            </Row>
          </>
        );
        break;
      default:
        break;
      }
    }

    let header;
    if (this.props.serviceAccountId === "new") {
      header = (
        <DetailsHeader
          title={"New client"}
        />
      );
    }
    else {
      header = (
        <DetailsHeader
          title={get(this.props.serviceAccount, "name", "")}
          canUpdate={true}
          canUpdateName={true}
          canDelete={true}
          isLoading={this.props.isLoading}
          onNameSaveClicked={this.onEditNameSave}
          onDeleteClicked={this.onDeleteClicked}
        />
      );
    }

    return (
      <>
        { header }
        <SubNavigation links={tabBarLinks} selectedIndex={this.state.selectedTabIndex} onClick={this.selectedTab} slim />
        { details }
      </>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    serviceAccount: state.serviceAccount.data,
    serviceAccountDraft: state.serviceAccount.draft,
    isLoading: state.loading.serviceAccounts,
    isLoadingLocations: state.loading.locations,
    selectedLocations: state.selected.locations,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getServiceAccount: accountActions.getServiceAccount,
    createServiceAccount: accountActions.createServiceAccount,
    updateServiceAccount: accountActions.updateServiceAccount,
    deleteServiceAccount: accountActions.deleteServiceAccount,
    selectLocation: selectedActions.selectLocation,
    deselectLocation: selectedActions.deselectLocation,
    updateServiceAccountDraft: accountActions.updateServiceAccountDraft,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceAccountDetails));